import { History } from "history";
import * as React from "react";
import { useContext } from "react";
import H2 from "../../../../components/widgets/H2";
import { Alert, Button } from "reactstrap";
import Page from "../../../../components/widgets/Page";
import H3 from "../../../../components/widgets/H3";
import { ButtonBar } from "../../../../components/widgets/ButtonBar";
import { ROUTES } from "../../../../AppRouter";
import { getEditRoute } from "../../../../common/Routing";
import PayrunForm from "../payrun/model/PayrunForm";
import { AppContext, IAppContext } from "../../../../context/AppContext";
import { deleteAllPayruns } from "../../service/PayrunService";

export interface ExistingPayrunProps {
  history: History;
  match: any;
  payruns: Array<PayrunForm>;
}

const ExistingPayrunPage: React.FC<ExistingPayrunProps> = ({
  history,
  match,
  payruns
}) => {
  const { appState } = useContext<IAppContext>(AppContext);

  const continuePayrun = () => {
    history.push(getEditRoute(ROUTES.PAYRUN_EDIT, match.params.id));
  };

  const startNewPayrun = async () => {
    await deleteAllPayruns(appState.currentBusinessId);
    history.push(ROUTES.DASHBOARD);
  };

  return (
    <Page>
      <H2>Payrun</H2>
      <Alert color="warning">
        <H3>Payrun in progress</H3>
        <p>You have a payrun in progress.</p>

        <ButtonBar>
          <Button color="primary" className="mx-2" onClick={continuePayrun}>
            Continue existing payrun
          </Button>
          <Button color="secondary" onClick={startNewPayrun}>
            Start new payrun
          </Button>
        </ButtonBar>
      </Alert>
    </Page>
  );
};

export { ExistingPayrunPage };
