import * as React from "react";
import Select from "./Select";
import KeyValuePair from "./KeyValuePair";

interface GenderInputProps {
  selected: string;
  update: (name: string, value: string) => void;
}

const genders: Array<KeyValuePair> = new Array<KeyValuePair>(
  new KeyValuePair("Female", "Female"),
  new KeyValuePair("Male", "Male"),
  new KeyValuePair("NonBinary", "NonBinary"),
  new KeyValuePair("NotStated", "NotStated"),
);

const GenderInput: React.SFC<GenderInputProps> = ({ selected, update }) => (
  <Select
    name="gender"
    collection={genders}
    selected={selected}
    update={update}
  />
);

export default GenderInput;
