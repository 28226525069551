import * as React from 'react';
import {useEffect} from "react";

interface PageProps {
}

const Page: React.FC<PageProps> = (props) => {
  useEffect(() => {
    document.body.style.backgroundImage = "none";
  }, []);

  return <div className="content container">{props.children}</div>
};

export default Page;
