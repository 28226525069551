import * as React from 'react';
import Select from "./Select";
import KeyValuePair from "./KeyValuePair";
import PaymentBasis from "../../../payroll/employee/api/PaymentBasis";
import TextInput from './TextInput';
import {Moment} from 'moment';
import StpType from '../../../payroll/payitem/api/StpType';

interface StpTypeInputProps {
  selected: StpType,
  update: (name: string, value: StpType) => void;
}

const stpTypes: Array<KeyValuePair> = new Array<KeyValuePair>(
  new KeyValuePair("GROSS_PAYMENTS", "Gross payments")
);


const StpTypeInput: React.SFC<StpTypeInputProps> = ({selected, update}) => {
  return <Select name="stpType" collection={stpTypes} selected={selected.toString()} update={update} />
};

export {StpTypeInput};