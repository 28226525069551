import * as React from "react";
import { History } from "history";
import { ROUTES } from "../../../../AppRouter";
import { CrudListPage } from "../../../../components/widgets/CrudListPage";
import { EntitlementRow } from "./EntitlementRow";
import {
  deleteEntitlement,
  fetchEntitlements
} from "../../api/entitlement/EntitlementApi";

interface EntitlementListPageProps {
  history: History;
}

const EntitlementListPage: React.FC<EntitlementListPageProps> = ({ history }) => {
  return (
    <CrudListPage
      entityName="entitlement"
      createRoute={ROUTES.ENTITLEMENT_CREATE}
      deleteRecord={deleteEntitlement}
      fetchRecords={fetchEntitlements}
      history={history}
      headings={["Display name", "Type", "STP category", ""]}
      RowComponent={EntitlementRow}
    />
  );
};

export default EntitlementListPage;
