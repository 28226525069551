import * as React from "react";
import {useState} from "react";
import * as ReactStrap from "reactstrap";
import {Alert, Button, Col, Form, FormGroup, Label, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import TextInput from "../../../components/widgets/input/TextInput";
import H2 from "../../../components/widgets/H2";
import {FormCard} from "../../../components/widgets/FormCard";
import {SignUpViewProps} from "./SignUpView";
import PasswordInput from "../../../components/widgets/input/PasswordInput";

const closeModal = (
  setOpen: (open: boolean) => void
) => () => {
  setOpen(false);
};

const ModalSignUpView: React.FC<SignUpViewProps> = ({
  onUpdate,
  showErrors,
  model,
  onSubmit
}) => {
  const [open, setOpen] = useState<boolean>(true);
  const cancelAndClose = closeModal(setOpen);
  return (
    <>
      <ReactStrap.Modal isOpen={open} toggle={cancelAndClose} centered={true} fade={false}>
        <ModalHeader toggle={cancelAndClose}>Try Keisan Payroll free for 30 days</ModalHeader>
        <ModalBody>
          {model.errors.apiError && (
            <Alert color="danger">{model.errors.apiError}</Alert>
          )}

          <FormCard>
            <Row>
              <Col>
                <Form>
                  <FormGroup row>
                    <Label for="name" sm={4}>
                      Email
                    </Label>
                    <Col sm={8}>
                      <TextInput
                        name="email"
                        value={model.email}
                        update={onUpdate}
                        error={model.errors.email}
                        showErrors={showErrors}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="name" sm={4}>
                      Password
                    </Label>
                    <Col sm={8}>
                      <PasswordInput
                        name="password"
                        value={model.password}
                        update={onUpdate}
                        error={model.errors.password}
                        showErrors={showErrors}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </FormCard>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSubmit} className="mx-2">
            Get started
          </Button>
          <Button color="secondary" onClick={cancelAndClose}>
            Maybe later
          </Button>
        </ModalFooter>
      </ReactStrap.Modal>
    </>
  );
};

export { ModalSignUpView };
