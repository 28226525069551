import * as React from "react";
import { EmployeeTab } from "./EmployeeTab";
import { EmployeeModel } from "../../../service/employee/EmployeeModel";
import { EmployeeEmergencyContactForm } from "../../forms/EmployeeEmergencyContactForm";

interface EmployeeEmergencyContactTabProps {
  employee: EmployeeModel;
  onSave: () => void;
  onCancel: () => void;
  updateModel: (name: string, value: string) => void;
}

const EmployeeEmergencyContactTab: React.SFC<
  EmployeeEmergencyContactTabProps
> = ({ employee, onSave, onCancel, updateModel }) => {
  return (
    <EmployeeTab name={"Emergency contact"} onCancel={onCancel} onSave={onSave}>
      <EmployeeEmergencyContactForm
        employee={employee}
        updateModel={updateModel}
      />
    </EmployeeTab>
  );
};

export { EmployeeEmergencyContactTab };
