import * as React from "react";

import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import TextInput from "../../../../components/widgets/input/TextInput";
import { EmployeeModel } from "../../service/employee/EmployeeModel";

interface EmployeeEmergencyContactFormProps {
  employee: EmployeeModel;
  updateModel: (name: string, value: string) => void;
}

const EmployeeEmergencyContactForm: React.SFC<
  EmployeeEmergencyContactFormProps
> = ({ employee, updateModel }) => {
  return (
    <Row>
      <Col sm={6}>
        <Form>
          <FormGroup row>
            <Label for="emergencyContactName" sm={4}>
              Contact name
            </Label>
            <Col sm={8}>
              <TextInput
                name="emergencyContactName"
                value={employee.emergencyContactName}
                error={employee.errors.emergencyContactName}
                showErrors={employee.showErrors}
                update={updateModel}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="emergencyContactRelationship" sm={4}>
              Contact phone
            </Label>
            <Col sm={8}>
              <TextInput
                name="emergencyContactRelationship"
                value={employee.emergencyContactRelationship}
                error={employee.errors.emergencyContactRelationship}
                showErrors={employee.showErrors}
                update={updateModel}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="emergencyContactEmail" sm={4}>
              Email
            </Label>
            <Col sm={8}>
              <TextInput
                name="emergencyContactEmail"
                value={employee.emergencyContactEmail}
                error={employee.errors.emergencyContactEmail}
                showErrors={employee.showErrors}
                update={updateModel}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="emergencyContactPhone" sm={4}>
              Phone
            </Label>
            <Col sm={8}>
              <TextInput
                name="emergencyContactPhone"
                value={employee.emergencyContactPhone}
                error={employee.errors.emergencyContactPhone}
                showErrors={employee.showErrors}
                update={updateModel}
              />
            </Col>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  );
};

export { EmployeeEmergencyContactForm };
