import { History } from "history";
import * as React from "react";
import { SignUpForm } from "./SignUpForm";
import { SignUpView } from "./SignUpView";
import { ROUTES } from "../../../AppRouter";
import Page from "../../../components/widgets/Page";

export interface SignUpPageProps {
  history: History;
}

const cancelSignUp = (history: History) => () => {
  history.push(ROUTES.ROOT);
};

const SignUpPage: React.FC<SignUpPageProps> = ({ history }) => {
  return (
    <Page>
      <SignUpForm
        goBack={cancelSignUp(history)}
        history={history}
        SignUpView={SignUpView}
      />
    </Page>
  );
};

export { SignUpPage };
