import * as React from "react";
import { EmployeeModel } from "../../../service/employee/EmployeeModel";
import EmployeeTaxForm from "../../forms/EmpoyeeTaxForm";
import { EmployeeTab } from "./EmployeeTab";

interface EmployeeTaxTabProps {
  employee: EmployeeModel;
  onSave: () => void;
  onCancel: () => void;
  updateModel: (name: string, value: any) => void;
}

const EmployeeTaxTab: React.SFC<EmployeeTaxTabProps> = ({
  employee,
  onSave,
  onCancel,
  updateModel
}) => {
  return (
    <EmployeeTab name={"Tax"} onCancel={onCancel} onSave={onSave}>
      <EmployeeTaxForm
        employee={employee}
        updateModel={updateModel}
      />
    </EmployeeTab>
  );
};

export { EmployeeTaxTab };
