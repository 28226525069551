import {unauthSave} from "../../common/api/api";
import {IdModel} from "../../common/api/IdModel";
import {getApiRoot} from "../../config/ConfigService";

export interface SendEmailModel {
  email: string,
  firstName: string,
  lastName: string,
  businessName: string,
  message: string
}


export const sendEmail = async (sendEmailModel: SendEmailModel): Promise<string> => {
  const idModel = await unauthSave<IdModel>(`${getApiRoot()}/email`, sendEmailModel);
  return idModel.id;
};
