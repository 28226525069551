import {ChangeEvent, default as React} from 'react';
import {Input} from 'reactstrap';


interface CheckboxInputProps {
  name: string,
  checked: boolean,
  update?: (name: string, value: any) => void
}

const onChange = (name: string, update?: (name: string, value: boolean) => void) => (event: ChangeEvent<HTMLInputElement>) => {
  if (update) {
    update(name, event.target.checked);
  }
};

const CheckboxInput: React.SFC<CheckboxInputProps> = ({name, checked, update}) => {
  return <Input type="checkbox" name={name} id={name} checked={checked} onChange={onChange(name, update)} />
};

export {CheckboxInput};