import * as React from "react";
import {useState} from "react";
import PublicPage from "../PublicPage";
import Page from "../../../components/widgets/Page";
import {Alert, Button, Col, Form, FormGroup, Label, Row} from "reactstrap";
import TextInput from "../../../components/widgets/input/TextInput";
import {fieldValidation, updateModel, validateModel} from "../../../common/Model";
import {required} from "../../../common/validation/RequiredValidator";
import {ContactModel, ContactModelErrors} from "./ContactModel";
import {ButtonBar} from "../../../components/widgets/ButtonBar";
import {sendMessage} from "../../service/SendMessageService";
import {validEmail} from "../../../common/validation/EmailValidator";


const validators = [
  fieldValidation("firstName", [required]),
  fieldValidation("email", [required, validEmail]),
  fieldValidation("message", [required])
];


const send = (onUpdate: (key: string, value: any) => void, model: ContactModel) => async () => {
  await sendMessage(model);
  onUpdate('sent', true);
};

export const ContactPage: React.FC = ({}) => {
  const initialModel = {
    firstName: "",
    lastName: "",
    businessName: "",
    message: "",
    email: "",
    sent: false,
    errors: {} as ContactModelErrors
  } as ContactModel;
  const [model, setModel] = useState<ContactModel>(validateModel(initialModel, validators));
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const onUpdate = updateModel(model, setModel, validators);

  return (
    <PublicPage>
      {model.sent && (
        <Alert color="success">Message sent.</Alert>
      )}

      <Page>
        <Row>
          <Col sm={12} className="mt-5">
            <h1>Get in touch</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="mt-2 mb-5">
            Want to know more? Fill out the form below and we'll get back to you as soon as possible.
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <FormGroup row>
                <Label for="firstName" sm={4}>
                  First name
                </Label>
                <Col sm={8}>
                  <TextInput
                    name="firstName"
                    value={model.firstName}
                    update={onUpdate}
                    error={model.errors.firstName}
                    showErrors={showErrors}
                  />
                </Col>
              </FormGroup>

              <FormGroup row>
                <Label for="email" sm={4}>
                  Email
                </Label>
                <Col sm={8}>
                  <TextInput
                    name="email"
                    value={model.email}
                    update={onUpdate}
                    error={model.errors.email}
                    showErrors={showErrors}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
          <Col>
            <Form>
              <FormGroup row>
                <Label for="lastName" sm={4}>
                  Last name
                </Label>
                <Col sm={8}>
                  <TextInput
                    name="lastName"
                    value={model.lastName}
                    update={onUpdate}
                    error={model.errors.lastName}
                    showErrors={showErrors}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Form>
              <FormGroup row>
                <Label for="message" sm={2}>
                  Message
                </Label>
                <Col sm={10}>
                  <TextInput
                    name="message"
                    type="textarea"
                    rows={7}
                    value={model.message}
                    update={onUpdate}
                    error={model.errors.message}
                    showErrors={showErrors}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <ButtonBar>
              <Button
                color="primary"
                onClick={send(onUpdate, model)}
                className="mx-2"
              >
                Send message
              </Button>
            </ButtonBar>
          </Col>
        </Row>
      </Page>
    </PublicPage>
  );
};
