import * as React from "react";
import {EmployeeModel} from "../../../service/employee/EmployeeModel";
import {EmploymentDetailsForm} from "../../forms/EmploymentDetailsForm";
import {EmployeeTab} from "./EmployeeTab";

interface EmploymentDetailsTabProps {
  employee: EmployeeModel;
  onSave: () => void;
  onCancel: () => void;
  updateModel: (name: string, value: any) => void;
}

const EmploymentDetailsTab: React.SFC<EmploymentDetailsTabProps> = ({
  employee,
  onSave,
  onCancel,
  updateModel
}) => (
  <EmployeeTab name={"Employement details"} onCancel={onCancel} onSave={onSave}>
    <EmploymentDetailsForm
      employee={employee}
      updateModel={updateModel}
    />
  </EmployeeTab>
);

export { EmploymentDetailsTab };
