import {Business} from "../api/Business";
import BusinessId from "../../types/BusinessId";
import * as React from "react";

export interface BusinessCreatePageProps {
  business: Business;
  selectBusiness: (selectedBusinessId: BusinessId) => void;
}

const BusinessCreatePage: React.FC<BusinessCreatePageProps> = ({}) => {
  return <></>;
};

export { BusinessCreatePage };
