import React from 'react';
import ReactDOM from 'react-dom';
import './styles/App.scss';
import {AppRouter} from './AppRouter';
import {initialiseAuth} from "./auth/service/AuthService";


initialiseAuth();

ReactDOM.render(<AppRouter />, document.getElementById('root'));

