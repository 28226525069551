import { getBaseUrl } from "../../../../business/api/BusinessApi";
import BusinessId from "../../../../types/BusinessId";
import AccessToken from "../../../../types/AccessToken";
import Super from "./Super";
import {
  deleteRecord,
  fetchList,
  fetchSingle,
  save
} from "../../../../common/api/api";
import { SuperModel } from "../../../employee/service/super/SuperModel";
import { IdModel } from "../../../../common/api/IdModel";
import { idToken } from "../../../../auth/service/AuthService";

const baseUrl = (businessId: string) =>
  getBaseUrl(businessId) + "/pay-items/super";

const superUrl = (businessId: string, id: string) =>
  `${baseUrl(businessId)}/${id}`;

const fetchSupers = async (businessId: BusinessId): Promise<Array<Super>> => {
  return fetchList<Super>(await idToken(), baseUrl(businessId));
};

const fetchSuper = async (
  businessId: BusinessId,
  id: string
): Promise<SuperModel> => {
  return fetchSingle<SuperModel>(await idToken(), superUrl(businessId, id));
};

const saveSuper = async (
  businessId: BusinessId,
  superModel: SuperModel
): Promise<string> => {
  const idModel = await save<IdModel>(
    await idToken(),
    superModel.id ? superUrl(businessId, superModel.id) : baseUrl(businessId),
    superModel
  );
  return idModel.id;
};

const deleteSuper = async (
  businessId: BusinessId,
  earningId: string
): Promise<void> => {
  await deleteRecord(await idToken(), superUrl(businessId, earningId));
};

export { fetchSupers, fetchSuper, saveSuper, deleteSuper };
