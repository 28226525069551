import React, { useContext, useState } from "react";
import Employee from "../../api/Employee";
import Page from "../../../../components/widgets/Page";
import H2 from "../../../../components/widgets/H2";
import { Button } from "reactstrap";
import EmployeeListTable from "./EmployeeListTable";
import { ButtonBar } from "../../../../components/widgets/ButtonBar";
import { AppContext, IAppContext } from "../../../../context/AppContext";
import * as EmployeeApi from "../../api/EmployeeApi";
import { ListLoader } from "../../../../components/widgets/ListLoader";

interface EmployeeListViewProps {
  onCreateClick: () => void;
}

const EmployeeListView: React.FC<EmployeeListViewProps> = ({
  onCreateClick
}) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const [employees, setEmployees] = useState<Array<Employee>>([]);
  return (
    <Page>
      <H2>Employees</H2>
      <ButtonBar>
        <Button color="primary" onClick={onCreateClick}>
          New employee
        </Button>
      </ButtonBar>
      <ListLoader
        businessId={appState.currentBusinessId}
        fetchRecords={EmployeeApi.fetchEmployees}
        setRecords={setEmployees}
      >
        <EmployeeListTable employees={employees} />
      </ListLoader>
    </Page>
  );
};

export { EmployeeListView };
