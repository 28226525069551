import * as React from "react";
import { Alert, Col, Form, FormGroup, Label, Row } from "reactstrap";
import TextInput from "../../../components/widgets/input/TextInput";
import { SignUpModel } from "../../service/SignUpModel";
import H2 from "../../../components/widgets/H2";
import { FormCard } from "../../../components/widgets/FormCard";
import { ButtonBar } from "../../../components/widgets/ButtonBar";
import PasswordInput from "../../../components/widgets/input/PasswordInput";
import Button from "../../../components/widgets/Button";
import AuthPage from "../AuthPage";

export interface SignUpViewProps {
  onUpdate: (name: string, value: any) => void;
  showErrors: boolean;
  model: SignUpModel;
  goBack: () => void;
  onSubmit: () => void;
  navToLogin: () => void;
}

const SignUpView: React.FC<SignUpViewProps> = ({
  onUpdate,
  showErrors,
  goBack,
  model,
  onSubmit,
  navToLogin
}) => {
  return (
    <AuthPage>
      {model.errors.apiError && (
        <Alert color="danger">{model.errors.apiError}</Alert>
      )}

      <H2 center={true}>Sign up free for 60 days</H2>
      <FormCard>
        <Row>
          <Col>
            <Form>
              <FormGroup row>
                <Label for="name" sm={4}>
                  Email
                </Label>
                <Col sm={8}>
                  <TextInput
                    name="email"
                    value={model.email}
                    update={onUpdate}
                    error={model.errors.email}
                    showErrors={showErrors}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="name" sm={4}>
                  Password
                </Label>
                <Col sm={8}>
                  <PasswordInput
                    name="password"
                    value={model.password}
                    update={onUpdate}
                    error={model.errors.password}
                    showErrors={showErrors}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <ButtonBar center={true}>
          <Button color="primary" onClick={onSubmit} className="mx-2">
            Get started
          </Button>
          <Button color="secondary" onClick={goBack}>
            Maybe later
          </Button>
        </ButtonBar>
        <Row>
          <Col className="mt-3">
            <span>
              Already a customer?{" "}
              <button
                type="button"
                className="btn btn-link pl-0 pr-0"
                onClick={navToLogin}
              >
                Log in here
              </button>
              .
            </span>
          </Col>
        </Row>
      </FormCard>
    </AuthPage>
  );
};

export { SignUpView };
