import * as React from 'react';


interface ErrorMessageProps {
  message: string
}

const ErrorMessage: React.SFC<ErrorMessageProps> = ({message}) => {
  return <span className="invalid-feedback">{message}</span>
};

export {ErrorMessage};