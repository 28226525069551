import * as React from "react";
import { useState } from "react";
import BusinessId from "../types/BusinessId";
import _ from "lodash";

export interface IAppState {
  currentBusinessId: BusinessId;
}

export interface IAppContext {
  appState: IAppState;
  updateAppState: (name: string, value: any) => void;
}

const AppContext = React.createContext<IAppContext>({
  appState: {
    currentBusinessId: ""
  },
  updateAppState: (name: string, value: any) => {}
});

interface AppContextProviderProps {
  children: JSX.Element[] | JSX.Element;
}

const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children
}) => {
  const [appState, setAppState] = useState<IAppState>({
    currentBusinessId: ""
  } as IAppState);

  const updateAppState = (name: string, value: any) => {
    _.set(appState, name, value);
    const updated = Object.assign({}, appState);
    setAppState(updated);
  };

  return (
    <AppContext.Provider value={{ appState, updateAppState }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
