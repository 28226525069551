import * as React from "react";
import PayFormEarning from "../model/PayFormEarning";
import MoneyInput from "../../../../../components/widgets/input/MoneyInput";
import EarningCalculationMethod from "../../../../payitem/api/EarningCalculationMethod";
import { Col, Row } from "reactstrap";
import { SimpleRowComponentProps } from "../../../../../components/widgets/list/SimpleRowComponentProps";
import {formatMoney} from "../../../../../common/format/NumberFormat";

const PayEarningRow: React.FC<SimpleRowComponentProps<PayFormEarning>> = ({
  item,
  entityName,
  index,
  updateModel,
  modelNamePrefix
}) => {
  if (item.calculationMethod === EarningCalculationMethod.FIXED_AMOUNT) {
    return (
      <Row className="mb-2">
        <Col sm="6">{item.displayName}</Col>
        <Col sm="2"></Col>
        <Col sm="2">
          <MoneyInput
            name={`${modelNamePrefix}amount`}
            value={item.amount}
            update={updateModel}
          />
        </Col>
        <Col sm="2" className="text-right">{formatMoney(item.total)}</Col>
      </Row>
    );
  } else {
    return (
      <Row className="mb-2">
        <Col sm="6">{item.displayName}</Col>
        <Col sm="2">
          <MoneyInput
            name={`${modelNamePrefix}amount`}
            value={item.amount}
            update={updateModel}
          />
        </Col>
        <Col sm="2">
          <MoneyInput
            name={`${modelNamePrefix}units`}
            value={item.units}
            update={updateModel}
          />
        </Col>
        <Col sm="2" className="text-right">{formatMoney(item.total)}</Col>
      </Row>
    );
  }
};

export { PayEarningRow };
