import * as React from "react";
import { useContext, useState } from "react";
import { History } from "history";
import Page from "../../../../../components/widgets/Page";
import H2 from "../../../../../components/widgets/H2";
import { Button, Col, Row } from "reactstrap";
import { TableComponent } from "../../../../../components/widgets/table/TableComponent";
import { AppContext, IAppContext } from "../../../../../context/AppContext";
import PayrunForm from "../model/PayrunForm";
import DraftPay from "../../../api/DraftPay";
import _ from "lodash";
import { PayFrequency } from "../../../../employee/api/PayFrequency";
import moment from "moment";
import { formatDate } from "../../../../../common/Date";
import H4 from "../../../../../components/widgets/H4";
import { ButtonBar } from "../../../../../components/widgets/ButtonBar";
import { ROUTES } from "../../../../../AppRouter";
import { finalisePayrun, findPayrun } from "../../../service/PayrunService";
import { Loader } from "../../../../../components/widgets/Loader";
import { getEditRoute } from "../../../../../common/Routing";
import { PayrunSummaryRow } from "./PayrunSummaryRow";

export interface PayrunPageProps {
  history: History;
  match: any;
}

const goBack = (history: History) => () => {
  history.push(ROUTES.DASHBOARD);
};

const finalise = (
  history: History,
  businessId: string,
  draftPayrunid: string
) => async () => {
  const createPayrunResult = await finalisePayrun(businessId, draftPayrunid);
  history.push(getEditRoute(ROUTES.PAYRUN_COMPLETE, createPayrunResult.id));
};

const PayrunSummaryPage: React.FC<PayrunPageProps> = ({ history, match }) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const [payrun, setPayrun] = useState<PayrunForm>({
    frequency: PayFrequency.MONTHLY,
    startDate: moment(),
    endDate: moment(),
    payDate: moment(),
    employees: [] as Array<DraftPay>,
    errors: {},
    changed: false
  } as PayrunForm);
  const fetchData = async () => {
    const payrun = await findPayrun(appState.currentBusinessId, match.params.id);
    setPayrun(payrun);
  };

  return (
    <Page>
      <H2>Payrun summary</H2>
      <p>
        Here is a summary of the {_.lowerCase(payrun.frequency.toString())}{" "}
        payrun. You might like to check that everything is correct before
        finalising.
      </p>
      <H4>
        Pay peiod {formatDate(payrun.startDate)} to {formatDate(payrun.endDate)}{" "}
        - Pay on {formatDate(payrun.payDate)}
      </H4>

      <Row marginHeight={20}>
        <Col sm={12}>
          <Loader fetchData={fetchData}>
            <TableComponent
              headings={[
                "Name",
                "Earnings",
                "Pre-tax deductions",
                "Taxable income",
                "Post-tax deductions",
                "Tax",
                "Take home"
              ]}
              records={payrun.employees}
              entityName={"employee"}
              RowComponent={PayrunSummaryRow}
              hover={true}
            />
          </Loader>
        </Col>
      </Row>

      <ButtonBar>
        <Button
          color="primary"
          className="mx-2"
          onClick={finalise(history, appState.currentBusinessId, match.params.id)}
        >
          Confirm
        </Button>
        <Button color="secondary" onClick={goBack(history)}>
          Cancel
        </Button>
      </ButtonBar>
    </Page>
  );
};

export { PayrunSummaryPage };
