import {ROUTES} from '../AppRouter';

const getEditEmployeeRoute = (id: string): string => {
  return ROUTES.EMPLOYEE_EDIT.replace(":id", id);
};

const getEditRoute = (route: string, id: string): string => {
  return route.replace(":id", id);
};

export {getEditRoute, getEditEmployeeRoute};