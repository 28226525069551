import * as React from "react";
import {LoginPageProps} from "./LoginPage";
import {Alert, Col, Form, FormGroup, Label, Row} from "reactstrap";
import H2 from "../../../components/widgets/H2";
import {FormCard} from "../../../components/widgets/FormCard";
import TextInput from "../../../components/widgets/input/TextInput";
import {ButtonBar} from "../../../components/widgets/ButtonBar";
import {SignUpModel} from "../../service/SignUpModel";
import PasswordInput from "../../../components/widgets/input/PasswordInput";
import AuthPage from "../AuthPage";
import Button from "../../../components/widgets/Button";


interface LoginViewProps {
  onUpdate: (name: string, value: any) => void;
  showErrors: boolean;
  model: SignUpModel;
  onSubmit: () => void;
  navToSignup: () => void;
}

const LoginView: React.FC<LoginViewProps> = ({ onUpdate, showErrors, model, onSubmit, navToSignup }) => {
  return (

    <AuthPage>
      {model.errors.apiError && (
        <Alert color="danger">{model.errors.apiError}</Alert>
      )}

      <H2 center={true}>Login</H2>
      <FormCard>
        <Row>
          <Col>
            <Form>
              <FormGroup row>
                <Label for="name" sm={4}>
                  Email
                </Label>
                <Col sm={8}>
                  <TextInput
                    name="email"
                    value={model.email}
                    update={onUpdate}
                    error={model.errors.email}
                    showErrors={showErrors}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="name" sm={4}>
                  Password
                </Label>
                <Col sm={8}>
                  <PasswordInput
                    name="password"
                    value={model.password}
                    update={onUpdate}
                    error={model.errors.password}
                    showErrors={showErrors}
                  />
                </Col>
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <ButtonBar center={true}>
          <Button block={true} color="primary" onClick={onSubmit} className="mx-2">
            Login
          </Button>
        </ButtonBar>
        <Row>
          <Col className="mt-3"><span>Not a customer yet? <button type="button" className="btn btn-link pl-0 pr-0" onClick={navToSignup}>Sign up</button> today.</span></Col>
        </Row>
      </FormCard>

    </AuthPage>
  );
};

export {LoginView};
