import BusinessId from "../../types/BusinessId";
import { idToken } from "../../auth/service/AuthService";
import {fetchList, save} from "../../common/api/api";
import { IdModel } from "../../common/api/IdModel";
import {Business} from "./Business";
import {getApiRoot} from "../../config/ConfigService";


const getBusinessesUrl = () => {
  return `${getApiRoot()}/businesses`;
};

const getBaseUrl = (businessId: BusinessId) => {
  return `${getBusinessesUrl()}/${businessId}`;
};

const createBusiness = async (name: string): Promise<IdModel> => {
  return await save<IdModel>(
    await idToken(),
    `${getApiRoot()}/businesses`,
    { name }
  );
};

const fetchBusiness = async (): Promise<Business[]> => {
  return await fetchList(await idToken(), getBusinessesUrl());
};

export { getBaseUrl, createBusiness, fetchBusiness };
