import * as React from "react";
import { useContext, useState } from "react";
import { PayFrequency } from "../../../employee/api/PayFrequency";
import { History } from "history";
import { findActivePayruns } from "../../service/PayrunService";
import { AppContext, IAppContext } from "../../../../context/AppContext";
import { Loader } from "../../../../components/widgets/Loader";
import PayrunForm from "../payrun/model/PayrunForm";
import { DashboardView } from "./DashboardView";
import { ROUTES } from "../../../../AppRouter";
import { getEditRoute } from "../../../../common/Routing";

export interface DashboardProps {
  frequency: PayFrequency;
  history: History;
}

const DashboardPage: React.FC<DashboardProps> = ({ frequency, history }) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const [payruns, setPayruns] = useState<Array<PayrunForm> | undefined>(
    undefined
  );

  const fetchData = async () => {
    let payruns = await findActivePayruns(appState.currentBusinessId);
    setPayruns(payruns);
    if (payruns.length > 0) {
      history.push(getEditRoute(ROUTES.PAYRUN_IN_PROGRESS, payruns[0].id!));
    }
  };

  return (
    <Loader fetchData={fetchData}>
      <DashboardView
        frequency={frequency}
        history={history}
        payruns={payruns}
      />
    </Loader>
  );
};

export { DashboardPage };
