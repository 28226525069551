import React from "react";
import {EmployeeListView} from './EmployeeListView';
import {ROUTES} from '../../../../AppRouter';
import {History} from 'history';

interface EmployeeListPageProps {
  history: History,
}

const onCreateClick = (history: History) => () => {
  history.push(ROUTES.EMPLOYEE_CREATE)
};

const EmployeeListPage: React.FC<EmployeeListPageProps> = ({history}) => {
  return (
    <EmployeeListView onCreateClick={onCreateClick(history)} />
  );
};

export default EmployeeListPage;