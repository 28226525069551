import * as React from "react";
import Select from "./Select";
import DeductionType from "../../../payroll/payitem/api/DeductionType";
import { enumToKeyValues } from "./enum/enumToKeyValues";

interface DeductionTypeInputProps {
  selected: DeductionType;
  update: (name: string, value: string) => void;
}

const DeductionTypeInput: React.SFC<DeductionTypeInputProps> = ({
  selected,
  update
}) => (
  <Select
    name="deductionType"
    collection={enumToKeyValues(DeductionType)}
    selected={selected}
    update={update}
  />
);

export { DeductionTypeInput };
