import * as React from "react";
import {useEffect} from "react";
import styled from "styled-components";


interface PublicPageProps {
}

const PublicPage: React.FC<PublicPageProps> = (props) => {
  useEffect(() => {
    document.body.style.backgroundImage = "none";
  }, []);

  return <PublicPageContainer>{props.children}</PublicPageContainer>
};

const PublicPageContainer = styled.div`
  margin-top:56px;
`;

export default PublicPage;
