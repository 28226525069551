import BusinessId from "../../../types/BusinessId";
import {deleteRecord, fetchList, fetchSingle, save} from "../../../common/api/api";
import {getBaseUrl} from "../../../business/api/BusinessApi";
import {PayFrequency} from "../../employee/api/PayFrequency";
import {Moment} from "moment";
import {IdModel} from "../../../common/api/IdModel";
import PayrunCreate from "./PayrunCreate";
import DraftPayrun from "./DraftPayrun";
import Payrun from "./Payrun";
import {formatIsoDate} from "../../../common/Date";
import {idToken} from "../../../auth/service/AuthService";

const createPayrun = async (
  businessId: BusinessId,
  frequency: PayFrequency,
  startDate: Moment,
  endDate: Moment,
  payDate: Moment,
  employees: Array<string>
): Promise<IdModel> => {
  return save<IdModel>(await idToken(), getBaseUrl(businessId) + "/payruns", {
    frequency,
    startDate,
    endDate,
    payDate,
    employees
  } as PayrunCreate);
};

const fetchPayrun = async (
  businessId: BusinessId,
  id: string
): Promise<DraftPayrun> => {
  return fetchSingle<DraftPayrun>(
    await idToken(),
    `${getBaseUrl(businessId)}/payruns/${id}`
  );
};

const fetchPayruns = async (
  businessId: BusinessId
): Promise<Array<DraftPayrun>> => {
  return fetchList<DraftPayrun>(await idToken(), `${getBaseUrl(businessId)}/payruns`);
};

const fetchCompletedPayruns = async (
  businessId: BusinessId,
  fromDate: Moment,
  toDate: Moment
): Promise<Array<Payrun>> => {
  return fetchList<Payrun>(
    await idToken(),
    `${getBaseUrl(businessId)}/payrun-events?from-date=${formatIsoDate(
      fromDate
    )}&to-date=${formatIsoDate(toDate)}`
  );
};

const deletePayruns = async (
  businessId: BusinessId
): Promise<void> => {
  return deleteRecord(await idToken(), `${getBaseUrl(businessId)}/payruns`);
};

const completePayrun = async (
  businessId: BusinessId,
  id: string
): Promise<IdModel> => {
  return save<IdModel>(await idToken(), `${getBaseUrl(businessId)}/payruns/${id}`, {});
};

export {
  createPayrun,
  fetchPayrun,
  fetchPayruns,
  deletePayruns,
  completePayrun,
  fetchCompletedPayruns
};
