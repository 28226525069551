import {AxiosRequestConfig, default as Axios} from "axios";
import AccessToken from "../../types/AccessToken";

const getAccessTokenConfig = (token: string): AxiosRequestConfig => {
  return {
    headers: {
      authorization: `Bearer ${token}`
    }
  } as AxiosRequestConfig
};

const fetchList = async <T>(token: AccessToken, url: string): Promise<Array<T>> => {
  const response = await Axios.get<Array<T>>(url, getAccessTokenConfig(token)).catch(function(error: Error) {
    console.log(error);
  });
  return response ? response.data : [];
};

const fetchSingle = async <T>(token: AccessToken, url: string): Promise<T> => {
  const response = await Axios.get<T>(url, getAccessTokenConfig(token)).catch(function(error: Error) {
    console.log(error);
  });
  return response ? response.data : ({} as T);
};

const save = async <T>(
  token: AccessToken,
  url: string,
  model: any
): Promise<T> => {
  const httpAction = model.id ? Axios.patch : Axios.post;
  const response = await httpAction<T>(url, model, getAccessTokenConfig(token)).catch(function(
    error: Error
  ) {
    console.log(error);
  });
  return response ? response.data : ({} as T);
};

export const unauthSave = async <T>(
  url: string,
  model: any
): Promise<T> => {
  const httpAction = model.id ? Axios.patch : Axios.post;
  const response = await httpAction<T>(url, model, {}).catch(function(
    error: Error
  ) {
    console.log(error);
  });
  return response ? response.data : ({} as T);
};

const deleteRecord = async (token: AccessToken, url: string): Promise<void> => {
  await Axios.delete(url).catch(function(error: Error) {
    console.log(error);
  });
};

export { fetchSingle, fetchList, save, deleteRecord };
