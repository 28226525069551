import * as React from "react";
import Employee from "../../../employee/api/Employee";
import {FormGroup} from "reactstrap";
import {Link} from "react-router-dom";
import {getEditEmployeeRoute} from "../../../../common/Routing";
import {ListCheckboxInput} from '../../../../components/widgets/input/ListCheckboxInput';
import {RowComponentProps} from '../../../../components/widgets/table/RowComponentProps';

const DashboardEmployeeRow: React.FC<RowComponentProps<Employee>> = ({item, index, updateModel}) => {
  return <tr>
    <td>
      <FormGroup check>
        <ListCheckboxInput collectionName="employees" name="selected" index={index} checked={item.selected} update={updateModel}/>
      </FormGroup>
    </td>
    <td>
      <Link
        to={getEditEmployeeRoute(item.id)}
        className="nav-link"
      >
        {item.firstName} {item.lastName}
      </Link>
    </td>
    <td>{item.employmentDetails.payFrequency.toString()}</td>
    <td>
      {item.lastPaymentDate
        ? item.lastPaymentDate.toString()
        : ""}
    </td>
  </tr>
};

export {DashboardEmployeeRow};
