import * as React from "react";
import {useEffect, useState} from "react";
import {Loading} from "./Loading";

interface LoaderProps {
  fetchData: () => void;
}

const Loader: React.FC<LoaderProps> = ({ children, fetchData }) => {
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        await fetchData();
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };
    fetchDataAsync();
  }, []);
  const component = loading ? <Loading /> : children;
  return <>{component}</>;
};

export { Loader };
