import BusinessId from "../../../types/BusinessId";
import {fetchList, save} from "../../../common/api/api";
import {getBaseUrl} from "../../../business/api/BusinessApi";
import DraftPay from "./DraftPay";
import PayForm from "../components/payrun/model/PayForm";
import Pay from "./Pay";
import {idToken} from "../../../auth/service/AuthService";

const updatePay = async (
  businessId: BusinessId,
  payrunId: string,
  payModel: DraftPay
): Promise<PayForm> => {
  return save<PayForm>(
    await idToken(),
    `${getBaseUrl(businessId)}/payruns/${payrunId}/pays/${payModel.id}`,
    payModel
  );
};

const findCompletedPays = async (
  businessId: BusinessId,
  employeeId: string
): Promise<Array<Pay>> => {
  return fetchList<Pay>(
    await idToken(),
    `${getBaseUrl(businessId)}/employees/${employeeId}/pays`
  );
};

export { updatePay, findCompletedPays };
