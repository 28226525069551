import * as React from "react";

import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import PaymentBasisInput from "../../../../components/widgets/input/PaymentBasisInput";
import ResidencyStatusInput from "../../../../components/widgets/input/ResidencyStatusInput";
import TextInput from "../../../../components/widgets/input/TextInput";
import MoneyInput from "../../../../components/widgets/input/MoneyInput";
import {EmployeeModel} from "../../service/employee/EmployeeModel";
import {CheckboxInput} from "../../../../components/widgets/input/CheckboxInput";
import DateInput from "../../../../components/widgets/input/DateInput";
import {MedicareLevyInput} from "../../../../components/widgets/input/MedicareLevyInput";

interface EmployeeTaxFormProps {
  employee: EmployeeModel;
  updateModel: (name: string, value: any) => void;
}

const EmployeeTaxForm: React.SFC<EmployeeTaxFormProps> = ({
  employee,
  updateModel
}) => {
  return (
    <Row>
      <Col>
        <Form>
          <FormGroup row>
            <Label for="tfn" sm={4}>
              TFN
            </Label>
            <Col sm={8}>
              <TextInput
                name="tfn"
                value={employee.tfn}
                error={employee.errors.tfn}
                showErrors={employee.showErrors}
                update={updateModel}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="paymentBasis" sm={4}>
              Payment basis
            </Label>
            <Col sm={8}>
              <PaymentBasisInput
                selected={employee.paymentBasis}
                update={updateModel}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="residencyStatus" sm={4}>
              Residency status
            </Label>
            <Col sm={8}>
              <ResidencyStatusInput
                selected={employee.residencyStatus}
                update={updateModel}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="medicareLevy" sm={4}>
              Medicare levy
            </Label>
            <Col sm={8}>
              <MedicareLevyInput
                selected={employee.medicareLevy}
                update={updateModel}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="withholdingVariation" sm={4}>
              Withholding variation
            </Label>
            <Col sm={8}>
              <MoneyInput
                name="withholdingVariation"
                value={employee.withholdingVariation}
                error={employee.errors.withholdingVariation}
                showErrors={employee.showErrors}
                update={updateModel}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="otherTaxOffset" sm={4}>
              Other tax offset
            </Label>
            <Col sm={8}>
              <MoneyInput
                name="otherTaxOffset"
                value={employee.otherTaxOffset}
                error={employee.errors.otherTaxOffset}
                showErrors={employee.showErrors}
                update={updateModel}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="declarationDate" sm={4}>
              Declaration date
            </Label>
            <Col sm={8}>
              <DateInput
                name="declarationDate"
                value={employee.declarationDate}
                error={employee.errors.declarationDate}
                showErrors={employee.showErrors}
                update={updateModel}
              />
            </Col>
          </FormGroup>
        </Form>
      </Col>
      <Col>
        <Form>
          <FormGroup check>
            <Label check>
              <CheckboxInput
                name="taxFreeThreshold"
                checked={employee.taxFreeThreshold}
                update={updateModel}
              />{" "}
              Claim tax free thresshold
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <CheckboxInput
                name="helpSslTslDebt"
                checked={employee.helpSslTslDebt}
                update={updateModel}
              />{" "}
              Has HELP debt, SSL or TSL debt
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <CheckboxInput
                name="financialSupplementDebt"
                checked={employee.financialSupplementDebt}
                update={updateModel}
              />{" "}
              Has financial supplement debt
            </Label>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  );
};

export default EmployeeTaxForm;
