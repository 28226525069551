import {default as React} from 'react';
import {CheckboxInput} from './CheckboxInput';


interface ListCheckboxInputProps {
  name: string,
  index: number,
  checked: boolean,
  update?: (name: string, value: any) => void
  collectionName?: string;
}

const ListCheckboxInput: React.SFC<ListCheckboxInputProps> = ({name, index, checked, update, collectionName}) => {
  return <CheckboxInput  name={getName(name, index, collectionName)} checked={checked} update={update} />
};

const getName = (name: string, index: number, collectionName?: string) => {
  return collectionName ? `${collectionName}[${index}].${name}` : `${name}`;
};

export {ListCheckboxInput};