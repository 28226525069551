import * as React from "react";
import { Input } from "reactstrap";
import { ChangeEvent } from "react";
import { Validator } from "../../../common/validation/Validator";
import { validateInput } from "./inputValidate";
import { useState } from "react";
import { ErrorMessage } from "../ErrorMessage";
import {formatMoney} from "../../../common/format/NumberFormat";

interface MoneyInputProps {
  name: string;
  value: number;
  update?: (name: string, value: number) => void;
  showErrors?: boolean;
  error?: string;
}

const onChange = (
  name: string,
  update?: (name: string, value: number) => void
) => (event: ChangeEvent<HTMLInputElement>) => {
  if (update) {
    update(name, +event.target.value);
  }
};

const onBlur = (
  setTouched: (value: ((prevState: boolean) => boolean) | boolean) => void
) => () => {
  setTouched(true);
};

const MoneyInput: React.SFC<MoneyInputProps> = ({
  name,
  value,
  update,
  showErrors,
  error
}) => {
  let [touched, setTouched] = useState(false);
  const showError = error && error.length > 0 && (touched || showErrors);
  return (
    <>
      <Input
        type="text"
        name={name}
        id={name}
        value={value === undefined ? "" : value.toString()}
        onChange={onChange(name, update)}
        onBlur={onBlur(setTouched)}
        className="text-right"
      />
      {showError && <ErrorMessage message={error ? error : ""} />}
    </>
  );
};

export default MoneyInput;
