import {PayFrequency} from "../../../employee/api/PayFrequency";
import {History} from "history";
import * as React from "react";
import PayrunForm from "../payrun/model/PayrunForm";
import {CreatePayrunPage} from "./CreatePayrunPage";

export interface DashboardViewProps {
  frequency: PayFrequency;
  history: History;
  payruns: Array<PayrunForm> | undefined;
}

const DashboardView: React.FC<DashboardViewProps> = ({
  frequency,
  history,
  payruns
}) => {
  return payruns ? <CreatePayrunPage frequency={frequency} history={history} /> : <></>;
};

export { DashboardView };
