import * as React from "react";
import {Button, Col} from "reactstrap";
import styled from "styled-components";
import {FullHeightDiv} from "../../components/widgets/FullHeightDiv";

import HomePageImage from '../../images/homepage-small.png';

interface SignUpSectionProps {
  tryPayrollAction: () => void;
}

const SignUpSection: React.FC<SignUpSectionProps> = ({ tryPayrollAction }) => {

  return (
    <TrySection>
      <FullHeightDiv className="content container">
        <SignUpRow className="row">
          <Col sm={6} className="my-auto">
            <h1>Pay your employees the easy way.</h1>
            <p>
              <Button
                color="primary"
                size="large"
                onClick={tryPayrollAction}
              >
                Try Keisan Payroll
              </Button>{" "}
              free for 30 days.
            </p>
            <PricingParagraph><small>Only $5 / month &middot; unlimited employees</small></PricingParagraph>
          </Col>
          <Col sm={6} className="my-auto">
            <img src={HomePageImage} className="img-fluid" alt="Keisan screenshots" />
          </Col>
        </SignUpRow>
      </FullHeightDiv>
    </TrySection>
  );
};

const PricingParagraph = styled.p`
  font-weight: normal;
  color: #e0e0e0;
`

const SignUpRow = styled(FullHeightDiv)`
  margin-top: -16px;
`

const TrySection = styled.section`
  width: 100%;
  height: 50vh;
  background-color: #31364b;
  color: white;
  font-weight: bold;
`;

export { SignUpSection };
