import DraftPayrun from '../api/DraftPayrun';
import PayrunForm, {PayrunFormErrors} from '../components/payrun/model/PayrunForm';
import moment from 'moment';
import DraftPay from '../api/DraftPay';
import PayForm, {PayFormErrors} from '../components/payrun/model/PayForm';
import PayFormEarning, {PayFormEarningErrors} from '../components/payrun/model/PayFormEarning';
import PayFormDeduction, {PayFormDeductionErrors} from '../components/payrun/model/PayFormDeduction';
import PayFormEntitlement, {PayFormEntitlementErrors} from '../components/payrun/model/PayFormEntitlement';
import PayFormSuper, {PayFormSuperErrors} from '../components/payrun/model/PayFormSuper';
import DraftPayEarning from '../api/DraftPayEarning';
import DraftPayDeduction from '../api/DraftPayDeduction';
import DraftPayEntitlement from '../api/DraftPayEntitlement';
import DraftPaySuper from '../api/DraftPaySuper';


const convertDraftPayrun = (payrun: DraftPayrun): PayrunForm => {
  return {
    id: payrun.id,
    frequency: payrun.frequency,
    startDate: moment(payrun.startDate),
    endDate: moment(payrun.endDate),
    payDate: moment(payrun.payDate),
    selectedEmployee: payrun.employees ? payrun.employees[0].employeeId : null,
    employees: payrun.employees ? payrun.employees.map(p => convertDraftPay(p)) : [],
    errors: {} as PayrunFormErrors
  } as PayrunForm
};

const convertDraftPay = (pay: DraftPay): PayForm => {
  return {
    id: pay.id,
    employeeId: pay.employeeId,
    employeeName: pay.employeeName,
    sequence: pay.sequence,
    notes: pay.notes,
    totalEarnings: pay.totalEarnings,
    totalPreTaxDeductions: pay.totalPreTaxDeductions,
    taxableIncome: pay.taxableIncome,
    tax: pay.tax,
    incomeLessTax: pay.incomeLessTax,
    totalPostTaxDeductions: pay.totalPostTaxDeductions,
    takeHomePay: pay.takeHomePay,
    totalSuper: pay.totalSuper,
    totalEntitlement: pay.totalEntitlement,
    earnings: pay.earnings.map(e => convertDraftEarning(e)),
    deductions: pay.deductions.map(e => convertDraftDeduction(e)),
    entitlements: pay.entitlements.map(e => convertDraftEntitlement(e)),
    super: pay.super.map(e => convertDraftSuper(e)),
    errors: {} as PayFormErrors
  } as PayForm
};

const convertDraftEarning = (earning: DraftPayEarning): PayFormEarning => {
  return {
    id: earning.id,
    earningId: earning.earningId,
    name: earning.name,
    displayName: earning.displayName,
    type: earning.type,
    calculationMethod: earning.calculationMethod,
    stpType: earning.stpType,
    paySuper: earning.paySuper,
    accrueLeave: earning.accrueLeave,
    reducesBase: earning.reducesBase,
    amount: earning.amount,
    units: earning.units,
    total: earning.total,
    errors: {} as PayFormEarningErrors
  } as PayFormEarning
};

const convertDraftDeduction = (deduction: DraftPayDeduction): PayFormDeduction => {
  return {
    id: deduction.id,
    deductionId: deduction.deductionId,
    name: deduction.name,
    displayName: deduction.displayName,
    type: deduction.type,
    calculationMethod: deduction.calculationMethod,
    limitType: deduction.limitType,
    limitCalculationMethod: deduction.limitCalculationMethod,
    stpType: deduction.stpType,
    beforeTax: deduction.beforeTax,
    amount: deduction.amount,
    units: deduction.units,
    total: deduction.total,
    errors: {} as PayFormDeductionErrors
  } as PayFormDeduction
};

const convertDraftEntitlement = (entitlement: DraftPayEntitlement): PayFormEntitlement => {
  return {
    id: entitlement.id,
    entitlementId: entitlement.entitlementId,
    name: entitlement.name,
    displayName: entitlement.displayName,
    calculationMethod: entitlement.calculationMethod,
    stpType: entitlement.stpType,
    amount: entitlement.amount,
    earning: entitlement.earning,
    total: entitlement.total,
    errros: {} as PayFormEntitlementErrors
  } as PayFormEntitlement
};

const convertDraftSuper = (draftPaySuper: DraftPaySuper): PayFormSuper => {
  return {
    id: draftPaySuper.id,
    superId: draftPaySuper.superId,
    name: draftPaySuper.name,
    displayName: draftPaySuper.displayName,
    type: draftPaySuper.type,
    calculationMethod: draftPaySuper.calculationMethod,
    stpType: draftPaySuper.stpType,
    amount: draftPaySuper.amount,
    total: draftPaySuper.total,
    errors: {} as PayFormSuperErrors
  } as PayFormSuper
};

export {convertDraftPayrun};
