import * as React from "react";
import { useContext, useState } from "react";
import H2 from "./H2";
import { ButtonBar } from "./ButtonBar";
import { Button } from "reactstrap";
import Page from "./Page";
import { History } from "history";
import BusinessId from "../../types/BusinessId";
import { AppContext, IAppContext } from "../../context/AppContext";
import { Loader } from "./Loader";
import { FormModel } from "../../common/FormModel";
import { FormCard } from "./FormCard";
import { FieldValidation } from "../../common/validation/FieldValidation";
import { CrudFormViewComponentProps } from "./CrudFormViewComponentProps";
import { updateModel, validateModel } from "../../common/Model";

interface CrudFormProps<T> {
  id?: string;
  history: History;
  businessId: string;
  initialModel: T;
  validators: Array<FieldValidation>;
  entityName: string;
  FormViewComponent: React.ComponentType<CrudFormViewComponentProps<T>>;
  fetchModel: (businessId: BusinessId, id: string) => Promise<T>;
  saveModel: (businessId: string, model: T) => void;
  listRoute: string;
}

const backToList = (history: History, listRoute: string) => () => {
  history.push(listRoute);
};

const loadModel = <T extends FormModel>(
  businessId: string,
  fetchModel: (businessId: BusinessId, id: string) => Promise<T>,
  setModel: (modle: T) => void,
  validators: Array<FieldValidation>,
  id?: string
) => async () => {
  if (!id) {
    return;
  }
  const model = await fetchModel(businessId, id);
  setModel(validateModel(model, validators));
};

const save = <T extends FormModel>(
  model: T,
  businessId: string,
  saveModel: (businessId: string, model: T) => void,
  setShowErrors: (showErrors: boolean) => void,
  goBack: () => void
) => async () => {
  if (!model.isValid) {
    setShowErrors(true);
  } else {
    await saveModel(businessId, model);
    goBack();
  }
};

const CrudForm = <T extends FormModel>({
  id,
  history,
  initialModel,
  validators,
  entityName,
  FormViewComponent,
  fetchModel,
  saveModel,
  listRoute
}: CrudFormProps<T>) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const [model, setModel] = useState<T>(
    validateModel(initialModel, validators)
  );
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const onUpdate = updateModel(model, setModel, validators);
  const heading = (id ? "Edit " : "Create ") + entityName;

  let goBack = backToList(history, listRoute);
  const page = (
    <Page>
      <H2>{heading}</H2>
      <FormCard>
        <FormViewComponent
          model={model}
          onUpdate={onUpdate}
          showErrors={showErrors}
        />
        <ButtonBar>
          <Button
            color="primary"
            onClick={save(model, appState.currentBusinessId, saveModel, setShowErrors, goBack)}
            className="mx-2"
          >
            Save
          </Button>
          <Button color="secondary" onClick={goBack}>
            Cancel
          </Button>
        </ButtonBar>
      </FormCard>
    </Page>
  );

  const component = id ? (
    <Loader
      fetchData={loadModel(appState.currentBusinessId, fetchModel, setModel, validators, id)}
    >
      {page}
    </Loader>
  ) : (
    page
  );

  return component;
};

export { CrudForm };
