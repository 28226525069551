import * as React from "react";
import { H6 } from "../../../../../components/widgets/H6";
import PayForm from "../model/PayForm";
import { PayEarningRow } from "./PayEarningRow";
import { PayDeductionRow } from "./PayDeductionRow";
import { PayEntitlementRow } from "./PayEntitlementRow";
import { PaySuperRow } from "./PaySuperRow";
import { Col, Input, Label, Row } from "reactstrap";
import { SimpleListComponent } from "../../../../../components/widgets/list/SimpleList";
import TextInput from "../../../../../components/widgets/input/TextInput";
import MoneyInput from "../../../../../components/widgets/input/MoneyInput";
import {TotalRow} from "./TotalRow";
import {SumRow} from "./SumRow";

export interface PayViewProps {
  updateModel: (name: string, value: any) => void;
  employeeIndex: number;
  currentPay?: PayForm;
}

const PayView: React.FC<PayViewProps> = ({
  currentPay,
  employeeIndex,
  updateModel
}) => {
  return (
    <>
      {currentPay && (
        <>
          <h4>{currentPay.employeeName}</h4>
          <H6>Earnings</H6>
          <SimpleListComponent
            records={currentPay.earnings}
            entityName={"earning"}
            modelNamePrefix={`employees[${employeeIndex}].`}
            RowComponent={PayEarningRow}
            updateModel={updateModel}
          />

          <SumRow label="Total earnings" total={currentPay.totalEarnings} />


          <H6>Deductions</H6>
          <SimpleListComponent
            records={currentPay.deductions}
            entityName={"deduction"}
            modelNamePrefix={`employees[${employeeIndex}].`}
            RowComponent={PayDeductionRow}
            updateModel={updateModel}
          />

          <H6>Entitlements</H6>
          <SimpleListComponent
            records={currentPay.entitlements}
            entityName={"entitlement"}
            modelNamePrefix={`employees[${employeeIndex}].`}
            RowComponent={PayEntitlementRow}
            updateModel={updateModel}
          />

          <SumRow label="Total entitlements" total={currentPay.totalEntitlement} />

          <H6>Super</H6>
          <SimpleListComponent
            records={currentPay.super}
            entityName={"super"}
            modelNamePrefix={`employees[${employeeIndex}].`}
            RowComponent={PaySuperRow}
            updateModel={updateModel}
          />

          <SumRow label="Total super" total={currentPay.totalSuper} />

          <hr />

          <div>
            <TotalRow label="Earnings" total={currentPay.totalEarnings} />
            <TotalRow label="Before tax deductions" total={currentPay.totalPreTaxDeductions} />
            <TotalRow label="Taxable income" total={currentPay.taxableIncome} />
            <TotalRow label="Tax" total={currentPay.tax} />
            <TotalRow label="After tax income" total={currentPay.incomeLessTax} />
            <TotalRow label="After tax deductions" total={currentPay.totalPostTaxDeductions} />
            <TotalRow label="Take home pay" total={currentPay.takeHomePay} />
          </div>

          <hr />

          <Row className="mb-2">
            <Col sm={12}>
              <Label key="payslipNotes">Notes:</Label>
              <TextInput
                type="textarea"
                name={`employees[${employeeIndex}].payslipNotes`}
                value={currentPay.notes}
                update={updateModel}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export { PayView };
