import React from "react";
import Page from "../../../../components/widgets/Page";
import H2 from "../../../../components/widgets/H2";
// @ts-ignore
import Stepper from "react-stepper-horizontal";
import WizardStep from "./WizardStep";
import { EmployeeStep } from "./EmployeeStep2";
import {EmployeeModel} from '../../service/employee/EmployeeModel';

interface EmployeeCreateViewProps {
  employee: EmployeeModel;
  currentStep: number;
  steps: Array<WizardStep>;
  onCancel: () => void;
  onNext: () => void;
  onPrevious: () => void;
}

const notEmptyString = (input: string) => {
  return input && input.length > 0;
};

const EmployeeCreateView: React.SFC<EmployeeCreateViewProps> = ({
  employee,
  currentStep,
  steps,
  onCancel,
  onNext,
  onPrevious
}) => {
  let title = steps[currentStep].title;
  const employeeName =
    notEmptyString(employee.firstName) || notEmptyString(employee.lastName)
      ? `${employee.firstName || ''} ${employee.lastName || ''}`
      : "New employee";
  return (
    <Page>
      <H2>{employeeName}</H2>
      <Stepper
        steps={steps.map(step => {
          return { title: step.title };
        })}
        activeStep={currentStep}
      />

      <EmployeeStep
        name={title}
        onCancel={onCancel}
        onNext={onNext}
        onPrevious={onPrevious}
        lastStep={(steps.length - 1) === currentStep}
      >
        {steps[currentStep].component}
      </EmployeeStep>
    </Page>
  );
};

export { EmployeeCreateView };
