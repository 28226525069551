import * as React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import {ROUTES} from "../../AppRouter";
import styled from "styled-components";
import KeisanLogo from '../../images/keisan-logo.svg';

const Menu = () => (
  <div>
    <StyledNavbar expand="md">
      <div className="container">
        <NavbarBrand href={ROUTES.ROOT} className="mr-5">
          <img src={KeisanLogo} width={120} alt="Keisan Logo" />
        </NavbarBrand>

        <Collapse navbar>
          <Nav className="d-flex ml-5" navbar>
            <NavItem>
              <Link to={ROUTES.DASHBOARD} className="nav-link">
                Payrun
              </Link>
            </NavItem>
            <NavItem>
              <Link to={ROUTES.EMPLOYEE_LIST} className="nav-link">
                Employees
              </Link>
            </NavItem>
            <NavItem>
              <Link to={ROUTES.ATO_REPORTING} className="nav-link">
                ATO reporting
              </Link>
            </NavItem>
            <NavItem>
              <Link to={ROUTES.REPORTS} className="nav-link">
                Reports
              </Link>
            </NavItem>
          </Nav>

          <Nav className="d-flex ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Settings
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem>Bank account</DropdownItem>
                <DropdownItem>Payslip</DropdownItem>
                <Link to={ROUTES.EARNING_LIST} className="dropdown-item">
                  Earnings
                </Link>
                <Link to={ROUTES.DEDUCTION_LIST} className="dropdown-item">
                  Deductions
                </Link>
                <Link to={ROUTES.SUPER_LIST} className="dropdown-item">
                  Superannuation
                </Link>
                <Link to={ROUTES.ENTITLEMENT_LIST} className="dropdown-item">
                  Entitlements
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Profile
              </DropdownToggle>
              <DropdownMenu right>
                <Link to={ROUTES.BUSINESSES} className="dropdown-item">
                  Switch business
                </Link>
                <Link to={ROUTES.LOGOUT} className="dropdown-item">
                  Logout
                </Link>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </div>
    </StyledNavbar>
  </div>
);


const StyledNavbar = styled(Navbar)`
  background-color: #31364B;

`

export default Menu;
