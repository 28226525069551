import { Loader } from "../Loader";
import * as React from "react";
import { useContext } from "react";
import { RecordBase } from "../../../common/api/RecordBase";
import BusinessId from "../../../types/BusinessId";
import { ListItem } from "../../../common/ListItem";
import { AppContext, IAppContext } from "../../../context/AppContext";
import { RowComponentProps } from "../table/RowComponentProps";
import { updateModel } from "../../../common/Model";
import { ListModel } from "../../../common/ListModel";

interface ListProps<I extends ListItem, R extends RecordBase> {
  fetchItems: (businessId: BusinessId) => Promise<R[]>;
  selected: string[];
  model: ListModel<I>;
  setModel: (model: ListModel<I>) => void;
  RowComponent: React.ComponentType<RowComponentProps<I>>;
  entityName?: string;
}

const loadItems = <I extends ListItem>(
  businessId: string,
  fetchItems: (businessId: BusinessId) => Promise<I[]>,
  selected: string[],
  setModel: (model: ListModel<I>) => void
) => async () => {
  const items = await fetchItems(businessId);
  setModel({
    items: items.map(it => {
      it.selected = selected.includes(it.id);
      return it;
    })
  });
};

const ListComponent: React.FC<ListProps<any, any>> = ({
  fetchItems,
  selected,
  model,
  setModel,
  RowComponent,
  entityName
}) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const onUpdate = updateModel(model, setModel, []);
  return (
    <Loader
      fetchData={loadItems<ListItem>(
        appState.currentBusinessId,
        fetchItems,
        selected,
        setModel
      )}
    >
      {model.items.map((item, index) => {
        return (
          <RowComponent
            key={item.id}
            index={index}
            item={item}
            entityName={entityName}
            updateModel={onUpdate}
            onDeleteClick={() => {}}
          />
        );
      })}
    </Loader>
  );
};

export { ListComponent };
