import { PayFrequency } from "../../api/PayFrequency";
import { EmployeeModel } from "./EmployeeModel";

const hoursPerYear = (
  payFrequency: PayFrequency,
  standardHours: number
): number => {
  if (payFrequency === PayFrequency.MONTHLY) {
    return 12 * standardHours;
  } else if (payFrequency === PayFrequency.FORTNIGHTLY) {
    return 26 * standardHours;
  }
  return 52 * standardHours;
};

const hourlyRate = (
  payFrequency: PayFrequency,
  standardHours: number,
  annualSalary: number
): number => {
  return parseFloat(
    (annualSalary / hoursPerYear(payFrequency, standardHours)).toFixed(4)
  );
};

const annualSalary = (
  payFrequency: PayFrequency,
  standardHours: number,
  hourlyRate: number
): number => {
  return parseFloat(
    (hourlyRate * hoursPerYear(payFrequency, standardHours)).toFixed(0)
  );
};

const computeEmployeeValues = (
  name: string,
  value: any,
  model: EmployeeModel,
  setValue: (name: string, value: any) => void
): void => {
  if (
    name === "annualSalary" ||
    name === "standardHours" ||
    name === "payFrequency"
  ) {
    if (
      !isNaN(model.annualSalary) &&
      !isNaN(model.standardHours) &&
      model.payFrequency
    ) {
      setValue(
        "hourlyRate",
        hourlyRate(model.payFrequency, model.standardHours, model.annualSalary)
      );
    }
  } else if (name === "hourlyRate") {
    if (
      !isNaN(model.hourlyRate) &&
      !isNaN(model.standardHours) &&
      model.payFrequency
    ) {
      setValue(
        "annualSalary",
        annualSalary(model.payFrequency, model.standardHours, model.hourlyRate)
      );
    }
  }
};

export { computeEmployeeValues };
