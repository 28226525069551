import BusinessId from "../../../types/BusinessId";
import {getBaseUrl} from "../../../business/api/BusinessApi";

const payslipUrl = (
  token: string,
  businessId: BusinessId,
  payId: string
): string => {
  return `${getBaseUrl(businessId)}/payslips/${payId}?token=${token}`;
};


export { payslipUrl };
