import * as React from "react";
import { Col, Row } from "reactstrap";
import {formatMoney} from "../../../../../common/format/NumberFormat";

export interface TotalRowProps {
  label: string;
  total: number;
}

const TotalRow: React.FC<TotalRowProps> = ({ label, total }) => {
  return (
    <Row className="mb-2">
      <Col sm="6">{label}</Col>
      <Col sm="2"></Col>
      <Col sm="2"></Col>
      <Col sm="2" className="text-right">{formatMoney(total)}</Col>
    </Row>
  );
};

export { TotalRow };
