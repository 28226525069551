import * as React from "react";

import { Col, Row } from "reactstrap";
import { EmployeeModel } from "../../service/employee/EmployeeModel";
import { TableComponent } from "../../../../components/widgets/table/TableComponent";
import { EmployeeBankAccountRow } from "./EmployeeBankAccountRow";

interface EmployeeBankDetailsFormProps {
  employee: EmployeeModel;
  updateModel: (name: string, value: string) => void;
}

const onDelete = (id: string) => {};

const EmployeeBankDetailsForm: React.SFC<EmployeeBankDetailsFormProps> = ({
  employee,
  updateModel
}) => {
  return (
    <Row>
      <Col>
        <TableComponent
          headings={[
            "Statement description",
            "Account name",
            "BSB",
            "Account number",
            ""
          ]}
          records={employee.bankAccounts}
          entityName={"Bank account"}
          RowComponent={EmployeeBankAccountRow}
          onDeleteClick={onDelete}
        />
      </Col>
    </Row>
  );
};

export { EmployeeBankDetailsForm };
