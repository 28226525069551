import { getBaseUrl } from "../../../../business/api/BusinessApi";
import BusinessId from "../../../../types/BusinessId";
import AccessToken from "../../../../types/AccessToken";
import Earning from "./Earning";
import {
  deleteRecord,
  fetchList,
  fetchSingle,
  save
} from "../../../../common/api/api";
import { EarningModel } from "../../../employee/service/earning/EarningModel";
import { IdModel } from "../../../../common/api/IdModel";
import { idToken } from "../../../../auth/service/AuthService";

const earningsBaseUrl = (businessId: string) =>
  getBaseUrl(businessId) + "/pay-items/earnings";

const earningUrl = (businessId: string, id: string) =>
  `${earningsBaseUrl(businessId)}/${id}`;

const fetchEarnings = async (
  businessId: BusinessId
): Promise<Array<Earning>> => {
  return fetchList<Earning>(await idToken(), earningsBaseUrl(businessId));
};

const fetchEarning = async (
  businessId: BusinessId,
  id: string
): Promise<EarningModel> => {
  return fetchSingle<EarningModel>(await idToken(), earningUrl(businessId, id));
};

const saveEarning = async (
  businessId: BusinessId,
  earning: EarningModel
): Promise<string> => {
  const idModel = await save<IdModel>(
    await idToken(),
    earning.id
      ? earningUrl(businessId, earning.id)
      : earningsBaseUrl(businessId),
    earning
  );
  return idModel.id;
};

const deleteEarning = async (
  token: AccessToken,
  businessId: BusinessId,
  earningId: string
): Promise<void> => {
  await deleteRecord(await idToken(), earningUrl(businessId, earningId));
};

export { fetchEarnings, fetchEarning, saveEarning, deleteEarning };
