import * as React from "react";
import { useEffect, useState } from "react";
import { Loading } from "./Loading";
import { RecordBase } from "../../common/api/RecordBase";
import BusinessId from "../../types/BusinessId";
import AccessToken from "../../types/AccessToken";
import { Loader } from "./Loader";

interface ListLoaderProps<T extends RecordBase> {
  businessId: BusinessId;
  setRecords: (records: Array<T>) => void;
  fetchRecords: (businessId: BusinessId) => Promise<Array<T>>;
}

const fetchData = <T extends RecordBase>(
  businessId: BusinessId,
  fetchRecords: (businessId: string) => Promise<Array<T>>,
  setRecords: (records: Array<T>) => void
) => async () => {
  setRecords(await fetchRecords(businessId));
};

const ListLoader: React.FC<ListLoaderProps<any>> = ({
  children,
  businessId,
  fetchRecords,
  setRecords
}) => {
  return (
    <Loader fetchData={fetchData(businessId, fetchRecords, setRecords)}>
      {children}
    </Loader>
  );
};

export { ListLoader };
