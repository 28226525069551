import * as React from "react";
import Select from "./Select";
import { enumToKeyValues } from "./enum/enumToKeyValues";
import SuperType from "../../../payroll/payitem/api/SuperType";

interface SuperTypeInputProps {
  selected: SuperType;
  update: (name: string, value: string) => void;
}

const SuperTypeInput: React.SFC<SuperTypeInputProps> = ({
  selected,
  update
}) => (
  <Select
    name="type"
    collection={enumToKeyValues(SuperType)}
    selected={selected}
    update={update}
  />
);

export { SuperTypeInput };
