import * as React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';


interface ButtonBarProps {
  children: JSX.Element[] | JSX.Element;
  center?: boolean;
}

const ButtonBar: React.SFC<ButtonBarProps> = ({center, children}) => {
  return <Row className={"mb-2"}>
    <Col sm={12}>
      <div className={center ? "text-center" : "text-right"}>
        {children}
      </div>
    </Col>
  </Row>
};

export {ButtonBar};
