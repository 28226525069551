import * as React from "react";
import { History } from "history";
import { ROUTES } from "../../../../AppRouter";
import { deleteEarning, fetchEarnings } from "../../api/earning/EarningApi";
import { CrudListPage } from "../../../../components/widgets/CrudListPage";
import { EarningRow } from "./EarningRow";

interface EarningListPageProps {
  history: History;
}

const EarningListPage: React.FC<EarningListPageProps> = ({ history }) => {
  return (
    <CrudListPage
      entityName="earning"
      createRoute={ROUTES.EARNING_CREATE}
      deleteRecord={deleteEarning}
      fetchRecords={fetchEarnings}
      history={history}
      headings={["Display name", "Type", "STP category", ""]}
      RowComponent={EarningRow}
    />
  );
};

export default EarningListPage;
