import * as React from "react";
import Select from "./Select";
import { enumToKeyValues } from "./enum/enumToKeyValues";
import EarningCalculationMethod from "../../../payroll/payitem/api/EarningCalculationMethod";

interface EarningCalculationMethodInputProps {
  selected: EarningCalculationMethod;
  update: (name: string, value: string) => void;
}

const EarningCalculationMethodInput: React.SFC<
  EarningCalculationMethodInputProps
> = ({ selected, update }) => (
  <Select
    name="calculationMethod"
    collection={enumToKeyValues(EarningCalculationMethod)}
    selected={selected}
    update={update}
  />
);

export { EarningCalculationMethodInput };
