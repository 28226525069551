import * as React from "react";
import DraftPay from '../../../api/DraftPay';
import {RowComponentProps} from '../../../../../components/widgets/table/RowComponentProps';

const PayrunEmployeeRow: React.FC<RowComponentProps<DraftPay>> = ({item, onSelect}) => {
  const selectEmployee = (): void => {
    if (onSelect) {
      onSelect(item.employeeId);
    }
  };

  return (<tr onClick={selectEmployee}>
    <td>
      {item.employeeName}
    </td>
  </tr>);
};

export {PayrunEmployeeRow};
