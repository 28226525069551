import * as React from "react";
import { Spinner } from "reactstrap";
import styled from "styled-components";

const Loading: React.SFC = () => (
  <SpinnerArea>
    <SpinnerContainer>
      <Spinner color="primary" />
    </SpinnerContainer>
  </SpinnerArea>
);

const SpinnerArea = styled.div`
  width: 100%;
  height: 100%;
`;

const SpinnerContainer = styled.div`
  padding-top: 25%;
  padding-left: 50%;
`;

export { Loading };
