import { History } from "history";
import { ROUTES } from "../../../../AppRouter";
import * as React from "react";
import { useContext } from "react";
import { AppContext, IAppContext } from "../../../../context/AppContext";
import {
  fetchEntitlement,
  saveEntitlement
} from "../../api/entitlement/EntitlementApi";
import {
  EntitlementModel,
  EntitlementModelErrors
} from "../../../employee/service/entitlement/EntitlementModel";
import { EntitlementView } from "./EntitlementView";
import { fieldValidation } from "../../../../common/Model";
import { required } from "../../../../common/validation/RequiredValidator";
import EntitlementCalculationMethod from "../../api/EntitlementCalculationMethod";
import StpType from "../../api/StpType";
import { CrudForm } from "../../../../components/widgets/CrudForm";

interface EntitlementPageProps {
  match: any;
  history: History;
}

const validators = [
  fieldValidation("name", [required]),
  fieldValidation("displayName", [required])
];

const EntitlementPage: React.FC<EntitlementPageProps> = ({
  history,
  match
}) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const initialModel = {
    name: "",
    displayName: "",
    calculationMethod: EntitlementCalculationMethod.FIXED_AMOUNT,
    stpType: StpType.GROSS_PAYMENTS,
    amount: 0.0,
    employees: [],
    isValid: false,
    errors: {} as EntitlementModelErrors
  } as EntitlementModel;

  return (
    <CrudForm
      id={match.params.id}
      businessId={appState.currentBusinessId}
      validators={validators}
      initialModel={initialModel}
      history={history}
      entityName="entitlement"
      FormViewComponent={EntitlementView}
      saveModel={saveEntitlement}
      fetchModel={fetchEntitlement}
      listRoute={ROUTES.ENTITLEMENT_LIST}
    />
  );
};

export { EntitlementPage };
