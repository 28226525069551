import {getBaseUrl} from "../../../business/api/BusinessApi";
import BusinessId from "../../../types/BusinessId";
import Employee from "./Employee";
import {fetchList, fetchSingle, save} from "../../../common/api/api";
import {EmployeeUpdate} from "./EmployeeUpdate";
import {idToken} from "../../../auth/service/AuthService";

const fetchEmployees = async (
  businessId: BusinessId
): Promise<Array<Employee>> =>
  fetchList<Employee>(await idToken(), `${getBaseUrl(businessId)}/employees`);

const fetchEmployee = async (
  businessId: BusinessId,
  employeeId: string
): Promise<Employee> =>
  fetchSingle<Employee>(
    await idToken(),
    `${getBaseUrl(businessId)}/employees/${employeeId}`
  );

const createEmployee = async (
  businessId: BusinessId,
  employeeId: string,
  employeeCreateDto: EmployeeUpdate
): Promise<void> =>
  save(
    await idToken(),
    `${getBaseUrl(businessId)}/employees`,
    employeeCreateDto
  );

const updateEmployee = async (
  businessId: BusinessId,
  employeeId: string,
  employeeUpdateDto: EmployeeUpdate
): Promise<void> =>
  save(
    await idToken(),
    `${getBaseUrl(businessId)}/employees/${employeeId}`,
    employeeUpdateDto
  );

export { fetchEmployees, fetchEmployee, createEmployee, updateEmployee };
