import * as React from "react";

import {Button, Card, CardText, CardTitle, Col, Row} from "reactstrap";
import {ButtonBar} from "../../../../../components/widgets/ButtonBar";

interface EmployeeTabProps {
  name: string;
  children: JSX.Element;
  onSave: () => void;
  onCancel: () => void;
}

const EmployeeTab: React.SFC<EmployeeTabProps> = ({
  name,
  children,
  onSave,
  onCancel
}) => {
  return (
    <Row>
      <Col sm="12">
        <Card body>
          <CardTitle>{name}</CardTitle>
          <CardText>
            {children}
            <ButtonBar>
              <Button color="secondary" className={"mr-2"} onClick={onCancel}>
                Cancel
              </Button>
              <Button color="primary" className={"mr-2"} onClick={onSave}>
                Save
              </Button>
            </ButtonBar>
          </CardText>
        </Card>
      </Col>
    </Row>
  );
};

export { EmployeeTab };
