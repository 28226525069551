import React from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import TextInput from "../../../../components/widgets/input/TextInput";
import {SuperModel} from "../../../employee/service/super/SuperModel";
import MoneyInput from "../../../../components/widgets/input/MoneyInput";
import {StpTypeInput} from "../../../../components/widgets/input/StpTypeInput";
import {CrudFormViewComponentProps} from '../../../../components/widgets/CrudFormViewComponentProps';
import {EntitlementCalculationMethodInput} from '../../../../components/widgets/input/EntitlementCalculationMethodInput';
import {SuperCalculationMethodInput} from '../../../../components/widgets/input/SuperCalculationMethodInput';
import {SuperTypeInput} from '../../../../components/widgets/input/SuperTypeInput';

const SuperView: React.FC<CrudFormViewComponentProps<SuperModel>> = ({
  model,
  onUpdate,
  showErrors
}) => (
  <>
    <Row>
      <Col>
        <Form>
          <FormGroup row>
            <Label for="name" sm={4}>
              Name
            </Label>
            <Col sm={8}>
              <TextInput
                name="name"
                value={model.name}
                update={onUpdate}
                error={model.errors.name}
                showErrors={showErrors}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="type" sm={4}>
              Type
            </Label>
            <Col sm={8}>
              <SuperTypeInput selected={model.type} update={onUpdate} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="amount" sm={4}>
              Amount
            </Label>
            <Col sm={8}>
              <MoneyInput
                name="amount"
                value={model.amount}
                update={onUpdate}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="calculationMethod" sm={4}>
              Calculation method
            </Label>
            <Col sm={8}>
              <SuperCalculationMethodInput
                selected={model.calculationMethod}
                update={onUpdate}
              />
            </Col>
          </FormGroup>
        </Form>
      </Col>
      <Col>
        <Form>
          <FormGroup row>
            <Label for="displayName" sm={4}>
              Name on payslip
            </Label>
            <Col sm={8}>
              <TextInput
                name="displayName"
                value={model.displayName}
                update={onUpdate}
                error={model.errors.displayName}
                showErrors={showErrors}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="stpType" sm={4}>
              STP category
            </Label>
            <Col sm={8}>
              <StpTypeInput selected={model.stpType} update={onUpdate} />
            </Col>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  </>
);

export { SuperView };
