import * as React from "react";
import { PayFrequency } from "../../../payroll/employee/api/PayFrequency";
import KeyValuePair from "./KeyValuePair";
import Select from "./Select";

interface FinancialYearInputProps {
  selected: string;
  update: (name: string, value: string) => void;
}

const financialYears: Array<KeyValuePair> = new Array<KeyValuePair>(
  new KeyValuePair("2020", "2019-2020"),
  new KeyValuePair("2021", "2020-2021")
);

const FinancialYearInput: React.SFC<FinancialYearInputProps> = ({
  selected,
  update
}) => (
  <Select
    name="financialYear"
    collection={financialYears}
    selected={selected.toString()}
    update={update}
  />
);

export { FinancialYearInput };
