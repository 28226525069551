import * as React from "react";
import PublicPage from "../PublicPage";
import Page from "../../../components/widgets/Page";
import {Col, Row} from "reactstrap";
import { CashCoin, FileEarmarkRuledFill, CaretRightFill, PeopleFill, ArrowDownSquareFill, PlusSquareFill, BarChartFill } from 'react-bootstrap-icons';

export const FeaturesPage: React.FC = ({}) => {
  return (
    <PublicPage>
      <Page>
        <Row>
          <Col sm={12} className="mt-5">
            <h1>Features</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={5} className="mt-5 d-flex align-items-center font-weight-bold">
            <CashCoin className="mr-3" color="#EB8157" /> Allowances
          </Col>
          <Col sm={7} className="mt-5">
            Configurable allowance calculations
          </Col>
        </Row>
        <Row>
          <Col sm={5} className="mt-5 d-flex align-items-center font-weight-bold">
            <ArrowDownSquareFill className="mr-3" color="#EB8157" /> Deductions
          </Col>
          <Col sm={7} className="mt-5">
            Configurable deduction calculations
          </Col>
        </Row>
        <Row>
          <Col sm={5} className="mt-5 d-flex align-items-center font-weight-bold">
            <BarChartFill className="mr-3" color="#EB8157" /> Superannuation
          </Col>
          <Col sm={7} className="mt-5">
            Super entitlement and salary sacrifice
          </Col>
        </Row>
        <Row>
          <Col sm={5} className="mt-5 d-flex align-items-center font-weight-bold">
            <PlusSquareFill className="mr-3" color="#EB8157" /> Entitlements
          </Col>
          <Col sm={7} className="mt-5">
            Configurable entitlements such as annual, sick and long service leave
          </Col>
        </Row>
        <Row>
          <Col sm={5} className="mt-5  d-flex align-items-center font-weight-bold">
            <PeopleFill className="mr-3" color="#EB8157" /> Employee management
          </Col>
          <Col sm={7} className="mt-5">
            Manage employee contact settings and default pay
          </Col>
        </Row>
        <Row>
          <Col sm={5} className="mt-5 d-flex align-items-center font-weight-bold">
            <CaretRightFill className="mr-3" color="#EB8157" /> Payruns
          </Col>
          <Col sm={7} className="mt-5">
            Calculate pays in batch
          </Col>
        </Row>
        <Row>
          <Col sm={5} className="mt-5 d-flex align-items-center font-weight-bold">
            <FileEarmarkRuledFill className="mr-3" color="#EB8157" /> Payslips
          </Col>
          <Col sm={7} className="mt-5">
            Generate paylsips in PDF to send to employees
          </Col>
        </Row>

      </Page>
    </PublicPage>
  );
};
