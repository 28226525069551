import * as React from "react";
import PublicPage from "../PublicPage";
import Page from "../../../components/widgets/Page";
import {Col, Row} from "reactstrap";

export const AboutPage: React.FC = ({}) => {
  return (
    <PublicPage>
      <Page>
        <Row>
          <Col sm={12} className="mt-5">
            <h1>About Keisan</h1>
          </Col>
        </Row>
        <Row>
          <Col sm={12} className="mt-5">
            <p>Keisan is a cloud payroll software provider based in Melbourne.</p>
          </Col>
        </Row>
      </Page>
    </PublicPage>
  );
};
