import * as React from 'react';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';


interface FilterBarProps {
  children: JSX.Element
}

const FilterBar: React.SFC<FilterBarProps> = ({children}) => {
  return <Row className={"mb-2"}>
    <Col sm={12}>
      <div>
        {children}
      </div>
    </Col>
  </Row>
};

export {FilterBar};