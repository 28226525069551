import {getBaseUrl} from "../../../../business/api/BusinessApi";
import BusinessId from "../../../../types/BusinessId";
import Deduction from "./Deduction";
import {deleteRecord, fetchList, fetchSingle, save} from "../../../../common/api/api";
import {DeductionModel} from "../../../employee/service/deduction/DeductionModel";
import {IdModel} from "../../../../common/api/IdModel";
import {idToken} from "../../../../auth/service/AuthService";

const baseUrl = (businessId: string) =>
  getBaseUrl(businessId) + "/pay-items/deductions";

const deductionurl = (businessId: string, id: string) =>
  `${baseUrl(businessId)}/${id}`;

const fetchDeductions = async (
  businessId: BusinessId
): Promise<Array<Deduction>> => {
  return fetchList<Deduction>(await idToken(), baseUrl(businessId));
};

const fetchDeduction = async (
  businessId: BusinessId,
  id: string
): Promise<DeductionModel> => {
  return fetchSingle<DeductionModel>(
    await idToken(),
    deductionurl(businessId, id)
  );
};

const saveDeduction = async (
  businessId: BusinessId,
  deduction: DeductionModel
): Promise<string> => {
  const idModel = await save<IdModel>(
    await idToken(),
    deduction.id ? deductionurl(businessId, deduction.id) : baseUrl(businessId),
    deduction
  );
  return idModel.id;
};

const deleteDeduction = async (
  businessId: BusinessId,
  earningId: string
): Promise<void> => {
  await deleteRecord(await idToken(), deductionurl(businessId, earningId));
};

export { fetchDeductions, fetchDeduction, saveDeduction, deleteDeduction };
