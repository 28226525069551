import * as React from "react";
import Select from "./Select";
import KeyValuePair from "./KeyValuePair";
import EarningType from '../../../payroll/payitem/api/EarningType';

interface EarningTypeInputProps {
  selected: EarningType;
  update: (name: string, value: EarningType) => void;
}

const earningTypes: Array<KeyValuePair> = new Array<KeyValuePair>(
  new KeyValuePair("NORMAL", "Normal"),
  new KeyValuePair("LEAVE", "Leave"),
  new KeyValuePair("ALLOWANCE", "Allowance"),
  new KeyValuePair("COMMISSION", "Commission"),
  new KeyValuePair("BONUS", "Bonus"),
  new KeyValuePair("ETP", "ETP")
);

const EarningTypeInput: React.SFC<EarningTypeInputProps> = ({
  selected,
  update
}) => {
  return (
    <Select
      name="type"
      collection={earningTypes}
      selected={selected.toString()}
      update={update}
    />
  );
};

export {EarningTypeInput };
