import * as React from "react";
import { useContext, useState } from "react";
import { History } from "history";
import Page from "../../../../../components/widgets/Page";
import H2 from "../../../../../components/widgets/H2";
import { Button } from "reactstrap";
import { AppContext, IAppContext } from "../../../../../context/AppContext";
import PayrunForm from "../model/PayrunForm";
import DraftPay from "../../../api/DraftPay";
import { PayFrequency } from "../../../../employee/api/PayFrequency";
import moment from "moment";
import H4 from "../../../../../components/widgets/H4";
import { ROUTES } from "../../../../../AppRouter";
import { findPayrun } from "../../../service/PayrunService";

export interface PayrunPageProps {
  history: History;
  match: any;
}

const goBack = (history: History) => () => {
  history.push(ROUTES.DASHBOARD);
};

const printPayslips = (
  history: History,
  businessId: string,
  draftPayrunid: string
) => async () => {};

const reportToAto = (
  history: History,
  businessId: string,
  draftPayrunid: string
) => async () => {
  history.push(ROUTES.ATO_REPORTING);
};

const PayrunCompletePage: React.FC<PayrunPageProps> = ({ history, match }) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const [payrun, setPayrun] = useState<PayrunForm>({
    frequency: PayFrequency.MONTHLY,
    startDate: moment(),
    endDate: moment(),
    payDate: moment(),
    employees: [] as Array<DraftPay>,
    errors: {},
    changed: false
  } as PayrunForm);
  const fetchData = async () => {
    const payrun = await findPayrun(appState.currentBusinessId, match.params.id);
    setPayrun(payrun);
  };

  return (
    <Page>
      <H2>Payrun complete</H2>
      <H4>All done.</H4>

      <div className="row">
        <div className="col text-center">
          <p>Click here to print the payslips.</p>
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <Button
            color="primary"
            className="mx-2"
            onClick={printPayslips(history, appState.currentBusinessId, match.params.id)}
          >
            Print payslips
          </Button>
        </div>
      </div>
      <hr />

      <div className="row">
        <div className="col text-center">
          <p>
            Click here to report this payrun to the ATO (Single Touch Payroll).
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <Button
            color="secondary"
            className="mx-2"
            onClick={reportToAto(history, appState.currentBusinessId, match.params.id)}
          >
            Report to ATO
          </Button>
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col text-center">
          <p>Go back to the payrun dashboard.</p>
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <Button color="secondary" onClick={goBack(history)}>
            Dashboard
          </Button>
        </div>
      </div>
    </Page>
  );
};

export { PayrunCompletePage };
