import { History } from "history";
import { ROUTES } from "../../AppRouter";
import React, { useContext } from "react";
import { BusinessListView } from "./BusinessListView";
import { AppContext, IAppContext, IAppState } from "../../context/AppContext";
import BusinessId from "../../types/BusinessId";

interface BusinessListPageProps {
  history: History;
}

const selectBusiness = (
  history: History,
  updateAppState: (name: string, value: any) => void
) => (selectedBusinessId: BusinessId) => {
  updateAppState('currentBusinessId', selectedBusinessId)
  history.push(ROUTES.DASHBOARD);
};

const onCreateclick = (history: History) => () => {
  history.push(ROUTES.BUSINESS_CREATE);
};

const BusinessListPage: React.FC<BusinessListPageProps> = ({ history }) => {
  const { updateAppState } = useContext<IAppContext>(AppContext);

  return (
    <BusinessListView
      onCreateClick={onCreateclick(history)}
      selectBusiness={selectBusiness(history, updateAppState)}
    />
  );
};

export { BusinessListPage };
