import * as React from "react";
import styled from "styled-components";

interface DeleteButtonProps {
  onClick: () => void;
}

const DeleteButton: React.SFC<DeleteButtonProps> = ({ onClick }) => (
  <DeleteButtonIcon className="material-icons" onClick={onClick}>
    cancel
  </DeleteButtonIcon>
);

const DeleteButtonIcon = styled.i`
  cursor: pointer;
`;

export { DeleteButton };
