import * as React from "react";
import Select from "./Select";
import KeyValuePair from "./KeyValuePair";
import ResidencyStatus from "../../../payroll/employee/api/ResidencyStatus";

interface ResidencyStatusInputProps {
  selected: ResidencyStatus;
  update: (name: string, value: ResidencyStatus) => void;
}

const collection: Array<KeyValuePair> = new Array<KeyValuePair>(
  new KeyValuePair("AustralianResident", "AustralianResident"),
  new KeyValuePair("ForeignResident", "ForeignResident"),
  new KeyValuePair("WorkingHolidayMaker", "WorkingHolidayMaker")
);

const ResidencyStatusInput: React.SFC<ResidencyStatusInputProps> = ({
  selected,
  update
}) => {
  return (
    <Select
      name="residencyStatus"
      collection={collection}
      selected={selected.toString()}
      update={update}
    />
  );
};

export default ResidencyStatusInput;
