import { getBaseUrl } from "../../../../business/api/BusinessApi";
import BusinessId from "../../../../types/BusinessId";
import AccessToken from "../../../../types/AccessToken";
import Entitlement from "./Entitlement";
import {
  deleteRecord,
  fetchList,
  fetchSingle,
  save
} from "../../../../common/api/api";
import { EntitlementModel } from "../../../employee/service/entitlement/EntitlementModel";
import { IdModel } from "../../../../common/api/IdModel";
import {idToken} from "../../../../auth/service/AuthService";

const baseUrl = (businessId: string) =>
  getBaseUrl(businessId) + "/pay-items/entitlements";

const entitlementUrl = (businessId: string, id: string) =>
  `${baseUrl(businessId)}/${id}`;

const fetchEntitlements = async (
  businessId: BusinessId
): Promise<Array<Entitlement>> => {
  return fetchList<Entitlement>(await idToken(), baseUrl(businessId));
};

const fetchEntitlement = async (
  businessId: BusinessId,
  id: string
): Promise<EntitlementModel> => {
  return fetchSingle<EntitlementModel>(await idToken(), entitlementUrl(businessId, id));
};

const saveEntitlement = async (
  businessId: BusinessId,
  entitlement: EntitlementModel
): Promise<string> => {
  const idModel = await save<IdModel>(
    await idToken(),
    entitlement.id ? entitlementUrl(businessId, entitlement.id) : baseUrl(businessId),
    entitlement
  );
  return idModel.id;
};

const deleteEntitlement = async (businessId: BusinessId, earningId: string): Promise<void> => {
  await deleteRecord(await idToken(), entitlementUrl(businessId, earningId));
};

export { fetchEntitlements, fetchEntitlement, saveEntitlement, deleteEntitlement };
