import * as React from "react";
import { Col, Row } from "reactstrap";
import {formatMoney} from "../../../../../common/format/NumberFormat";

export interface SumRowProps {
  label: string;
  total: number;
}

const SumRow: React.FC<SumRowProps> = ({ label, total }) => {
  return (
    <Row className="mb-2">
      <Col sm="6">
        <p className="font-weight-bold">{label}</p>
      </Col>
      <Col sm="2"></Col>
      <Col sm="2"></Col>
      <Col sm="2">
        <p className="font-weight-bold text-right">{formatMoney(total)}</p>
      </Col>
    </Row>
  );
};

export { SumRow };
