import PayForm from "../components/payrun/model/PayForm";
import {findCompletedPays, updatePay} from "../api/PayApi";
import AccessToken from "../../../types/AccessToken";
import BusinessId from "../../../types/BusinessId";
import Pay from "../api/Pay";
import PayModel from "./PayModel";
import moment, {Moment} from "moment";
import {PayFrequency} from "../../employee/api/PayFrequency";
import {payslipUrl} from "../api/PayslipApi";
import {idToken} from "../../../auth/service/AuthService";


const savePay = async (businessId: BusinessId, payrunId: string, payForm: PayForm): Promise<PayForm> => {
  return await updatePay(businessId, payrunId, payForm);
};

const findPastPays = async (businessId: BusinessId, employeeId: string): Promise<Array<PayModel>> => {
  const token = await idToken();
  const pays = await findCompletedPays(businessId, employeeId);
  return pays.map(it => convertPay(token, businessId, it));
};

const convertPay = (token: string, businessId: BusinessId, pay: Pay): PayModel => {
  const payslip = payslipUrl(token, businessId, pay.id);
  return {
    ...pay,
    payslip: payslip,
    payrun: {
      id: pay.payrun.id,
      createdDate: moment(pay.payrun.createdDate),
      startDate: moment(pay.payrun.startDate),
      endDate: moment(pay.payrun.endDate),
      payDate: moment(pay.payrun.payDate),
      frequency: pay.payrun.frequency
    }
  };
};


export {savePay, findPastPays};
