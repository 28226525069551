import * as React from "react";
import styled from "styled-components";
import {useEffect} from "react";

interface AuthPageProps {}

const AuthPage: React.FC<AuthPageProps> = props => {
  useEffect(() => {
    document.body.style.backgroundImage = "linear-gradient(rgb(49, 54, 75), rgb(89,94,118))";
    document.body.style.height = '100vh';
    document.body.style.backgroundRepeat = 'no-repeat';
  }, []);

  return (
    <AuthPageContailer className="container">
      <AuthPageStyled>{props.children}</AuthPageStyled>
    </AuthPageContailer>
  );
};

const AuthPageContailer = styled.div`
`

const AuthPageStyled = styled.div`
  background-color: #e7e7ef;
  
  
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  
  /* just in case there no content*/
  padding: 20px 25px 30px;
  
  
  /* shadows and rounded borders */
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  
  max-width: 450px;
  padding: 40px 40px;
`;

export default AuthPage;
