import * as React from "react";
import PayFormDeduction from "../model/PayFormDeduction";
import MoneyInput from "../../../../../components/widgets/input/MoneyInput";
import { Col, Row } from "reactstrap";
import { SimpleRowComponentProps } from "../../../../../components/widgets/list/SimpleRowComponentProps";
import {formatMoney} from "../../../../../common/format/NumberFormat";

const PayDeductionRow: React.FC<SimpleRowComponentProps<PayFormDeduction>> = ({
  item,
  entityName,
  index,
  updateModel,
  modelNamePrefix
}) => {
  return (
    <Row className="mb-2">
      <Col sm="6">{item.displayName}</Col>
      <Col sm="2"></Col>
      <Col sm="2">
        <MoneyInput
          name={`${modelNamePrefix}amount`}
          value={item.amount}
          update={updateModel}
        />
      </Col>
      <Col sm="2" className="text-right">{formatMoney(item.total)}</Col>
    </Row>
  );
};

export { PayDeductionRow };
