import * as React from "react";
import { EmployeeBankDetailsForm } from "../../forms/EmployeeBankDetailsForm";
import { EmployeeTab } from "./EmployeeTab";
import { EmployeeModel } from "../../../service/employee/EmployeeModel";

interface EmployeeBankDetailsTabProps {
  employee: EmployeeModel;
  onSave: () => void;
  onCancel: () => void;
  updateModel: (name: string, value: string) => void;
}

const EmployeeBankDetailsTab: React.SFC<EmployeeBankDetailsTabProps> = ({
  employee,
  onSave,
  onCancel,
  updateModel
}) => {
  return (
    <EmployeeTab name={"Bank details"} onCancel={onCancel} onSave={onSave}>
      <EmployeeBankDetailsForm employee={employee} updateModel={updateModel} />
    </EmployeeTab>
  );
};

export { EmployeeBankDetailsTab };
