import * as React from "react";
import {RowComponentProps} from "../widgets/table/RowComponentProps";
import PayrunModel from "../../payroll/payrun/components/payrun/model/PayrunModel";
import {formatDate} from "../../common/Date";
import _ from "lodash";
import {Button, Tooltip, UncontrolledTooltip} from "reactstrap";
import {useState} from "react";

const ATOReportingRow: React.FC<RowComponentProps<PayrunModel>> = ({
  item
}) => {
  return (
    <tr>
      <td>{_.capitalize(item.frequency)}</td>
      <td>{formatDate(item.startDate)}</td>
      <td>{formatDate(item.endDate)}</td>
      <td>{formatDate(item.payDate)}</td>
      <td>Completed</td>
      <td>
        <Button color="secondary">Lodge</Button>
        <UncontrolledTooltip placement="right" target="Button" trigger="click">
          Coming soon
        </UncontrolledTooltip>
      </td>
    </tr>
  );
};

export { ATOReportingRow };
