import Deduction from "../../api/deduction/Deduction";
import React from "react";
import {Link} from "react-router-dom";
import {getEditRoute} from "../../../../common/Routing";
import {ROUTES} from "../../../../AppRouter";
import {RowComponentProps} from "../../../../components/widgets/table/RowComponentProps";
import TableDeleteButton from "../../../../components/widgets/table/TableDeleteButton";

const DeductionRow: React.FC<RowComponentProps<Deduction>> = ({
  item,
  onDeleteClick,
  entityName
}) => (
  <tr>
    <td>
      <Link
        to={getEditRoute(ROUTES.DEDUCTION_EDIT, item.id)}
        className="nav-link"
      >
        {item.name}
      </Link>
    </td>
    <td>{item.type}</td>
    <td>{item.stpType}</td>
    <td>
      <TableDeleteButton
        entityName={entityName}
        onDeleteClick={() => {
          onDeleteClick(item.id);
        }}
      />
    </td>
  </tr>
);

export { DeductionRow };
