import * as React from "react";
import {Business} from "../api/Business";
import BusinessId from "../../types/BusinessId";
import {Button} from "reactstrap";

export interface BusinessRowProps {
  business: Business;
  selectBusiness: (selectedBusinessId: BusinessId) => void;
}

const onSelect = (
  id: BusinessId,
  selectBusiness: (selectedBusinessId: BusinessId) => void
) => () => {
  selectBusiness(id);
};

const BusinessRow: React.FC<BusinessRowProps> = ({
  business,
  selectBusiness
}) =>
  (
    <tr>
      <td>
        <Button onClick={onSelect(business.id, selectBusiness)} color="link">{business.name}</Button>
      </td>
    </tr>
  );

export { BusinessRow };
