import * as React from "react";
import { History } from "history";
import { ROUTES } from "../../../../AppRouter";
import { deleteDeduction, fetchDeductions } from "../../api/deduction/DeductionApi";
import { CrudListPage } from "../../../../components/widgets/CrudListPage";
import { DeductionRow } from "./DeductionRow";

interface DeductionListPageProps {
  history: History;
}

const DeductionListPage: React.FC<DeductionListPageProps> = ({ history }) => {
  return (
    <CrudListPage
      entityName="deduction"
      createRoute={ROUTES.DEDUCTION_CREATE}
      deleteRecord={deleteDeduction}
      fetchRecords={fetchDeductions}
      history={history}
      headings={["Display name", "Type", "STP category", ""]}
      RowComponent={DeductionRow}
    />
  );
};

export default DeductionListPage;
