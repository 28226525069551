import * as React from "react";
import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { EmployeePersonalDetailsTab } from "./tabs/EmployeePersonalDetailsTab";
import { EmployeeEmergencyContactTab } from "./tabs/EmployeeEmergencyContactTab";
import { EmploymentDetailsTab } from "./tabs/EmploymentDetailsTab";
import { EmployeeBankDetailsTab } from "./tabs/EmployeeBankDetailsTab";
import { EmployeeTaxTab } from "./tabs/EmpoyeeTaxTab";
import { EmployeeStandardPayTab } from "./tabs/EmployeeStandardPayTab";
import { EmployeePastPaysTab } from "./tabs/EmployeePastPaysTab";
import { EmployeeModel } from "../../service/employee/EmployeeModel";

interface EmployeeTabsProps {
  employee: EmployeeModel;
  onSave: () => void;
  onCancel: () => void;
  updateModel: (name: string, value: any) => void;
}

const EmployeeTabs: React.FC<EmployeeTabsProps> = ({
  employee,
  onSave,
  onCancel,
  updateModel,
}) => {
  const [activeTab, setActiveTab] = useState<string>("PERSONAL");
  const toggle = (tab: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <Nav tabs>
        <NavItem>
          <NavLink
            active={activeTab === "PERSONAL"}
            onClick={() => {
              toggle("PERSONAL");
            }}
          >
            Personal details
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "EMERGENCY_CONTACT"}
            onClick={() => {
              toggle("EMERGENCY_CONTACT");
            }}
          >
            Emergency contact
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={activeTab === "EMPLOYEMENT"}
            onClick={() => {
              toggle("EMPLOYEMENT");
            }}
          >
            Employement details
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            active={activeTab === "TAX"}
            onClick={() => {
              toggle("TAX");
            }}
          >
            Tax
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            active={activeTab === "BANK_ACCOUNTS"}
            onClick={() => {
              toggle("BANK_ACCOUNTS");
            }}
          >
            Bank accounts
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            active={activeTab === "STANDARD_PAY"}
            onClick={() => {
              toggle("STANDARD_PAY");
            }}
          >
            Standard pay
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            active={activeTab === "PAYS"}
            onClick={() => {
              toggle("PAYS");
            }}
          >
            Past pays
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="PERSONAL">
          <EmployeePersonalDetailsTab
            employee={employee}
            onSave={onSave}
            onCancel={onCancel}
            updateModel={updateModel}
          />
        </TabPane>
        <TabPane tabId="EMERGENCY_CONTACT">
          <EmployeeEmergencyContactTab
            employee={employee}
            onSave={onSave}
            onCancel={onCancel}
            updateModel={updateModel}
          />
        </TabPane>
        <TabPane tabId="EMPLOYEMENT">
          <EmploymentDetailsTab
            employee={employee}
            onSave={onSave}
            onCancel={onCancel}
            updateModel={updateModel}
          />
        </TabPane>
        <TabPane tabId="TAX">
          <EmployeeTaxTab
            employee={employee}
            onSave={onSave}
            onCancel={onCancel}
            updateModel={updateModel}
          />
        </TabPane>
        <TabPane tabId="BANK_ACCOUNTS">
          <EmployeeBankDetailsTab
            employee={employee}
            onSave={onSave}
            onCancel={onCancel}
            updateModel={updateModel}
          />
        </TabPane>
        <TabPane tabId="STANDARD_PAY">
          <EmployeeStandardPayTab
            employee={employee}
            onSave={onSave}
            onCancel={onCancel}
            updateModel={updateModel}
          />
        </TabPane>
        <TabPane tabId="PAYS">
          <EmployeePastPaysTab
            employee={employee}
            onSave={onSave}
            onCancel={onCancel}
          />
        </TabPane>
      </TabContent>
    </div>
  );
};

export { EmployeeTabs };
