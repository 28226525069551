import { Validator } from "./validation/Validator";
import { FieldValidation } from "./validation/FieldValidation";
import _ from 'lodash';

const fieldValidation = (
  fieldName: string,
  validators: Array<Validator>
): FieldValidation => {
  return {
    fieldName,
    validators
  } as FieldValidation;
};

const checkModelValid = (errors: any) => {
  const actualErrors = Object.keys(errors).filter(fieldName => {
    return errors[fieldName].length > 0;
  });
  return actualErrors.length === 0;
};

const validateModel = <T>(model: T, validators: Array<FieldValidation>) => {
  let errors = {};

  for (const fieldName in model) {
    if (fieldName === "errors" || fieldName == "isValid") {
      continue;
    }
    // @ts-ignore
    errors[fieldName] = validate(fieldName, model[fieldName], validators);
  }

  // @ts-ignore
  model.isValid = checkModelValid(errors);
  // @ts-ignore
  model.errors = errors;

  return Object.assign({}, model);
};

const updateModel = <T>(
  model: T,
  setModel: (model: T) => void,
  validators: Array<FieldValidation>,
  computeValues?: (name: string, value: any, model: T, setValue: (name: string, value: any) => void) => void
) => (name: string, value: any) => {
  // @ts-ignore
  if (!model.errors) {
    // @ts-ignore
    model.errors = {};
  }

  // @ts-ignore
  _.set(model, name, value);
  const error = validate(name, value, validators);
  // @ts-ignore
  _.set(model.errors, name, error);
  // @ts-ignore
  model.isValid = checkModelValid(model.errors);
  if (name != 'changed') {
    // @ts-ignore
    _.set(model, 'changed', true);
  }
  const updated = Object.assign({}, model);
  // @ts-ignore
  //console.log(updated);
  setModel(updated);

  if (computeValues) {
    computeValues(name, value, model, updateModel(model, setModel, validators));
  }

  return updated;
};

const validate = (
  name: string,
  value: string,
  validators: Array<FieldValidation>
): string => {
  let errors: (string | null)[] = [];
  const validatorsForThisInput = validators.find(it => {
    return it.fieldName === name;
  });
  if (validatorsForThisInput) {
    errors = validatorsForThisInput.validators
      .map((validator: Validator) => {
        return validator(value);
      })
      .filter(message => {
        return message !== null;
      });
  }
  return errors.length > 0 && errors[0] != null ? errors[0] : "";
};

const addError = <T>(model: T, setModel: (model: T) => void) => (
  name: string,
  error: Error
) => {
  // @ts-ignore
  const errors = model["errors"] === undefined ? {} : model["errors"];
  if (error) {
    // @ts-ignore
    errors[name] = error;
  } else if (errors[name] !== undefined) {
    delete errors[name];
  }

  // @ts-ignore
  model["errors"] = errors;
  setModel(Object.assign({}, model));
};

export { updateModel, addError, fieldValidation, validateModel };
