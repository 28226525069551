import { History } from "history";
import {
  completePayrun,
  createPayrun,
  deletePayruns,
  fetchCompletedPayruns,
  fetchPayrun,
  fetchPayruns
} from "../api/PayrunApi";
import { getEditRoute } from "../../../common/Routing";
import { ROUTES } from "../../../AppRouter";
import BusinessId from "../../../types/BusinessId";
import { convertDraftPayrun } from "./DraftPayrunConverter";
import PayrunForm from "../components/payrun/model/PayrunForm";
import CreatePayrunForm from "../components/dashboard/CreatePayrunForm";
import { IdModel } from "../../../common/api/IdModel";
import PayrunModel from "../components/payrun/model/PayrunModel";
import { convertPayrun } from "./PayrunConverter";
import moment from "moment";

const startPayrun = (
  businessId: BusinessId,
  dashboardForm: CreatePayrunForm,
  history: History
) => async () => {
  const response = await createPayrun(
    businessId,
    dashboardForm.payFrequency,
    dashboardForm.startDate,
    dashboardForm.endDate,
    dashboardForm.endDate.add(7, "days"),
    dashboardForm.employees.filter(emp => emp.selected).map(emp => emp.id)
  );
  history.push(getEditRoute(ROUTES.PAYRUN_EDIT, response.id));
};

const findPayrun = async (
  businessId: BusinessId,
  id: string
): Promise<PayrunForm> => {
  let payrun = await fetchPayrun(businessId, id);
  return convertDraftPayrun(payrun);
};

const findActivePayruns = async (
  businessId: BusinessId
): Promise<Array<PayrunForm>> => {
  let payruns = await fetchPayruns(businessId);
  return payruns.map(p => convertDraftPayrun(p));
};

const findCompletedPayruns = async (
  businessId: BusinessId,
  financialYear: number
): Promise<Array<PayrunModel>> => {
  const fromDate = moment(`${financialYear - 1}-07-01`);
  const toDate = moment(`${financialYear}-06-30`);
  let payruns = await fetchCompletedPayruns(businessId, fromDate, toDate);
  return payruns.map(p => convertPayrun(p));
};

const deleteAllPayruns = async (businessId: BusinessId): Promise<void> => {
  return await deletePayruns(businessId);
};

const finalisePayrun = async (
  businessId: BusinessId,
  id: string
): Promise<IdModel> => {
  return await completePayrun(businessId, id);
};

export {
  startPayrun,
  findPayrun,
  findActivePayruns,
  deleteAllPayruns,
  finalisePayrun,
  findCompletedPayruns
};
