import * as React from "react";
import Select from "./Select";
import { enumToKeyValues } from "./enum/enumToKeyValues";
import SuperCalculationMethod from "../../../payroll/payitem/api/SuperCalculationMethod";

interface SuperCalculationMethodInputProps {
  selected: SuperCalculationMethod;
  update: (name: string, value: string) => void;
}

const SuperCalculationMethodInput: React.SFC<
  SuperCalculationMethodInputProps
> = ({ selected, update }) => (
  <Select
    name="calculationMethod"
    collection={enumToKeyValues(SuperCalculationMethod)}
    selected={selected}
    update={update}
  />
);

export { SuperCalculationMethodInput };
