import React from "react";
import {Col, Form, FormGroup, Label, Row} from "reactstrap";
import TextInput from "../../../../components/widgets/input/TextInput";
import {EarningModel} from "../../../employee/service/earning/EarningModel";
import {CheckboxInput} from "../../../../components/widgets/input/CheckboxInput";
import MoneyInput from "../../../../components/widgets/input/MoneyInput";
import {StpTypeInput} from "../../../../components/widgets/input/StpTypeInput";
import {EarningTypeInput} from "../../../../components/widgets/input/EarningTypeInput";
import {CrudFormViewComponentProps} from '../../../../components/widgets/CrudFormViewComponentProps';
import {EntitlementCalculationMethodInput} from '../../../../components/widgets/input/EntitlementCalculationMethodInput';
import {EarningCalculationMethodInput} from '../../../../components/widgets/input/EarningCalculationMethodInput';

const EarningView: React.FC<CrudFormViewComponentProps<EarningModel>> = ({
  model,
  onUpdate,
  showErrors
}) => (
  <>
    <Row>
      <Col>
        <Form>
          <FormGroup row>
            <Label for="name" sm={4}>
              Name
            </Label>
            <Col sm={8}>
              <TextInput
                name="name"
                value={model.name}
                update={onUpdate}
                error={model.errors.name}
                showErrors={showErrors}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="type" sm={4}>
              Type
            </Label>
            <Col sm={8}>
              <EarningTypeInput selected={model.type} update={onUpdate} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="type" sm={4}>
              Calculation
            </Label>
            <Col sm={8}>
              <EarningTypeInput selected={model.type} update={onUpdate} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="amount" sm={4}>
              Amount
            </Label>
            <Col sm={8}>
              <MoneyInput
                name="amount"
                value={model.amount}
                update={onUpdate}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="calculationMethod" sm={4}>
              Calculation method
            </Label>
            <Col sm={8}>
              <EarningCalculationMethodInput
                selected={model.calculationMethod}
                update={onUpdate}
              />
            </Col>
          </FormGroup>
          <FormGroup check className="my-4">
            <Label check>
              <CheckboxInput
                name="paySuper"
                checked={model.paySuper}
                update={onUpdate}
              />
              Pay super on this earning?
            </Label>
          </FormGroup>
          <FormGroup check className="my-4">
            <Label check>
              <CheckboxInput
                name="accrueLeave"
                checked={model.accrueLeave}
                update={onUpdate}
              />
              Accrue leave on this earning?
            </Label>
          </FormGroup>
          <FormGroup check className="my-4">
            <Label check>
              <CheckboxInput
                name="reducesBase"
                checked={model.reducesBase}
                update={onUpdate}
              />
              Reduce base salary?
            </Label>
          </FormGroup>
        </Form>
      </Col>
      <Col>
        <Form>
          <FormGroup row>
            <Label for="displayName" sm={4}>
              Name on payslip
            </Label>
            <Col sm={8}>
              <TextInput
                name="displayName"
                value={model.displayName}
                update={onUpdate}
                error={model.errors.displayName}
                showErrors={showErrors}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="stpType" sm={4}>
              STP category
            </Label>
            <Col sm={8}>
              <StpTypeInput selected={model.stpType} update={onUpdate} />
            </Col>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  </>
);

export { EarningView };
