import * as React from "react";
import {useState} from "react";
import {HomePageView} from "./HomePageView";
import {SignUpForm} from "../../auth/components/signup/SignUpForm";
import {ModalSignUpView} from "../../auth/components/signup/ModalSignUpView";
import { History } from "history";


export interface HomePageProps {
  history: History;
}

const tryPayroll = (
  setShowSignUpModal: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void
) => () => {
  setShowSignUpModal(true);
};

const cancelSignUp = (
  setShowSignUpModal: (
    value: ((prevState: boolean) => boolean) | boolean
  ) => void
) => () => {
  setShowSignUpModal(false);
};

const HomePage: React.FC<HomePageProps> = ({history}) => {
  const [showSignUpModal, setShowSignUpModal] = useState<boolean>(false);
  return (
    <>
      <HomePageView
        tryPayrollAction={tryPayroll(setShowSignUpModal)}
      />
      {showSignUpModal && (
        <SignUpForm
          history={history}
          goBack={cancelSignUp(setShowSignUpModal)}
          SignUpView={ModalSignUpView}
        />
      )}
    </>
  );
};

export { HomePage };
