import * as React from "react";
import * as ReactStrap from "reactstrap";
import {Button, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

interface ModalProps {
  open: boolean,
  setOpen: (open: boolean) => void;
  title: string;
  saveText: string;
  saveHandler: () => void;
  cancelText: string;
  cancelHandler?: () => void;
}

const closeModal = (
  setOpen: (open: boolean) => void,
  beforeClose?: () => void
) => () => {
  if (beforeClose) {
    beforeClose();
  }
  setOpen(false);
};

const Modal: React.FC<ModalProps> = ({
  children,
  open,
  setOpen,
  title,
  saveText,
  saveHandler,
  cancelText,
  cancelHandler
}) => {
  const cancelAndClose = closeModal(setOpen, cancelHandler);
  const saveAndClose = closeModal(setOpen, saveHandler);
  return (
    <ReactStrap.Modal isOpen={open} toggle={cancelAndClose} centered={true} fade={false}>
      <ModalHeader toggle={cancelAndClose}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={saveAndClose} className="mx-2">
          {saveText}
        </Button>
        <Button color="secondary" onClick={cancelAndClose}>
          {cancelText}
        </Button>
      </ModalFooter>
    </ReactStrap.Modal>
  );
};

export { Modal };
