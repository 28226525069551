import * as React from "react";
import {Table} from "reactstrap";
import {TableHeading} from "./TableHeader";
import {RowComponentProps} from "./RowComponentProps";

interface TableListProps<T> {
  headings: Array<string>;
  records: Array<any>;
  entityName: string;
  RowComponent: React.ComponentType<RowComponentProps<T>>;
  updateModel?: (name: string, value: any) => void;
  onSelect?: (id: string) => void;
  onDeleteClick?: (id: string) => void;
  hover?: boolean;
}

const TableComponent = <T extends object>({
  headings,
  records,
  entityName,
  RowComponent,
  updateModel,
  onSelect,
  onDeleteClick,
  hover
}: TableListProps<T>) => (
  <Table hover={hover}>
    {headings.length > 0 &&
      <TableHeading headings={headings} />
    }
    <tbody>
      {records.length == 0 && <tr><td colSpan={headings.length}>There are no {entityName}s.</td></tr>}
      {records.map((record, index) => {
        return (
          <RowComponent
            key={record.id}
            index={index}
            item={record}
            entityName={entityName}
            updateModel={updateModel}
            onSelect={onSelect}
            onDeleteClick={onDeleteClick ? onDeleteClick : () => {}}
          />
        );
      })}
    </tbody>
  </Table>
);

export { TableComponent };
