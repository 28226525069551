import * as React from "react";
import { SimpleRowComponentProps } from "./SimpleRowComponentProps";
import { Container } from "reactstrap";

interface SimpleListListProps<T> {
  records: Array<any>;
  entityName: string;
  RowComponent: React.ComponentType<SimpleRowComponentProps<T>>;
  updateModel?: (name: string, value: any) => void;
  modelNamePrefix?: string;
}

const SimpleListComponent = <T extends object>({
  records,
  entityName,
  RowComponent,
  updateModel,
  modelNamePrefix
}: SimpleListListProps<T>) => (
  <Container>
    {records.length == 0 && <p>There are no {entityName}s.</p>}
    {records.map((record, index) => {
      return (
        <RowComponent
          key={record.id}
          index={index}
          item={record}
          entityName={entityName}
          updateModel={updateModel}
          modelNamePrefix={`${modelNamePrefix}${entityName}s[${index}].`}
        />
      );
    })}
  </Container>
);

export { SimpleListComponent };
