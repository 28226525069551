import * as React from "react";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle
} from "reactstrap";
import styled from "styled-components";
import { FullHeightDiv } from "../../components/widgets/FullHeightDiv";


interface PlansSectionProps {
  tryPayrollAction: () => void;
}


const PlansSection: React.FC<PlansSectionProps> = ({tryPayrollAction}) => {
  return (
    <PlansSectionStyle>
      <PlansContainer className="content container">
        <FullHeightDiv className="row">
          <div className="col-6 mx-auto my-auto">
            <CardStyle className="card card-block">
              <Card>
                <CardBody>
                  <CardTitle className="mb-2" tag="h5">Keisan Payroll</CardTitle>
                  <CardSubtitle tag="h6" className="mb-3 text-muted">
                    Unlimited employees
                  </CardSubtitle>
                  <CardText className="mb-2">
                    <h1 className="text-center">$5 / month</h1>
                    <ul>
                      <li>Payroll for <strong>unlimited</strong> employees</li>
                      <li>30 day free trial</li>
                      <li>Configurable pay calculation</li>
                      <li>Tax</li>
                      <li>Payslips</li>
                    </ul>
                  </CardText>
                  <Button block={true} onClick={tryPayrollAction}>Try it now!</Button>
                </CardBody>
              </Card>
            </CardStyle>
          </div>
        </FullHeightDiv>
      </PlansContainer>
    </PlansSectionStyle>
  );
};

const PlansContainer = styled(FullHeightDiv)`
  margin-top: 0;
`

const CardStyle = styled.div`
  border: 0;
`

const PlansSectionStyle = styled.section`
  width: 100%;
  height: 50vh;
  background-color: #e7e7ef;
`;

export { PlansSection };
