import * as React from "react";
import { useState } from "react";
import { SaveCancelModal } from "./SaveCancelModal";
import { ListComponent } from "../../../../components/widgets/list/ListComponent";
import { ListItem } from "../../../../common/ListItem";
import { CheckboxListRow } from "../../../../components/widgets/list/CheckboxListRow";
import { ListModel } from "../../../../common/ListModel";
import BusinessId from "../../../../types/BusinessId";

interface EmployeeEarningsModalProps {
  entityName: string;
  fetchItems: (businessId: BusinessId) => Promise<ListItem[]>;
  open: boolean;
  setModal: (open: boolean) => void;
  updateModel: (name: string, value: any) => void;
  employeeName: string;
  selected: string[];
}

const updateItemsInEmployee = (
  entityName: string,
  itemsModel: ListModel<ListItem>,
  updateModel: (name: string, value: any) => void
) => () => {
  const selected = itemsModel.items.filter(e => e.selected);
  updateModel(entityName, selected);
};

const PayItemModal: React.FC<EmployeeEarningsModalProps> = ({
  entityName,
  fetchItems,
  open,
  setModal,
  updateModel,
  employeeName,
  selected
}) => {
  const [items, setItems] = useState<ListModel<ListItem>>({ items: [] });
  return (
    <SaveCancelModal
      open={open}
      setModal={setModal}
      title={"Earnings for " + employeeName}
      onSave={updateItemsInEmployee(entityName, items, updateModel)}
    >
      <ListComponent
        entityName="item"
        fetchItems={fetchItems}
        selected={selected}
        model={items}
        setModel={setItems}
        RowComponent={CheckboxListRow}
      />
    </SaveCancelModal>
  );
};

export { PayItemModal };
