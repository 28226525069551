import * as React from "react";
import { useContext, useState } from "react";
import H2 from "./H2";
import { ButtonBar } from "./ButtonBar";
import { Button } from "reactstrap";
import { TableComponent } from "./table/TableComponent";
import Page from "./Page";
import { History } from "history";
import AccessToken from "../../types/AccessToken";
import BusinessId from "../../types/BusinessId";
import { AppContext, IAppContext } from "../../context/AppContext";
import { RecordBase } from "../../common/api/RecordBase";
import { ListLoader } from "./ListLoader";
import { RowComponentProps } from "./table/RowComponentProps";

interface CrudListPageProps<T> {
  headings: Array<string>;
  RowComponent: React.ComponentType<RowComponentProps<T>>;
  history: History;
  entityName: string;
  createRoute: string;
  deleteRecord: (
    token: AccessToken,
    businessId: BusinessId,
    earningId: string
  ) => void;
  fetchRecords: (businessId: string) => Promise<Array<T>>;
}

const onCreateClick = (history: History, createRoute: string) => () => {
  history.push(createRoute);
};

const onDeleteClick = <T extends RecordBase>(
  businessId: string,
  records: Array<T>,
  setRecords: React.Dispatch<React.SetStateAction<Array<T>>>,
  deleteRecord: (
    token: AccessToken,
    businessId: BusinessId,
    earningId: string
  ) => void
) => (id: string) => {
  deleteRecord("", businessId, id);
  const others = records.filter(it => it.id !== id);
  setRecords(others);
};

const capitalize = (input: string) => {
  return input.charAt(0).toUpperCase() + input.substring(1);
};

const CrudListPage = <T extends RecordBase>({
  history,
  headings,
  RowComponent,
  entityName,
  createRoute,
  deleteRecord,
  fetchRecords
}: CrudListPageProps<T>) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const [records, setRecords] = useState<Array<T>>([]);
  return (
    <Page>
      <H2>{capitalize(entityName)}s</H2>
      <ButtonBar>
        <Button color="primary" onClick={onCreateClick(history, createRoute)}>
          New {entityName}
        </Button>
      </ButtonBar>
      <ListLoader
        businessId={appState.currentBusinessId}
        fetchRecords={fetchRecords}
        setRecords={setRecords}
      >
        <TableComponent
          headings={headings}
          records={records}
          entityName={entityName}
          RowComponent={RowComponent}
          onDeleteClick={onDeleteClick(
            appState.currentBusinessId,
            records,
            setRecords,
            deleteRecord
          )}
        />
      </ListLoader>
    </Page>
  );
};

export { CrudListPage };
