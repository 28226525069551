import * as React from "react";
import {RowComponentProps} from "../../../../components/widgets/table/RowComponentProps";
import TableDeleteButton from "../../../../components/widgets/table/TableDeleteButton";
import {formatDate} from "../../../../common/Date";
import PayModel from "../../../payrun/service/PayModel";

const EmployeePastPayRow: React.FC<RowComponentProps<PayModel>> = ({
  item,
  onDeleteClick,
  entityName
}) => {
  return (
    <tr>
      <td>
        <a href={item.payslip} className="nav-link" target="_blank">Payslip</a>
      </td>
      <td>{formatDate(item.payrun.startDate)}</td>
      <td>{formatDate(item.payrun.endDate)}</td>
      <td>{formatDate(item.payrun.payDate)}</td>
      <td className="text-right">{item.totals.takeHomePay}</td>
      <td className="text-right">{item.totals.tax}</td>
      <td>
        <TableDeleteButton
          entityName={entityName}
          onDeleteClick={() => {
            onDeleteClick(item.id);
          }}
        />
      </td>
    </tr>
  );
};

export { EmployeePastPayRow };
