import React, {useContext, useState} from "react";
import BankAccount from "../../api/BankAccount";
import {History} from "history";
import PaymentBasis from "../../api/PaymentBasis";
import {PayFrequency} from "../../api/PayFrequency";
import ResidencyStatus from "../../api/ResidencyStatus";
import {updateModel, validateModel} from "../../../../common/Model";
import moment from "moment";
import {EmployeeModel, EmployeeModelErrors} from "../../service/employee/EmployeeModel";
import {EmployeeEarningModel} from "../../service/employee/EmployeeEarningModel";
import {EmployeeEntitlementModel} from "../../service/employee/EmployeeEntitlementModel";
import {EmployeeSuperModel} from "../../service/employee/EmployeeSuperModel";
import {EmployeeDeductionModel} from "../../service/employee/EmployeeDeductionModel";
import {creatEmployee, getStandardPay} from "../../service/employee/EmployeeService";
import {AppContext, IAppContext} from "../../../../context/AppContext";
import {computeEmployeeValues} from "../../service/employee/EmployeeCompute";
import MedicareLevyStatus from "../../api/MedicareLevyStatus";
import {getEmployeeValidators} from "../../service/validation/EmployeeValidationService";
import {EmployeeCreateWizard} from "./EmployeeCreateWizard";
import {Loader} from "../../../../components/widgets/Loader";

interface EmployeeCreatePageProps {
  history: History;
}

const onCancel = (history: History) => () => {
  history.goBack();
};

const onSave = (
  history: History,
  businessId: string,
  employee: EmployeeModel
) => async () => {
  await creatEmployee(businessId, employee);
  history.goBack();
};

const initialModel = {
  id: undefined,
  title: "Ms.",
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "Female",
  dateOfBirth: moment("2000-01-01"),
  emergencyContactName: "",
  emergencyContactRelationship: "",
  emergencyContactPhone: "",
  emergencyContactEmail: "",
  jobTitle: "",
  terminationDate: undefined,
  startDate: moment("2010-01-01"),
  lastPaymentDate: undefined,
  phone: "",
  mobile: "",
  email: "",
  streetAddress1: "",
  streetAddress2: "",
  suburb: "",
  postCode: "",
  state: "",
  country: "AU",
  tfn: "",
  paymentBasis: PaymentBasis.Fulltime,
  payFrequency: PayFrequency.WEEKLY,
  annualSalary: 0,
  standardHours: 40,
  hourlyRate: 0,
  residencyStatus: ResidencyStatus.AustralianResident,
  medicareLevy: MedicareLevyStatus.NO_EXCEMPTION,
  taxFreeThreshold: false,
  helpSslTslDebt: false,
  financialSupplementDebt: false,
  withholdingVariation: 0,
  otherTaxOffset: 0,
  declarationDate: undefined,
  earnings: [] as Array<EmployeeEarningModel>,
  deductions: [] as Array<EmployeeDeductionModel>,
  super: [] as Array<EmployeeSuperModel>,
  entitlements: [] as Array<EmployeeEntitlementModel>,
  bankAccounts: [
    { statementDescription: "", accountName: "", bsb: "", accountNumber: "" }
  ] as Array<BankAccount>,
  errors: {} as EmployeeModelErrors,
  isValid: false,
  showErrors: false
} as EmployeeModel;

const EmployeeCreatePage: React.FC<EmployeeCreatePageProps> = ({ history }) => {
  const {appState} = useContext<IAppContext>(AppContext);
  const [employee, setEmployee] = useState<EmployeeModel>(initialModel);
  const onUpdate = updateModel(
    employee,
    setEmployee,
    getEmployeeValidators(),
    computeEmployeeValues
  );

  const fetchData = async () => {
    const standardPay = await getStandardPay(appState.currentBusinessId);
    setEmployee(
      validateModel({
        ...initialModel,
        earnings: standardPay.earnings,
        deductions: standardPay.deductions,
        entitlements: standardPay.entitlements,
        super: standardPay.super
      }, getEmployeeValidators())
    );
  };

  return (
    <Loader fetchData={fetchData}>
      <EmployeeCreateWizard
        employee={employee}
        onSave={onSave(history, appState.currentBusinessId, employee)}
        onCancel={onCancel(history)}
        onUpdate={onUpdate}
      />
    </Loader>
  );
};

export { EmployeeCreatePage };
