import * as React from "react";
import { useState } from "react";

import { Col, Form, FormGroup, Row } from "reactstrap";
import { EmployeeModel } from "../../service/employee/EmployeeModel";
import { TableComponent } from "../../../../components/widgets/table/TableComponent";
import { EmployeeEarningRow } from "./EmployeeEarningRow";
import { EmployeeDeductionRow } from "./EmployeeDeductionRow";
import { EmployeeEntitlementRow } from "./EmployeeEntitlementRow";
import { EmployeeSuperRow } from "./EmployeeSuperRow";
import { H6 } from "../../../../components/widgets/H6";
import { AddButton } from "../../../../components/widgets/AddButton";
import { PayItemModal } from "../modal/PayItemModal";
import {
  getDeductions,
  getEarnings,
  getEntitlements,
  getSuper
} from "../../service/employee/EmployeeService";
import LabelInline from "../../../../components/widgets/LabelInline";
import PayFrequencyInput from "../../../../components/widgets/input/PayFrequencyInput";
import MoneyInput from "../../../../components/widgets/input/MoneyInput";

interface EmployeeStandardPayFormProps {
  employee: EmployeeModel;
  updateModel: (name: string, value: any) => void;
}

const onEarningDelete = (id: string) => {};

const onDeductionDelete = (id: string) => {};

const onEntitlementDelete = (id: string) => {};

const onSuperDelete = (id: string) => {};

const EmployeeStandardPayForm: React.FC<EmployeeStandardPayFormProps> = ({
  employee,
  updateModel
}) => {
  const [earningsModal, setEarningsModal] = useState(false);
  const [deductionsModal, setDeductionsModal] = useState(false);
  const [entitlementsModal, setEntitlementsModal] = useState(false);
  const [superModal, setSuperModal] = useState(false);
  return (
    <Row>
      <Col>
        <H6>Base pay</H6>
        <Form inline>
          <FormGroup className="mb-2 mr-sm-4 mb-sm-0">
            <LabelInline htmlFor="frequency">Frequency:</LabelInline>
            <PayFrequencyInput
              selected={employee.payFrequency}
              update={updateModel}
            />
          </FormGroup>

          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <LabelInline htmlFor="startDate">Annual salary:</LabelInline>
            <MoneyInput
              name="annualSalary"
              value={employee.annualSalary}
              update={updateModel}
            />
          </FormGroup>

          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <LabelInline htmlFor="endDate">Standard hours:</LabelInline>
            <MoneyInput
              name="standardHours"
              value={employee.standardHours}
              update={updateModel}
            />
          </FormGroup>

          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <LabelInline htmlFor="endDate">Hourly rate:</LabelInline>
            <MoneyInput
              name="hourlyRate"
              value={employee.hourlyRate}
              update={updateModel}
            />
          </FormGroup>
        </Form>

        <H6>Earnings</H6>
        <TableComponent
          headings={[]}
          records={employee.earnings}
          entityName={"earning"}
          RowComponent={EmployeeEarningRow}
          onDeleteClick={onEarningDelete}
        />
        <AddButton
          onClick={() => {
            setEarningsModal(true);
          }}
        />
        <PayItemModal
          entityName="earnings"
          fetchItems={getEarnings}
          open={earningsModal}
          setModal={setEarningsModal}
          updateModel={updateModel}
          selected={employee.earnings.map(it => it.id)}
          employeeName={employee.firstName}
        />

        <H6>Deductions</H6>
        <TableComponent
          headings={[]}
          records={employee.deductions}
          entityName={"deduction"}
          RowComponent={EmployeeDeductionRow}
          onDeleteClick={onDeductionDelete}
        />
        <AddButton
          onClick={() => {
            setDeductionsModal(true);
          }}
        />
        <PayItemModal
          entityName="deductions"
          fetchItems={getDeductions}
          open={deductionsModal}
          setModal={setDeductionsModal}
          updateModel={updateModel}
          selected={employee.deductions.map(it => it.id)}
          employeeName={employee.firstName}
        />

        <H6>Entitlements</H6>
        <TableComponent
          headings={[]}
          records={employee.entitlements}
          entityName={"entitlement"}
          RowComponent={EmployeeEntitlementRow}
          onDeleteClick={onEntitlementDelete}
        />
        <AddButton
          onClick={() => {
            setEntitlementsModal(true);
          }}
        />
        <PayItemModal
          entityName="entitlements"
          fetchItems={getEntitlements}
          open={entitlementsModal}
          setModal={setEntitlementsModal}
          updateModel={updateModel}
          selected={employee.entitlements.map(it => it.id)}
          employeeName={employee.firstName}
        />

        <H6>Super</H6>
        <TableComponent
          headings={[]}
          records={employee.super}
          entityName={"super"}
          RowComponent={EmployeeSuperRow}
          onDeleteClick={onSuperDelete}
        />
        <AddButton
          onClick={() => {
            setSuperModal(true);
          }}
        />
        <PayItemModal
          entityName="super"
          fetchItems={getSuper}
          open={superModal}
          setModal={setSuperModal}
          updateModel={updateModel}
          selected={employee.super.map(it => it.id)}
          employeeName={employee.firstName}
        />
      </Col>
    </Row>
  );
};

export { EmployeeStandardPayForm };
