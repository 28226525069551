import * as React from "react";
import Employee from "../../api/Employee";
import {Table} from 'reactstrap';
import {EmployeeRow} from './EmployeeRow';

export interface EmployeeListProps {
  employees: Array<Employee>
}

const EmployeeListTable: React.FC<EmployeeListProps> = ({employees}) => {
  return (
    <Table>
      <thead>
        <tr>
      <th>Name</th>
      <th>Frequency</th>
      <th>Last pay</th>
      <th></th>
        </tr>
      </thead>
      <tbody>
      {employees.map((employee) => {
        return <EmployeeRow key={employee.id} employee={employee} />;
      })}
      </tbody>
    </Table>
  );
};

export default EmployeeListTable;

