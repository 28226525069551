import { History } from "history";
import { ROUTES } from "../../../../AppRouter";
import * as React from "react";
import { useContext } from "react";
import { AppContext, IAppContext } from "../../../../context/AppContext";
import { fetchEarning, saveEarning } from "../../api/earning/EarningApi";
import {
  EarningModel,
  EarningModelErrors
} from "../../../employee/service/earning/EarningModel";
import { EarningView } from "./EarningView";
import { fieldValidation } from "../../../../common/Model";
import { required } from "../../../../common/validation/RequiredValidator";
import EarningType from "../../api/EarningType";
import EarningCalculationMethod from "../../api/EarningCalculationMethod";
import StpType from "../../api/StpType";
import { CrudForm } from "../../../../components/widgets/CrudForm";

interface EarningPageProps {
  match: any;
  history: History;
}

const validators = [
  fieldValidation("name", [required]),
  fieldValidation("displayName", [required])
];

const EarningPage: React.FC<EarningPageProps> = ({ history, match }) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const initialModel = {
    name: "",
    displayName: "",
    type: EarningType.NORMAL,
    calculationMethod: EarningCalculationMethod.FIXED_AMOUNT,
    stpType: StpType.GROSS_PAYMENTS,
    paySuper: true,
    reducesBase: false,
    accrueLeave: true,
    amount: 0.0,
    employees: [],
    isValid: false,
    errors: {} as EarningModelErrors
  } as EarningModel;

  return (
    <CrudForm
      id={match.params.id}
      businessId={appState.currentBusinessId}
      validators={validators}
      initialModel={initialModel}
      history={history}
      entityName="earning"
      FormViewComponent={EarningView}
      saveModel={saveEarning}
      fetchModel={fetchEarning}
      listRoute={ROUTES.EARNING_LIST}
    />
  );
};

export { EarningPage };
