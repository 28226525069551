import * as React from "react";
import Select from "./Select";
import KeyValuePair from "./KeyValuePair";
import PaymentBasis from "../../../payroll/employee/api/PaymentBasis";
import TextInput from "./TextInput";
import { Moment } from "moment";
import MedicareLevyStatus from '../../../payroll/employee/api/MedicareLevyStatus';

interface MedicareLevyInputProps {
  selected: MedicareLevyStatus;
  update: (name: string, value: MedicareLevyStatus) => void;
}

const medicareLevyStatuses: Array<KeyValuePair> = new Array<KeyValuePair>(
  new KeyValuePair("NO_EXCEMPTION", "No exception"),
  new KeyValuePair("HALF_EXCEMPTION", "Half excemption"),
  new KeyValuePair("FULL_EXCEMPTION", "Full excemption")
);

const MedicareLevyInput: React.SFC<MedicareLevyInputProps> = ({
  selected,
  update
}) => {
  return (
    <Select
      name="medicareLevy"
      collection={medicareLevyStatuses}
      selected={selected.toString()}
      update={update}
    />
  );
};

export { MedicareLevyInput };
