import BusinessId from "../../../../types/BusinessId";
import { fetchSingle } from "../../../../common/api/api";
import { getBaseUrl } from "../../../../business/api/BusinessApi";
import { StandardPay } from "./StandardPay";
import { idToken } from "../../../../auth/service/AuthService";

const fetchStandardPay = async (businessId: BusinessId): Promise<StandardPay> =>
  fetchSingle<StandardPay>(
    await idToken(),
    `${getBaseUrl(businessId)}/pay-items/standard`
  );

export { fetchStandardPay };
