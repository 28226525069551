import * as React from "react";
import { useContext, useState } from "react";
import { Button, Form, FormGroup } from "reactstrap";
import Page from "../../../../components/widgets/Page";
import H2 from "../../../../components/widgets/H2";
import PayFrequencyInput from "../../../../components/widgets/input/PayFrequencyInput";
import { PayFrequency } from "../../../employee/api/PayFrequency";
import { updateModel } from "../../../../common/Model";
import * as EmployeeApi from "../../../employee/api/EmployeeApi";
import { AppContext, IAppContext } from "../../../../context/AppContext";
import { FilterBar } from "../../../../components/widgets/FilterBar";
import LabelInline from "../../../../components/widgets/LabelInline";
import moment from "moment";
import { History } from "history";
import Employee from "../../../employee/api/Employee";
import { ListRecord } from "../../../../common/api/ListRecord";
import DateInput from "../../../../components/widgets/input/DateInput";
import { TableComponent } from "../../../../components/widgets/table/TableComponent";
import { DashboardEmployeeRow } from "./DashboardEmployeeRow";
import { Loader } from "../../../../components/widgets/Loader";
import { startPayrun } from "../../service/PayrunService";
import CreatePayrunForm from "./CreatePayrunForm";

export interface CreatePayrunPageProps {
  frequency: PayFrequency;
  history: History;
}

const defaultSelected = (collection: Array<ListRecord>) => {
  return collection.map(it => {
    it.selected = true;
    return it;
  });
};

const CreatePayrunPage: React.FC<CreatePayrunPageProps> = ({
  frequency,
  history
}) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const [dashboardForm, setDashboardForm] = useState<CreatePayrunForm>({
    payFrequency: frequency || PayFrequency.MONTHLY,
    startDate: moment(),
    endDate: moment(),
    employees: [] as Array<Employee>,
    errors: {}
  } as CreatePayrunForm);
  const onUpdate = updateModel<CreatePayrunForm>(
    dashboardForm,
    setDashboardForm,
    []
  );
  const fetchData = () => {
    EmployeeApi.fetchEmployees(appState.currentBusinessId).then(employees =>
      onUpdate("employees", defaultSelected(employees))
    );
  };

  return (
    <Page>
      <H2>Payrun</H2>

      <FilterBar>
        <Form inline>
          <FormGroup className="mb-2 mr-sm-4 mb-sm-0">
            <LabelInline htmlFor="frequency">Frequency:</LabelInline>
            <PayFrequencyInput
              selected={dashboardForm.payFrequency}
              update={onUpdate}
            />
          </FormGroup>

          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <LabelInline htmlFor="startDate">From:</LabelInline>
            <DateInput
              name="startDate"
              value={dashboardForm.startDate}
              update={onUpdate}
            />
          </FormGroup>

          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <LabelInline htmlFor="endDate">To:</LabelInline>
            <DateInput
              name="endDate"
              value={dashboardForm.endDate}
              update={onUpdate}
            />
          </FormGroup>

          <Button
            color="primary"
            onClick={startPayrun(appState.currentBusinessId, dashboardForm, history)}
          >
            Start
          </Button>
        </Form>
      </FilterBar>

      <Loader fetchData={fetchData}>
        <TableComponent
          headings={["", "Name", "Frequency", "Last pay"]}
          records={dashboardForm.employees}
          entityName={"employee"}
          updateModel={onUpdate}
          RowComponent={DashboardEmployeeRow}
        />
      </Loader>
    </Page>
  );
};

export { CreatePayrunPage };
