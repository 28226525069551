// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from "firebase/app";

// Add the Firebase services that you want to use
import "firebase/auth";
import AccessToken from "../../types/AccessToken";
import {createBusiness, fetchBusiness} from "../../business/api/BusinessApi";
import {ROUTES} from "../../AppRouter";
import {getAuthTenantId} from "../../config/ConfigService";

// TODO: environment configs
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
var firebaseConfig = {
  apiKey: "AIzaSyAZ2OVo5TBmh7wHZ6Fh4F5MiW-rkQ_-2yU",
  authDomain: "accounting-295605.firebaseapp.com"
};

const initialiseAuth = () => {
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.auth().tenantId = getAuthTenantId();
};

const signUp = async (email: string, password: string): Promise<string> => {
  await firebase.auth().createUserWithEmailAndPassword(email, password);
  const businessName = `${email.split("@")[0]}'s business`;
  const response = await createBusiness(businessName);
  return response.id;
};

const login = (
  updateAppState: (name: string, value: any) => void
) => async (email: string, password: string): Promise<string | null> => {
  const user = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);
  const businesses = await fetchBusiness();
  if (businesses.length === 0) {
    return null;
  }
  updateAppState('currentBusinessId', businesses[0].id);
  return businesses[0].id;
};

const logout = async (): Promise<void> => {
  return await firebase.auth().signOut();
};

const idToken = async (): Promise<AccessToken> => {
  const user = await firebase.auth().currentUser;
  if (user) {
    return user.getIdToken();
  }
  window.location.href = ROUTES.LOGIN;
  return "";
};

export { initialiseAuth, signUp, login, logout, idToken };
