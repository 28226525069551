import * as React from 'react';


interface LabelInlineProps {
  htmlFor: string;
}

const LabelInline: React.SFC<LabelInlineProps> = (props) => {
  return <label className="mr-1" {...props}>{props.children}</label>
};

export default LabelInline;
