import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { History } from "history";
import Page from "../../../../components/widgets/Page";
import H2 from "../../../../components/widgets/H2";
import { Button, Col, Row } from "reactstrap";
import { TableComponent } from "../../../../components/widgets/table/TableComponent";
import { updateModel } from "../../../../common/Model";
import { AppContext, IAppContext } from "../../../../context/AppContext";
import PayrunForm from "./model/PayrunForm";
import DraftPay from "../../api/DraftPay";
import { PayrunEmployeeRow } from "./employeelist/PayrunEmployeeRow";
import _ from "lodash";
import { PayFrequency } from "../../../employee/api/PayFrequency";
import moment from "moment";
import { formatDate } from "../../../../common/Date";
import H4 from "../../../../components/widgets/H4";
import { ButtonBar } from "../../../../components/widgets/ButtonBar";
import { ROUTES } from "../../../../AppRouter";
import { PayView } from "./pay/PayView";
import { findPayrun } from "../../service/PayrunService";
import { savePay } from "../../service/PayService";
import { Loader } from "../../../../components/widgets/Loader";
import { getEditRoute } from "../../../../common/Routing";

export interface PayrunPageProps {
  history: History;
  match: any;
}

const goBack = (history: History) => () => {
  history.push(ROUTES.DASHBOARD);
};

const save = (
  history: History,
  businessId: string,
  payrun: PayrunForm
) => async () => {
  const employeeIndex = getCurrentEmployee(payrun);
  savePay(businessId, payrun.id!, payrun.employees[employeeIndex]);
  history.push(getEditRoute(ROUTES.PAYRUN_SUMMARY, payrun.id!!));
};

const getCurrentEmployee = (payrun: PayrunForm): number => {
  return payrun.employees.findIndex(
    p => p.employeeId === payrun.selectedEmployee
  );
};

const selectEmployee = (
  updateModel: (name: string, value: any) => Partial<PayrunForm>
) => (employeeId: string): void => {
  console.log(`Selected employee: ${employeeId}`);
  updateModel("selectedEmployee", employeeId);
};

const PayrunPage: React.FC<PayrunPageProps> = ({ history, match }) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const [payrun, setPayrun] = useState<PayrunForm>({
    frequency: PayFrequency.MONTHLY,
    startDate: moment(),
    endDate: moment(),
    payDate: moment(),
    employees: [] as Array<DraftPay>,
    errors: {},
    changed: false
  } as PayrunForm);
  useEffect(() => {
    let interval;
    if (payrun) {
      interval = setInterval(() => {
        if (payrun.changed) {
          console.log("autosaving...");
          onUpdate("changed", false);
          const employeeIndex = getCurrentEmployee(payrun);
          savePay(
            appState.currentBusinessId,
            payrun.id!,
            payrun.employees[employeeIndex]
          );
        }
      }, 5000);
    } else {
      clearInterval(interval);
    }
  }, [payrun]);

  const onUpdate = updateModel(payrun, setPayrun, []);
  const fetchData = async () => {
    const payrun = await findPayrun(appState.currentBusinessId, match.params.id);
    setPayrun(payrun);
  };

  const employeeIndex = getCurrentEmployee(payrun);
  return (
    <Page>
      <H2>{_.capitalize(payrun.frequency.toString())} payrun</H2>
      <H4>
        Pay peiod {formatDate(payrun.startDate)} to {formatDate(payrun.endDate)}{" "}
        - Pay on {formatDate(payrun.payDate)}
      </H4>

      <Row marginHeight={20}>
        <Col sm={4}>
          <Loader fetchData={fetchData}>
            <TableComponent
              headings={["Name"]}
              records={payrun.employees}
              entityName={"employee"}
              RowComponent={PayrunEmployeeRow}
              hover={true}
              onSelect={selectEmployee(onUpdate)}
            />
          </Loader>
        </Col>
        <Col sm={8}>
          <PayView
            updateModel={onUpdate}
            currentPay={payrun.employees[employeeIndex]}
            employeeIndex={employeeIndex}
          />
        </Col>
      </Row>

      <ButtonBar>
        <Button
          color="primary"
          className="mx-2"
          onClick={save(history, appState.currentBusinessId, payrun)}
        >
          Save
        </Button>
        <Button color="secondary" onClick={goBack(history)}>
          Cancel
        </Button>
      </ButtonBar>
    </Page>
  );
};

export { PayrunPage };
