import * as React from "react";
import TextInput from "../../../../components/widgets/input/TextInput";
import { RowComponentProps } from "../../../../components/widgets/table/RowComponentProps";
import BankAccount from "../../api/BankAccount";
import TableDeleteButton from "../../../../components/widgets/table/TableDeleteButton";

const EmployeeBankAccountRow: React.FC<RowComponentProps<BankAccount>> = ({
  item,
  onDeleteClick,
  entityName,
  updateModel
}) => {
  return (
    <tr>
      <td>
        <TextInput
          name="statementDescription"
          value={item.statementDescription}
          update={updateModel}
        />
      </td>
      <td>
        <TextInput
          name="accountName"
          value={item.accountName}
          update={updateModel}
        />
      </td>
      <td>
        <TextInput name="bsb" value={item.bsb} update={updateModel} />
      </td>
      <td>
        <TextInput
          name="accountNumber"
          value={item.accountNumber}
          update={updateModel}
        />
      </td>
      <td>
        <TableDeleteButton
          entityName={entityName}
          onDeleteClick={() => {
            onDeleteClick(item.id);
          }}
        />
      </td>
    </tr>
  );
};

export { EmployeeBankAccountRow };
