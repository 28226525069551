import { History } from "history";
import * as React from "react";
import { useEffect } from "react";
import { SignUpViewProps } from "../signup/SignUpView";
import { logout } from "../../service/AuthService";
import {ROUTES} from "../../../AppRouter";

export interface LogoutPageProps {
  goBack: () => void;
  history: History;
  SignUpView: React.ComponentType<SignUpViewProps>;
}

const doLogout = (history: History) => async () => {
  await logout();
  history.push(ROUTES.ROOT);
};

const LogoutPage: React.FC<LogoutPageProps> = ({ history }) => {
  useEffect(() => {
    doLogout(history)();
  }, []);

  return null;
};

export { LogoutPage };
