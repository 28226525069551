import * as React from 'react';
import Select from "./Select";
import KeyValuePair from "./KeyValuePair";
import PaymentBasis from "../../../payroll/employee/api/PaymentBasis";
import TextInput from './TextInput';
import {Moment} from 'moment';

interface PaymentBasisInputProps {
  selected: PaymentBasis,
  update: (name: string, value: PaymentBasis) => void;
}

const paymentBasises: Array<KeyValuePair> = new Array<KeyValuePair>(
  new KeyValuePair("Fulltime", "Fulltime"),
  new KeyValuePair("PartTime", "PartTime"),
  new KeyValuePair("LabourHire", "LabourHire"),
  new KeyValuePair("SuperannuationOrAnnuity", "SuperannuationOrAnnuity"),
  new KeyValuePair("Casual", "Casual")
);


const PaymentBasisInput: React.SFC<PaymentBasisInputProps> = ({selected, update}) => {
  return <Select name="paymentBasis" collection={paymentBasises} selected={selected.toString()} update={update} />
};

export default PaymentBasisInput;