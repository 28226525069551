enum PaymentBasis {
  Fulltime,
  PartTime,
  LabourHire,
  SuperannuationOrAnnuity,
  Casual
};


export default PaymentBasis;
