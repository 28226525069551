import * as React from "react";

import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import GenderInput from "../../../../components/widgets/input/GenderInput";
import TitleInput from "../../../../components/widgets/input/TitleInput";
import DateInput from "../../../../components/widgets/input/DateInput";
import CountryInput from "../../../../components/widgets/input/CountryInput";
import TextInput from "../../../../components/widgets/input/TextInput";
import { EmployeeModel } from "../../service/employee/EmployeeModel";

interface EmployeePersonalDetailsFormProps {
  employee: EmployeeModel;
  updateModel: (name: string, value: any) => void;
}

const EmployeePersonalDetailsForm: React.SFC<
  EmployeePersonalDetailsFormProps
> = ({ employee, updateModel}) => {
  return (
    <Row>
      <Col>
        <Form>
          <FormGroup row>
            <Label for="title" sm={4}>
              Title
            </Label>
            <Col sm={8}>
              <TitleInput selected={employee.title} update={updateModel} />
            </Col>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col sm={12}>
                <Row>
                  <Col sm={8}>
                    <div className={"invalid-feedback"}>Requires field</div>
                  </Col>
                </Row>
                <Row>
                  <Label for="firstName" sm={4}>
                    Given names
                  </Label>
                  <Col sm={4}>
                    <TextInput
                      name="firstName"
                      value={employee.firstName}
                      update={updateModel}
                      error={employee.errors.firstName}
                      showErrors={employee.showErrors}
                    />
                  </Col>
                  <Col sm={4}>
                    <TextInput
                      name="middleName"
                      value={employee.middleName}
                      update={updateModel}
                      error={employee.errors.middleName}
                      showErrors={employee.showErrors}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup row>
            <Label for="lastName" sm={4}>
              Surname
            </Label>
            <Col sm={8}>
              <TextInput
                name="lastName"
                value={employee.lastName}
                update={updateModel}
                error={employee.errors.lastName}
                showErrors={employee.showErrors}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="gender" sm={4}>
              Gender
            </Label>
            <Col sm={8}>
              <GenderInput selected={employee.gender} update={updateModel} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="dateOfBirth" sm={4}>
              Date of birth
            </Label>
            <Col sm={8}>
              <DateInput
                name="dateOfBirth"
                value={employee.dateOfBirth}
                update={updateModel}
                error={employee.errors.dateOfBirth}
                showErrors={employee.showErrors}
              />
            </Col>
          </FormGroup>
          <hr />
          <FormGroup row>
            <Label for="email" sm={4}>
              Email
            </Label>
            <Col sm={8}>
              <TextInput
                name="email"
                value={employee.email}
                update={updateModel}
                error={employee.errors.email}
                showErrors={employee.showErrors}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="phone" sm={4}>
              Phone
            </Label>
            <Col sm={8}>
              <TextInput
                name="phone"
                value={employee.phone}
                update={updateModel}
                error={employee.errors.phone}
                showErrors={employee.showErrors}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="mobile" sm={4}>
              Mobile
            </Label>
            <Col sm={8}>
              <TextInput
                name="mobile"
                value={employee.mobile}
                update={updateModel}
                error={employee.errors.mobile}
                showErrors={employee.showErrors}
              />
            </Col>
          </FormGroup>
        </Form>
      </Col>
      <Col>
        <Form>
          <FormGroup row>
            <Label for="streetAddress1" sm={4}>
              Street address
            </Label>
            <Col sm={8}>
              <TextInput
                name="streetAddress1"
                value={employee.streetAddress1}
                update={updateModel}
                error={employee.errors.streetAddress1}
                showErrors={employee.showErrors}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="streetAddress2" sm={4} />
            <Col sm={8}>
              <TextInput
                name="streetAddress2"
                value={employee.streetAddress2}
                update={updateModel}
                error={employee.errors.streetAddress2}
                showErrors={employee.showErrors}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="suburb" sm={4}>
              Suburb
            </Label>
            <Col sm={8}>
              <TextInput
                name="suburb"
                value={employee.suburb}
                update={updateModel}
                error={employee.errors.suburb}
                showErrors={employee.showErrors}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="state" sm={4}>
              State
            </Label>
            <Col sm={8}>
              <TextInput
                name="state"
                value={employee.state}
                update={updateModel}
                error={employee.errors.state}
                showErrors={employee.showErrors}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="postCode" sm={4}>
              Post code
            </Label>
            <Col sm={8}>
              <TextInput
                name="postCode"
                value={employee.postCode}
                update={updateModel}
                error={employee.errors.postCode}
                showErrors={employee.showErrors}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="country" sm={4}>
              Country
            </Label>
            <Col sm={8}>
              <CountryInput selected={employee.country} update={updateModel} />
            </Col>
          </FormGroup>
        </Form>
      </Col>
    </Row>
  );
};

export { EmployeePersonalDetailsForm };
