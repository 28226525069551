import * as React from "react";
import { RowComponentProps } from "../table/RowComponentProps";
import { ListItem } from "../../../common/ListItem";
import {FormGroup, Label} from "reactstrap";
import { ListCheckboxInput } from "../input/ListCheckboxInput";

const CheckboxListRow: React.FC<RowComponentProps<ListItem>> = ({
  item,
  index,
  entityName,
  updateModel
}) => {
  return (
    <FormGroup check>
      <Label check>
        <ListCheckboxInput
          collectionName={entityName ? `${entityName}s` : undefined}
          name="selected"
          index={index}
          checked={item.selected}
          update={updateModel}
        />{' '}
        {item.name}
      </Label>
    </FormGroup>
  );
};

export { CheckboxListRow };
