enum EarningType {
  BASE = 'BASE',
  NORMAL = 'NORMAL',
  LEAVE = 'LEAVE',
  ALLOWANCE = 'ALLOWANCE',
  COMMISSION = 'COMMISSION',
  BONUS = "BONUS",
  ETP = 'EPT'
}

export default EarningType;
