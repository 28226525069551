import * as React from "react";
import styled from "styled-components";

interface AddButtonProps {
  onClick: () => void;
}

const AddButton: React.SFC<AddButtonProps> = ({ onClick }) => (
  <>
    <AddButtonIcon className="material-icons" onClick={onClick}>
      add
    </AddButtonIcon>
    <button type="button" className="btn btn-link" onClick={onClick}>Add</button>
  </>
);

const AddButtonIcon = styled.i`
  cursor: pointer;
`;

export { AddButton };
