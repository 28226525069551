import * as React from "react";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardText,
  CardTitle
} from "reactstrap";
import styled from "styled-components";

const FooterSection: React.FC = () => {
  return (
    <FooterSectionStyle className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-3">© 2021 Keisan</div>
          <div className="col-sm-3"></div>
        </div>
      </div>
    </FooterSectionStyle>
  );
};

const FooterSectionStyle = styled.footer`
  background-color: #31364B;
  color: white;
  font-size: 14px;  
`;

export { FooterSection };
