import * as React from "react";
import {useContext, useState} from "react";
import {History} from "history";
import Page from "../widgets/Page";
import H2 from "../widgets/H2";
import {TableComponent} from "../widgets/table/TableComponent";
import {AppContext, IAppContext} from "../../context/AppContext";
import {ATOReportingRow} from "./ATOReportingRow";
import {FilterBar} from "../widgets/FilterBar";
import {Form, FormGroup} from "reactstrap";
import LabelInline from "../widgets/LabelInline";
import {findCompletedPayruns} from "../../payroll/payrun/service/PayrunService";
import AtoReportingForm from "./AtoReportingForm";
import {FinancialYearInput} from "../widgets/input/FinancialYearInput";
import {updateModel} from "../../common/Model";
import BusinessId from "../../types/BusinessId";
import {Loader} from "../widgets/Loader";

interface AtoReportingPageProps {
  history: History;
}

const fetchPayrunsForFinancialYear = (businessId: BusinessId,
                                      form: AtoReportingForm,
                                      onUpdate: (name: string, value: any) => AtoReportingForm) =>
    async () => {
  const payruns = await findCompletedPayruns(businessId, parseInt(form.financialYear, 10));
  onUpdate('payruns', payruns);
};

const AtoReportingPage: React.FC<AtoReportingPageProps> = ({ history }) => {
  const {appState} = useContext<IAppContext>(AppContext);
  const [atoReportingForm, setAtoReportingForm] = useState<AtoReportingForm>({
    financialYear: "2021",
    payruns: []
  } as AtoReportingForm);
  const onUpdate = updateModel<AtoReportingForm>(atoReportingForm, setAtoReportingForm, []);
  return (
    <Page>
      <H2>ATO Reporting</H2>

      <FilterBar>
        <Form inline>
          <FormGroup className="mb-2 mr-sm-4 mb-sm-0">
            <LabelInline htmlFor="frequency">Financial year:</LabelInline>
            <FinancialYearInput
              selected={atoReportingForm.financialYear}
              update={onUpdate}
            />
          </FormGroup>
        </Form>
      </FilterBar>

      <Loader fetchData={fetchPayrunsForFinancialYear(appState.currentBusinessId, atoReportingForm, onUpdate)}>
        <TableComponent
          headings={['Frequency', 'Start date', 'End date', 'Pay date', 'Status', 'Lodge']}
          records={atoReportingForm.payruns}
          entityName="payrun"
          RowComponent={ATOReportingRow}
        />
      </Loader>
    </Page>
  );
};

export { AtoReportingPage };
