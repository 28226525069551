import * as React from "react";
import Page from "../../../../components/widgets/Page";
import H2 from "../../../../components/widgets/H2";
import { EmployeeModel } from "../../service/employee/EmployeeModel";
import { EmployeeTabs } from "./EmployeeTabs";

export interface EmployeeEditPageViewProps {
  employee: EmployeeModel;
  onSave: () => void;
  onCancel: () => void;
  updateModel: (name: string, value: any) => void;
}

const EmployeeEditPageView: React.SFC<EmployeeEditPageViewProps> = ({
  employee,
  onSave,
  onCancel,
  updateModel,
}) => {
  return (
    <Page>
      <H2>
        {employee.firstName} {employee.lastName}
      </H2>
      <EmployeeTabs
        employee={employee}
        onSave={onSave}
        onCancel={onCancel}
        updateModel={updateModel}
      />
    </Page>
  );
};

export { EmployeeEditPageView };
