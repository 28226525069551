import * as React from 'react'
import {RowComponentProps} from '../../../../components/widgets/table/RowComponentProps';
import TableDeleteButton from '../../../../components/widgets/table/TableDeleteButton';
import EmployeeEarning from '../../api/EmployeeEarning';

const EmployeeEarningRow: React.FC<RowComponentProps<EmployeeEarning>> = ({item,
                                                                          onDeleteClick,
                                                                          entityName}) => {
  return <tr>
    <td>{item.displayName}</td>
    <td>{item.type}</td>
    <td>{item.stpType}</td>
    <td>{item.amount}</td>
    <td>
      <TableDeleteButton
        entityName={entityName}
        onDeleteClick={() => {
          onDeleteClick(item.id);
        }}
      />
    </td>
  </tr>;
};

export {EmployeeEarningRow};
