import * as React from "react";
import PayFormEntitlement from "../model/PayFormEntitlement";
import MoneyInput from "../../../../../components/widgets/input/MoneyInput";
import { Col, Row } from "reactstrap";
import { SimpleRowComponentProps } from "../../../../../components/widgets/list/SimpleRowComponentProps";

const PayEntitlementRow: React.FC<
  SimpleRowComponentProps<PayFormEntitlement>
> = ({ item, entityName, index, updateModel, modelNamePrefix }) => {
  return (
    <Row className="mb-2">
      <Col sm="6">{item.displayName}</Col>
      <Col sm="2"></Col>
      <Col sm="2">
        <MoneyInput name={`${modelNamePrefix}amount`} value={item.amount} update={updateModel} />
      </Col>
      <Col sm="2" className="text-right">{item.total.toFixed(2)}</Col>
    </Row>
  );
};

export { PayEntitlementRow };
