import * as React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Menu from "./components/menu/Menu";
import EmployeeListPage from "./payroll/employee/components/list/EmployeeListPage";
import ReportListPage from "./components/reports/ReportListPage";
import { EmployeeCreatePage } from "./payroll/employee/components/create/EmployeeCreatePage";
import { EmployeeEditPage } from "./payroll/employee/components/edit/EmployeeEditPage";
import { Switch } from "react-router";
import EarningListPage from "./payroll/payitem/components/earning/EarningListPage";
import { EarningPage } from "./payroll/payitem/components/earning/EarningPage";
import { DeductionPage } from "./payroll/payitem/components/deduction/DeductionPage";
import DeductionListPage from "./payroll/payitem/components/deduction/DeductionListPage";
import EntitlementListPage from "./payroll/payitem/components/entitlement/EntitlementListPage";
import { EntitlementPage } from "./payroll/payitem/components/entitlement/EntitlementPage";
import { SuperPage } from "./payroll/payitem/components/super/SuperPage";
import SuperListPage from "./payroll/payitem/components/super/SuperListPage";
import { PayrunPage } from "./payroll/payrun/components/payrun/PayrunPage";
import { DashboardPage } from "./payroll/payrun/components/dashboard/DashboardPage";
import { ExistingPayrunPage } from "./payroll/payrun/components/dashboard/ExistingPayrunPage";
import { PayrunSummaryPage } from "./payroll/payrun/components/payrun/summary/PayrunSummaryPage";
import { PayrunCompletePage } from "./payroll/payrun/components/payrun/complete/PayrunCompletePage";
import { AtoReportingPage } from "./components/ato/AtoReportingPage";
import { HomePage } from "./homepage/components/HomePage";
import { HomePageMenu } from "./components/menu/HomePageMenu";
import { SignUpPage } from "./auth/components/signup/SignUpPage";
import { LoginPage } from "./auth/components/login/LoginPage";
import { AppContextProvider } from "./context/AppContext";
import { LogoutPage } from "./auth/components/logout/LogoutPage";
import { BusinessListPage } from "./business/components/BusinessesPage";
import {BusinessCreatePage} from "./business/components/BusinessCreatePage";
import {FeaturesPage} from "./homepage/components/features/FeaturesPage";
import {ContactPage} from "./homepage/components/contact/ContactPage";
import {AboutPage} from "./homepage/components/about/AboutPage";

const ROUTES = {
  ROOT: "/",

  FEATURES: "/features",
  ABOUT: "/about",
  CONTACT: "/contact",

  LOGIN: "/id/login",
  SIGNUP: "/id/sign-up",
  LOGOUT: "/id/logout",

  BUSINESSES: "/id/businesses",
  BUSINESS_CREATE: "/id/businesses/new",

  PAYRUN_EDIT: "/app/payruns/:id",
  PAYRUN_SUMMARY: "/app/payruns/:id/summary",
  PAYRUN_COMPLETE: "/app/payruns/:id/complete",
  DASHBOARD: "/app/payruns",
  PAYRUN_IN_PROGRESS: "/app/payruns/existing/:id",

  EMPLOYEE_CREATE: "/app/employees/new",
  EMPLOYEE_EDIT: "/app/employees/:id",
  EMPLOYEE_LIST: "/app/employees",

  EARNING_CREATE: "/app/earnings/new",
  EARNING_EDIT: "/app/earnings/:id",
  EARNING_LIST: "/app/earnings",

  DEDUCTION_CREATE: "/app/deductions/new",
  DEDUCTION_EDIT: "/app/deductions/:id",
  DEDUCTION_LIST: "/app/deductions",

  ENTITLEMENT_CREATE: "/app/entitlements/new",
  ENTITLEMENT_EDIT: "/app/entitlements/:id",
  ENTITLEMENT_LIST: "/app/entitlements",

  SUPER_CREATE: "/app/super/new",
  SUPER_EDIT: "/app/super/:id",
  SUPER_LIST: "/app/super",

  ATO_REPORTING: "/app/ato-reporting",
  REPORTS: "/app/reports"
};

export const PublicRoutes = () => (
  <>
    <HomePageMenu />
    <Switch>
      <Route exact path={ROUTES.ROOT} component={HomePage} />
      <Route exact path={ROUTES.FEATURES} component={FeaturesPage} />
      <Route exact path={ROUTES.ABOUT} component={AboutPage} />
      <Route exact path={ROUTES.CONTACT} component={ContactPage} />
    </Switch>
  </>
);

export const AuthRoutes = () => (
  <>
    <Switch>
      <Route path={ROUTES.LOGIN} component={LoginPage} />
      <Route path={ROUTES.SIGNUP} component={SignUpPage} />
      <Route path={ROUTES.LOGOUT} component={LogoutPage} />
      <Route path={ROUTES.BUSINESSES} component={BusinessListPage} />
      <Route path={ROUTES.BUSINESS_CREATE} component={BusinessCreatePage} />
    </Switch>
  </>
);

export const AppRoutes = () => (
  <>
    <Menu />
    <Switch>
      <Route path={ROUTES.PAYRUN_IN_PROGRESS} component={ExistingPayrunPage} />
      <Route path={ROUTES.PAYRUN_SUMMARY} component={PayrunSummaryPage} />
      <Route path={ROUTES.PAYRUN_COMPLETE} component={PayrunCompletePage} />
      <Route path={ROUTES.PAYRUN_EDIT} component={PayrunPage} />
      <Route path={ROUTES.DASHBOARD} component={DashboardPage} />
      <Route path={ROUTES.EMPLOYEE_CREATE} component={EmployeeCreatePage} />
      <Route path={ROUTES.EMPLOYEE_EDIT} component={EmployeeEditPage} />
      <Route path={ROUTES.EMPLOYEE_LIST} component={EmployeeListPage} />
      <Route path={ROUTES.EARNING_CREATE} component={EarningPage} />
      <Route path={ROUTES.EARNING_EDIT} component={EarningPage} />
      <Route path={ROUTES.EARNING_LIST} component={EarningListPage} />
      <Route path={ROUTES.DEDUCTION_CREATE} component={DeductionPage} />
      <Route path={ROUTES.DEDUCTION_EDIT} component={DeductionPage} />
      <Route path={ROUTES.DEDUCTION_LIST} component={DeductionListPage} />
      <Route path={ROUTES.ENTITLEMENT_CREATE} component={EntitlementPage} />
      <Route path={ROUTES.ENTITLEMENT_EDIT} component={EntitlementPage} />
      <Route path={ROUTES.ENTITLEMENT_LIST} component={EntitlementListPage} />
      <Route path={ROUTES.SUPER_CREATE} component={SuperPage} />
      <Route path={ROUTES.SUPER_EDIT} component={SuperPage} />
      <Route path={ROUTES.SUPER_LIST} component={SuperListPage} />
      <Route path={ROUTES.ATO_REPORTING} component={AtoReportingPage} />
      <Route path={ROUTES.REPORTS} component={ReportListPage} />
    </Switch>
  </>
);

const AppRouter: React.FC = () => {
  return (
    <AppContextProvider>
      <Router>
        <div>
          <Switch>
            <Route path="/app" component={AppRoutes} />
            <Route path="/id" component={AuthRoutes} />
            <Route path="/" component={PublicRoutes} />
          </Switch>
        </div>
      </Router>
    </AppContextProvider>
  );
};

export { AppRouter, ROUTES };
