import React, {useContext, useState} from "react";
import {AppContext, IAppContext} from "../../context/AppContext";
import Page from "../../components/widgets/Page";
import H2 from "../../components/widgets/H2";
import {ButtonBar} from "../../components/widgets/ButtonBar";
import {Button} from "reactstrap";
import {ListLoader} from "../../components/widgets/ListLoader";
import {Business} from "../api/Business";
import {fetchBusiness} from "../api/BusinessApi";
import BusinessListTable from "./BusinessListTable";
import BusinessId from "../../types/BusinessId";


interface BusinessListViewProps {
  onCreateClick: () => void;
  selectBusiness: (selectedBusinessId: BusinessId) => void;
}

const BusinessListView: React.FC<BusinessListViewProps> = ({
                                                             onCreateClick,
                                                             selectBusiness
                                                           }) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const [businesses, setBusinesses] = useState<Array<Business>>([]);
  return (
    <Page>
      <H2>Businesses</H2>
      <ButtonBar>
        <Button color="primary" onClick={onCreateClick}>
          New Business
        </Button>
      </ButtonBar>
      <ListLoader
        businessId={appState.currentBusinessId}
        fetchRecords={fetchBusiness}
        setRecords={setBusinesses}
      >
        <BusinessListTable businesses={businesses} selectBusiness={selectBusiness} />
      </ListLoader>
    </Page>
  );
};

export { BusinessListView };
