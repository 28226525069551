import * as React from 'react';

interface H2Props {
  center?: boolean;
}

const H2: React.FC<H2Props> = (props) => {
  const className = props.center ? 'mt-4 mb-2 text-center' : 'mt-4 mb-2';
  return <h2 className="mt-4 mb-2">{props.children}</h2>
};

export default H2;
