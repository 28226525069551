import * as React from "react";
import { EmployeeTab } from "./EmployeeTab";
import {EmployeeModel} from '../../../service/employee/EmployeeModel';
import { EmployeeStandardPayForm } from "../../forms/EmployeeStandardPayForm";

interface EmployeeStandardPayTabProps {
  employee: EmployeeModel;
  onSave: () => void;
  onCancel: () => void;
  updateModel: (name: string, value: string) => void;
}

const EmployeeStandardPayTab: React.SFC<EmployeeStandardPayTabProps> = ({
  employee,
  onSave,
  onCancel,
  updateModel,
}) => {
  return (
    <EmployeeTab name={"Standard pay"} onCancel={onCancel} onSave={onSave}>
      <EmployeeStandardPayForm
        employee={employee}
        updateModel={updateModel}
      />
    </EmployeeTab>
  );
};

export { EmployeeStandardPayTab };
