import {ContactModel} from "../components/contact/ContactModel";
import {sendEmail} from "../api/SendMessageApi";


export const sendMessage = async (contactModel: ContactModel): Promise<string> => {
  return await sendEmail({
    email: contactModel.email,
    firstName: contactModel.firstName,
    lastName: contactModel.lastName,
    businessName: contactModel.businessName,
    message: contactModel.message
  });
};
