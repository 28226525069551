import {History} from "history";
import * as React from "react";
import {SignUpViewProps} from "../signup/SignUpView";
import {
  fieldValidation,
  updateModel,
  validateModel
} from "../../../common/Model";
import {required} from "../../../common/validation/RequiredValidator";
import {SignUpModel, SignUpModelErrors} from "../../service/SignUpModel";
import {login, signUp} from "../../service/AuthService";
import {ROUTES} from "../../../AppRouter";
import {useContext, useState} from "react";
import {LoginModel, LoginModelErrors} from "../../service/LoginModel";
import {LoginView} from "./LoginView";
import {AppContext, IAppContext } from "../../../context/AppContext";

export interface LoginPageProps {
  goBack: () => void;
  history: History;
  SignUpView: React.ComponentType<SignUpViewProps>;
}

const validators = [
  fieldValidation("email", [required]),
  fieldValidation("password", [required])
];

const submitLogin = (
  model: SignUpModel,
  setShowErrors: (showErrors: boolean) => void,
  history: History,
  updateAppState: (name: string, value: any) => void
) => async () => {
  if (!model.isValid) {
    setShowErrors(true);
  } else {
    try {
      setShowErrors(false);
      const businessId = await login(updateAppState)(model.email, model.password);
      history.push(businessId ? ROUTES.DASHBOARD : ROUTES.BUSINESSES);
    } catch (error) {
      model.errors.apiError = error.message;
      setShowErrors(true);
    }
  }
};

const navToSignup = (history: History) => () => {
  history.push(ROUTES.SIGNUP);
}

const LoginPage: React.FC<LoginPageProps> = ({history}) => {
  const initialModel = {
    email: "",
    password: "",
    errors: {} as LoginModelErrors
  } as LoginModel;
  const [model, setModel] = useState<LoginModel>(validateModel(initialModel, validators));
  const {updateAppState} = useContext<IAppContext>(AppContext);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const onUpdate = updateModel(model, setModel, validators);

  return (
    <LoginView
      model={model}
      onSubmit={submitLogin(model, setShowErrors, history, updateAppState)}
      onUpdate={onUpdate}
      showErrors={showErrors}
      navToSignup={navToSignup(history)}
    />
  );
};

export {LoginPage};
