import * as React from "react";
import {Table} from "reactstrap";
import {Business} from "../api/Business";
import {BusinessRow} from "./BusinessRow";
import BusinessId from "../../types/BusinessId";


export interface BusinessListProps {
  businesses: Array<Business>;
  selectBusiness: (selectedBusinessId: BusinessId) => void;
}

const BusinessListTable: React.FC<BusinessListProps> = ({businesses, selectBusiness}) => {
  return (
    <Table>
      <thead>
      <tr>
        <th>Name</th>
      </tr>
      </thead>
      <tbody>
      {businesses.map((business) => {
        return <BusinessRow key={business.id} business={business} selectBusiness={selectBusiness} />;
      })}
      </tbody>
    </Table>
  );
};

export default BusinessListTable;
