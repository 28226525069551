import * as React from "react";
import TextInput, {TextInputProps} from "./TextInput";

const PasswordInput: React.FC<TextInputProps> = (props) => {
  return (
    <>
      <TextInput type="password" {...props} />
    </>
  );
};

export default PasswordInput;
