import Payrun from "../api/Payrun";
import moment from "moment";
import PayEarning from "../api/PayEarning";
import PayDeduction from "../api/PayDeduction";
import PayEntitlement from "../api/PayEntitlement";
import PaySuper from "../api/PaySuper";
import PayrunModel from "../components/payrun/model/PayrunModel";
import PayrunPayModel from "../components/payrun/model/PayrunPayModel";
import PayrunPayEarningModel from "../components/payrun/model/PayrunPayEarningModel";
import PayrunPay from "../api/PayrunPay";
import PayrunPayDeductionModel from "../components/payrun/model/PayrunPayDeductionModel";
import PayrunPayEntitlementModel from "../components/payrun/model/PayrunPayEntitlementModel";
import PayrunPaySuperModel from "../components/payrun/model/PayrunPaySuperModel";
import PayrunPayTotalsModel from "../components/payrun/model/PayrunPayTotalsModel";
import PayTotals from "../api/PayTotals";

const convertPayrun = (payrun: Payrun): PayrunModel => {
  return {
    id: payrun.id,
    frequency: payrun.frequency,
    startDate: moment(payrun.startDate),
    endDate: moment(payrun.endDate),
    payDate: moment(payrun.payDate),
    employees: payrun.employees ? payrun.employees.map(p => convertPay(p)) : []
  } as PayrunModel;
};

const convertPay = (pay: PayrunPay): PayrunPayModel => {
  return {
    id: pay.id,
    employeeId: pay.employeeId,
    employeeName: pay.employeeName,
    sequence: pay.sequence,
    notes: pay.notes,
    totals: convertTotals(pay.totals),
    earnings: pay.earnings.map(e => convertEarning(e)),
    deductions: pay.deductions.map(e => convertDeduction(e)),
    entitlements: pay.entitlements.map(e => convertEntitlement(e)),
    super: pay.super.map(e => convertSuper(e))
  } as PayrunPayModel;
};

const convertTotals = (totals: PayTotals): PayrunPayTotalsModel => {
  return {
    ...totals
  };
};

const convertEarning = (earning: PayEarning): PayrunPayEarningModel => {
  return {
    id: earning.id,
    earningId: earning.earningId,
    name: earning.name,
    displayName: earning.displayName,
    type: earning.type,
    calculationMethod: earning.calculationMethod,
    stpType: earning.stpType,
    paySuper: earning.paySuper,
    accrueLeave: earning.accrueLeave,
    reducesBase: earning.reducesBase,
    amount: earning.amount,
    units: earning.units,
    total: earning.total
  } as PayrunPayEarningModel;
};

const convertDeduction = (deduction: PayDeduction): PayrunPayDeductionModel => {
  return {
    id: deduction.id,
    deductionId: deduction.deductionId,
    name: deduction.name,
    displayName: deduction.displayName,
    type: deduction.type,
    calculationMethod: deduction.calculationMethod,
    limitType: deduction.limitType,
    limitCalculationMethod: deduction.limitCalculationMethod,
    stpType: deduction.stpType,
    beforeTax: deduction.beforeTax,
    amount: deduction.amount,
    units: deduction.units,
    total: deduction.total
  } as PayrunPayDeductionModel;
};

const convertEntitlement = (
  entitlement: PayEntitlement
): PayrunPayEntitlementModel => {
  return {
    id: entitlement.id,
    entitlementId: entitlement.entitlementId,
    name: entitlement.name,
    displayName: entitlement.displayName,
    calculationMethod: entitlement.calculationMethod,
    stpType: entitlement.stpType,
    amount: entitlement.amount,
    earning: entitlement.earning,
    total: entitlement.total
  } as PayrunPayEntitlementModel;
};

const convertSuper = (PaySuper: PaySuper): PayrunPaySuperModel => {
  return {
    id: PaySuper.id,
    superId: PaySuper.superId,
    name: PaySuper.name,
    displayName: PaySuper.displayName,
    type: PaySuper.type,
    calculationMethod: PaySuper.calculationMethod,
    stpType: PaySuper.stpType,
    amount: PaySuper.amount,
    total: PaySuper.total
  } as PayrunPaySuperModel;
};

export { convertPayrun };
