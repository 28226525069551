import * as React from "react";
import Select from "./Select";
import { enumToKeyValues } from "./enum/enumToKeyValues";
import EntitlementCalculationMethod from "../../../payroll/payitem/api/EntitlementCalculationMethod";

interface EntitlementCalculationMethodInputProps {
  selected: EntitlementCalculationMethod;
  update: (name: string, value: string) => void;
}

const EntitlementCalculationMethodInput: React.SFC<
  EntitlementCalculationMethodInputProps
> = ({ selected, update }) => (
  <Select
    name="calculationMethod"
    collection={enumToKeyValues(EntitlementCalculationMethod)}
    selected={selected}
    update={update}
  />
);

export { EntitlementCalculationMethodInput };
