import * as React from "react";
import {Link} from "react-router-dom";
import {
  Collapse, DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  UncontrolledDropdown
} from "reactstrap";
import {ROUTES} from "../../AppRouter";
import styled from "styled-components";
import KeisanLogo from '../../images/keisan-logo.svg';


const HomePageMenu = () => (
  <div>
    <NavbarStyled dark={true} className="bg-primary fixed-top" light expand="md">
      <div className="container">
        <NavbarBrand href={ROUTES.ROOT} className="mr-5">
          <img src={KeisanLogo} width={120} alt="Keisan Logo" />
        </NavbarBrand>
        <Collapse navbar>

          <Nav className="d-flex ml-5" navbar>
            <NavItem>
              <Link to={ROUTES.FEATURES} className="nav-link">
                Features
              </Link>
            </NavItem>
            <NavItem>
              <Link to={ROUTES.ABOUT} className="nav-link">
                About
              </Link>
            </NavItem>
            <NavItem>
              <Link to={ROUTES.CONTACT} className="nav-link">
                Contact
              </Link>
            </NavItem>
          </Nav>

          <Nav className="d-flex ml-auto" navbar>
            <NavItem>
              <Link to={ROUTES.LOGIN} className="btn btn-secondary mr-2">
                Login
              </Link>
            </NavItem>
            <NavItem>
              <Link to={ROUTES.SIGNUP} className="btn btn-primary">
                Free trial
              </Link>
            </NavItem>
          </Nav>

        </Collapse>
      </div>
    </NavbarStyled>
  </div>
);

const NavbarStyled = styled(Navbar)`
  background-color: #31364B;
`;

export {HomePageMenu};
