import * as React from "react";
import * as reactstrap from "reactstrap";
import { useState } from "react";
import { Spinner } from "reactstrap";

const Button: React.FC<reactstrap.ButtonProps> = props => {
  const [saving, setSaving] = useState<boolean>(false);

  const onClick = (event: React.MouseEvent<any>) => {
    setSaving(true);
    if (props.onClick) {
      props.onClick(event);
    }
  };

  return (
    <reactstrap.Button {...props} onClick={onClick}>
      {saving && (
        <>
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
            className="mr-2"
          />
          Loading ...
        </>
      )}
      {!saving && <>{props.children}</>}
    </reactstrap.Button>
  );
};

export default Button;
