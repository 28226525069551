import * as React from "react";
import { Input } from "reactstrap";
import KeyValuePair from "./KeyValuePair";
import {ChangeEvent} from 'react';

interface SelectProps {
  name: string;
  collection: Array<KeyValuePair>;
  selected: string;
  update: (name: string, value: any) => void;
}

const onChange = (name: string, update: (name: string, value: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
  update(name, event.target.value);
};

const Select: React.SFC<SelectProps> = ({ name, collection, selected, update }) => {
  return (
    <Input type="select" name={name} id={name} value={selected} onChange={onChange(name, update)}>
      {collection.map(keyValue => {
        return (
          <option
            key={keyValue.key}
            value={keyValue.key}
          >
            {keyValue.value}
          </option>
        );
      })}
    </Input>
  );
};

export default Select;
