import * as React from "react";
import {RowComponentProps} from "../../../../../components/widgets/table/RowComponentProps";
import PayForm from "../model/PayForm";


const PayrunSummaryRow: React.FC<RowComponentProps<PayForm>> = ({item}) => {
  return (<tr>
    <td>
      {item.employeeName}
    </td>
    <td>{item.totalEarnings}</td>
    <td>{item.totalPreTaxDeductions}</td>
    <td>{item.taxableIncome}</td>
    <td>{item.totalPostTaxDeductions}</td>
    <td>{item.tax}</td>
    <td>{item.takeHomePay}</td>
  </tr>);
};

export {PayrunSummaryRow};
