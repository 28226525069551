import * as React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface EmployeeEarningsModalProps {
  open: boolean;
  setModal: (open: boolean) => void;
  title: string;
  onSave: () => void;
}


const SaveCancelModal: React.SFC<EmployeeEarningsModalProps> = ({
  open,
  setModal,
  title,
  onSave,
  children
}) => {
  const toggle = () => setModal(!open);

  const toggleAndSave = () => {
    toggle();
    onSave();
  };

  return (
    <Modal isOpen={open} toggle={toggle} centered={true}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggleAndSave}>
          Save
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export { SaveCancelModal };
