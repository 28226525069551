import * as React from "react";
import KeyValuePair from "./KeyValuePair";
import Select from "./Select";

interface TitleInputProps {
  selected: string;
  update: (name: string, value: string) => void;
}

const titles: Array<KeyValuePair> = new Array<KeyValuePair>(
  new KeyValuePair("Mr.", "Mr."),
  new KeyValuePair("Mrs.", "Mrs."),
  new KeyValuePair("Miss", "Miss"),
  new KeyValuePair("Ms.", "Ms."),
  new KeyValuePair("Dr.", "Dr.")
);

const TitleInput: React.SFC<TitleInputProps> = ({ selected, update }) => (
  <Select
    name="title"
    collection={titles}
    selected={selected}
    update={update}
  />
);

export default TitleInput;
