


export enum Environment {
  DEVELOPMENT,
  STAGING,
  PRODUCTION
}


interface EnvironmentConfig {
  authTenantId: string,
  apiRoot: string
}

const config = {
  [Environment.DEVELOPMENT]: {
    authTenantId: 'development-jkzq7',
    apiRoot: 'http://localhost:8080'
  } as EnvironmentConfig,
  [Environment.STAGING]: {
    authTenantId: 'staging-cgpgt',
    apiRoot: 'https://staging-api.keisan.app'
  } as EnvironmentConfig,
  [Environment.PRODUCTION]: {
    authTenantId: 'production-wq3rj',
    apiRoot: 'https://api.keisan.app'
  } as EnvironmentConfig
}


export const getEnvironment = (): Environment => {
  if (window.location.hostname === 'keisan.app' || window.location.hostname === 'ui-production-dot-accounting-295605.ts.r.appspot.com') {
    return Environment.PRODUCTION;
  } else if (window.location.hostname === 'staging.keisan.app' || window.location.hostname === 'staging-dot-accounting-295605.ts.r.appspot.com') {
    return Environment.STAGING;
  }
  return Environment.DEVELOPMENT;
};


export const getAuthTenantId = (): string => {
  return config[getEnvironment()].authTenantId;
};

export const getApiRoot = () => {
  return config[getEnvironment()].apiRoot;
};
