import * as React from "react";
import { EmployeeTab } from "./EmployeeTab";
import { EmployeeModel } from "../../../service/employee/EmployeeModel";
import { EmployeePersonalDetailsForm } from "../../forms/EmployeePersonalDetailsForm";

interface EmployeePersonalDetailsTabProps {
  employee: EmployeeModel;
  onSave: () => void;
  onCancel: () => void;
  updateModel: (name: string, value: any) => void;
}

const EmployeePersonalDetailsTab: React.SFC<
  EmployeePersonalDetailsTabProps
> = ({ employee, onSave, onCancel, updateModel }) => {
  return (
    <EmployeeTab name={"Personal details"} onCancel={onCancel} onSave={onSave}>
      <EmployeePersonalDetailsForm
        employee={employee}
        updateModel={updateModel}
      />
    </EmployeeTab>
  );
};

export { EmployeePersonalDetailsTab };
