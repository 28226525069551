import * as React from "react";
import { History } from "history";
import { ROUTES } from "../../../../AppRouter";
import { CrudListPage } from "../../../../components/widgets/CrudListPage";
import { SuperRow } from "./SuperRow";
import { deleteSuper, fetchSupers } from "../../api/super/SuperApi";

interface SuperListPageProps {
  history: History;
}

const SuperListPage: React.FC<SuperListPageProps> = ({ history }) => {
  return (
    <CrudListPage
      entityName="super"
      createRoute={ROUTES.SUPER_CREATE}
      deleteRecord={deleteSuper}
      fetchRecords={fetchSupers}
      history={history}
      headings={["Display name", "Type", "STP category", ""]}
      RowComponent={SuperRow}
    />
  );
};

export default SuperListPage;
