import * as React from 'react';
import {Button, Form, FormGroup, Label, Input} from 'reactstrap';
import Page from "../widgets/Page";

export interface ReportListPageProps { }

export default class ReportListPage extends React.Component<ReportListPageProps> {
  render() {
    return (
      <Page>
        <h2>Reports</h2>
      </Page>
    );
  }
}