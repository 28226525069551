import * as React from "react";
import {SignUpSection} from "./SignUpSection";
import {PlansSection} from "./PlansSection";
import PublicPage from "./PublicPage";
import {FooterSection} from "./FooterSection";

export interface HomePageViewProps {
  tryPayrollAction: () => void;
}

const HomePageView: React.FC<HomePageViewProps> = ({ tryPayrollAction }) => {
  return (
    <PublicPage>
      <SignUpSection tryPayrollAction={tryPayrollAction} />
      <PlansSection tryPayrollAction={tryPayrollAction} />
      <FooterSection />
    </PublicPage>
  );
};

export { HomePageView };
