import * as React from "react";
import {ChangeEvent, useState} from "react";
import {Input} from "reactstrap";
import moment, {Moment} from "moment";
import {ErrorMessage} from "../ErrorMessage";

interface DateInputProps {
  name: string;
  value?: Moment;
  update: (name: string, value: Moment) => void;
  showErrors?: boolean;
  error?: string;
}

const onChange = (
  name: string,
  update: (name: string, value: Moment) => void
) => (event: ChangeEvent<HTMLInputElement>) => {
  update(name, toMoment(event.target.value));
};

const onBlur = (
  setTouched: (value: ((prevState: boolean) => boolean) | boolean) => void
) => () => {
  setTouched(true);
};

const DateInput: React.SFC<DateInputProps> = ({
  name,
  value,
  update,
  showErrors,
  error
}) => {
  let [touched, setTouched] = useState(false);
  const showError = error && error.length > 0 && (touched || showErrors);
  const momentValue = toMoment(value);
  return (
    <>
      <Input
        type="date"
        name={name}
        className={showError ? "is-invalid" : ""}
        id={name}
        value={momentValue ? momentValue.format("YYYY-MM-DD") : undefined}
        onChange={onChange(name, update)}
        onBlur={onBlur(setTouched)}
      />
      {showError && <ErrorMessage message={error ? error : ""} />}
    </>
  );
};

const toMoment = (input: any) => {
  return input && typeof input === "string" ? moment(input) : input;
};

export default DateInput;
