import * as React from "react";
import { useContext, useState } from "react";
import { History } from "history";
import { AppContext, IAppContext } from "../../../../context/AppContext";
import { EmployeeEditPageView } from "./EmployeeEditPageView";
import {
  getEmployee,
  updateEmployee
} from "../../service/employee/EmployeeService";
import { updateModel, validateModel } from "../../../../common/Model";
import { EmployeeModel } from "../../service/employee/EmployeeModel";
import { computeEmployeeValues } from "../../service/employee/EmployeeCompute";
import { getEmployeeValidators } from "../../service/validation/EmployeeValidationService";
import { Loader } from "../../../../components/widgets/Loader";

export interface EmployeeEditPageProps {
  match: any;
  history: History;
}

const onSave = (
  history: History,
  businessId: string,
  employee: EmployeeModel
) => async () => {
  await updateEmployee(businessId, employee);
  history.goBack();
};

const onCancel = (history: History) => () => {
  history.goBack();
};

const EmployeeEditPage: React.FC<EmployeeEditPageProps> = ({
  match,
  history
}) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const [employee, setEmployee] = useState<EmployeeModel>();

  const fetchData = async () => {
    const returned = await getEmployee(appState.currentBusinessId, match.params.id);
    setEmployee(validateModel(returned, getEmployeeValidators()));
  };

  return (
    <Loader fetchData={fetchData}>
      {employee && (
        <EmployeeEditPageView
          updateModel={updateModel(
            employee,
            setEmployee,
            getEmployeeValidators(),
            computeEmployeeValues
          )}
          employee={employee}
          onSave={onSave(history, appState.currentBusinessId, employee)}
          onCancel={onCancel(history)}
        />
      )}
    </Loader>
  );
};

export { EmployeeEditPage };
