import * as React from "react";

import { Button, Card, CardText, CardTitle, Col, Row } from "reactstrap";
import { ButtonBar } from "../../../../components/widgets/ButtonBar";

interface EmployeeStepProps {
  name: string;
  children: JSX.Element;
  onCancel: () => void;
  onNext: () => void;
  onPrevious: () => void;
  lastStep: boolean;
}

const EmployeeStep: React.SFC<EmployeeStepProps> = ({
  name,
  children,
  onCancel,
  onNext,
  onPrevious,
  lastStep
}) => {
  const nextName = lastStep ? "Save" : "Next";
  return (
    <Row>
      <Col sm="12">
        <Card body>
          <CardTitle>{name}</CardTitle>
          <div>
            {children}
            <ButtonBar>
              <Button color="secondary" className={"mr-2"} onClick={onCancel}>
                Cancel
              </Button>
              <Button color="secondary" className={"mr-2"} onClick={onPrevious}>
                Previous
              </Button>

              <Button color="primary" className={"mr-2"} onClick={onNext}>
                {nextName}
              </Button>
            </ButtonBar>
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export { EmployeeStep };
