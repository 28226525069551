import * as React from "react";

export interface TableHeadingProps {
  headings: Array<string>;
}

const TableHeading: React.FC<TableHeadingProps> = ({ headings }) => {
  return (
    <thead>
      <tr>
        {headings.map(heading => {
          return <th key={heading}>{heading}</th>;
        })}
      </tr>
    </thead>
  );
};

export { TableHeading };
