import * as React from "react";
import Employee from "../../api/Employee";
import { Link } from "react-router-dom";
import { getEditRoute } from "../../../../common/Routing";
import { ROUTES } from "../../../../AppRouter";

export interface EmployeeRowProps {
  employee: Employee;
}

const EmployeeRow: React.FC<EmployeeRowProps> = ({ employee }) => (
  <tr>
    <td>
      <Link
        to={getEditRoute(ROUTES.EMPLOYEE_EDIT, employee.id)}
        className="nav-link"
      >
        {employee.firstName} {employee.lastName}
      </Link>
    </td>
    <td>{employee.employmentDetails.payFrequency.toString()}</td>
    <td>
      {employee.lastPaymentDate ? employee.lastPaymentDate.toString() : ""}
    </td>
    <td />
  </tr>
);

export { EmployeeRow };
