import * as React from "react";

import { Col, Form, FormGroup, Label, Row } from "reactstrap";
import PayFrequencyInput from "../../../../components/widgets/input/PayFrequencyInput";
import DateInput from "../../../../components/widgets/input/DateInput";
import TextInput from "../../../../components/widgets/input/TextInput";
import { EmployeeModel } from "../../service/employee/EmployeeModel";

interface EmploymentDetailsFormProps {
  employee: EmployeeModel;
  updateModel: (name: string, value: any) => void;
}

const EmploymentDetailsForm: React.SFC<EmploymentDetailsFormProps> = ({
  employee,
  updateModel
}) => (
  <Row>
    <Col>
      <Form>
        <FormGroup row>
          <Label for="startDate" sm={4}>
            Start date
          </Label>
          <Col sm={8}>
            <DateInput
              name="startDate"
              value={employee.startDate}
              error={employee.errors.startDate}
              showErrors={employee.showErrors}
              update={updateModel}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="terminationDate" sm={4}>
            Termination date
          </Label>
          <Col sm={8}>
            <DateInput
              name="terminationDate"
              value={employee.terminationDate}
              error={employee.errors.terminationDate}
              showErrors={employee.showErrors}
              update={updateModel}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="jobTitle" sm={4}>
            Job title
          </Label>
          <Col sm={8}>
            <TextInput
              name="jobTitle"
              value={employee.jobTitle}
              error={employee.errors.jobTitle}
              showErrors={employee.showErrors}
              update={updateModel}
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="payFrequency" sm={4}>
            Pay frequency
          </Label>
          <Col sm={8}>
            <PayFrequencyInput
              selected={employee.payFrequency}
              update={updateModel}
            />
          </Col>
        </FormGroup>
      </Form>
    </Col>
    <Col>
      <Form>
        <FormGroup row>
          <Label for="examplepayslipNotes">Payslip notes</Label>
          <TextInput
            type="textarea"
            name="payslipNotes"
            value={employee.payslipNotes}
            error={employee.errors.payslipNotes}
            showErrors={employee.showErrors}
            rows={10}
          />
        </FormGroup>
      </Form>
    </Col>
  </Row>
);

export { EmploymentDetailsForm };
