import {Moment} from 'moment';


const formatDate = (date: Moment): string => {
  return date.format('DD/MM/YYYY');
};

const formatIsoDate = (date: Moment): string => {
  return date.format('YYYY-MM-DD');
};

export {formatDate, formatIsoDate};
