import * as React from "react";
import KeyValuePair from "./KeyValuePair";
import Select from "./Select";

interface CountryInputProps {
  selected: string;
  update: (name: string, value: string) => void;
}

const countries: Array<KeyValuePair> = new Array<KeyValuePair>(
  new KeyValuePair("AF", "Afghanistan"),
  new KeyValuePair("AX", "Åland Islands"),
  new KeyValuePair("AL", "Albania"),
  new KeyValuePair("DZ", "Algeria"),
  new KeyValuePair("AS", "American Samoa"),
  new KeyValuePair("AD", "Andorra"),
  new KeyValuePair("AO", "Angola"),
  new KeyValuePair("AI", "Anguilla"),
  new KeyValuePair("AQ", "Antarctica"),
  new KeyValuePair("AG", "Antigua and Barbuda"),
  new KeyValuePair("AR", "Argentina"),
  new KeyValuePair("AM", "Armenia"),
  new KeyValuePair("AW", "Aruba"),
  new KeyValuePair("AU", "Australia"),
  new KeyValuePair("AT", "Austria"),
  new KeyValuePair("AZ", "Azerbaijan"),
  new KeyValuePair("BS", "Bahamas"),
  new KeyValuePair("BH", "Bahrain"),
  new KeyValuePair("BD", "Bangladesh"),
  new KeyValuePair("BB", "Barbados"),
  new KeyValuePair("BY", "Belarus"),
  new KeyValuePair("BE", "Belgium"),
  new KeyValuePair("BZ", "Belize"),
  new KeyValuePair("BJ", "Benin"),
  new KeyValuePair("BM", "Bermuda"),
  new KeyValuePair("BT", "Bhutan"),
  new KeyValuePair("BO", "Bolivia, Plurinational State of"),
  new KeyValuePair("BQ", "Bonaire, Sint Eustatius and Saba"),
  new KeyValuePair("BA", "Bosnia and Herzegovina"),
  new KeyValuePair("BW", "Botswana"),
  new KeyValuePair("BV", "Bouvet Island"),
  new KeyValuePair("BR", "Brazil"),
  new KeyValuePair("IO", "British Indian Ocean Territory"),
  new KeyValuePair("BN", "Brunei Darussalam"),
  new KeyValuePair("BG", "Bulgaria"),
  new KeyValuePair("BF", "Burkina Faso"),
  new KeyValuePair("BI", "Burundi"),
  new KeyValuePair("KH", "Cambodia"),
  new KeyValuePair("CM", "Cameroon"),
  new KeyValuePair("CA", "Canada"),
  new KeyValuePair("CV", "Cape Verde"),
  new KeyValuePair("KY", "Cayman Islands"),
  new KeyValuePair("CF", "Central African Republic"),
  new KeyValuePair("TD", "Chad"),
  new KeyValuePair("CL", "Chile"),
  new KeyValuePair("CN", "China"),
  new KeyValuePair("CX", "Christmas Island"),
  new KeyValuePair("CC", "Cocos (Keeling) Islands"),
  new KeyValuePair("CO", "Colombia"),
  new KeyValuePair("KM", "Comoros"),
  new KeyValuePair("CG", "Congo"),
  new KeyValuePair("CD", "Congo, the Democratic Republic of the"),
  new KeyValuePair("CK", "Cook Islands"),
  new KeyValuePair("CR", "Costa Rica"),
  new KeyValuePair("CI", "Côte d'Ivoire"),
  new KeyValuePair("HR", "Croatia"),
  new KeyValuePair("CU", "Cuba"),
  new KeyValuePair("CW", "Curaçao"),
  new KeyValuePair("CY", "Cyprus"),
  new KeyValuePair("CZ", "Czech Republic"),
  new KeyValuePair("DK", "Denmark"),
  new KeyValuePair("DJ", "Djibouti"),
  new KeyValuePair("DM", "Dominica"),
  new KeyValuePair("DO", "Dominican Republic"),
  new KeyValuePair("EC", "Ecuador"),
  new KeyValuePair("EG", "Egypt"),
  new KeyValuePair("SV", "El Salvador"),
  new KeyValuePair("GQ", "Equatorial Guinea"),
  new KeyValuePair("ER", "Eritrea"),
  new KeyValuePair("EE", "Estonia"),
  new KeyValuePair("ET", "Ethiopia"),
  new KeyValuePair("FK", "Falkland Islands (Malvinas)"),
  new KeyValuePair("FO", "Faroe Islands"),
  new KeyValuePair("FJ", "Fiji"),
  new KeyValuePair("FI", "Finland"),
  new KeyValuePair("FR", "France"),
  new KeyValuePair("GF", "French Guiana"),
  new KeyValuePair("PF", "French Polynesia"),
  new KeyValuePair("TF", "French Southern Territories"),
  new KeyValuePair("GA", "Gabon"),
  new KeyValuePair("GM", "Gambia"),
  new KeyValuePair("GE", "Georgia"),
  new KeyValuePair("DE", "Germany"),
  new KeyValuePair("GH", "Ghana"),
  new KeyValuePair("GI", "Gibraltar"),
  new KeyValuePair("GR", "Greece"),
  new KeyValuePair("GL", "Greenland"),
  new KeyValuePair("GD", "Grenada"),
  new KeyValuePair("GP", "Guadeloupe"),
  new KeyValuePair("GU", "Guam"),
  new KeyValuePair("GT", "Guatemala"),
  new KeyValuePair("GG", "Guernsey"),
  new KeyValuePair("GN", "Guinea"),
  new KeyValuePair("GW", "Guinea-Bissau"),
  new KeyValuePair("GY", "Guyana"),
  new KeyValuePair("HT", "Haiti"),
  new KeyValuePair("HM", "Heard Island and McDonald Islands"),
  new KeyValuePair("VA", "Holy See (Vatican City State)"),
  new KeyValuePair("HN", "Honduras"),
  new KeyValuePair("HK", "Hong Kong"),
  new KeyValuePair("HU", "Hungary"),
  new KeyValuePair("IS", "Iceland"),
  new KeyValuePair("IN", "India"),
  new KeyValuePair("ID", "Indonesia"),
  new KeyValuePair("IR", "Iran, Islamic Republic of"),
  new KeyValuePair("IQ", "Iraq"),
  new KeyValuePair("IE", "Ireland"),
  new KeyValuePair("IM", "Isle of Man"),
  new KeyValuePair("IL", "Israel"),
  new KeyValuePair("IT", "Italy"),
  new KeyValuePair("JM", "Jamaica"),
  new KeyValuePair("JP", "Japan"),
  new KeyValuePair("JE", "Jersey"),
  new KeyValuePair("JO", "Jordan"),
  new KeyValuePair("KZ", "Kazakhstan"),
  new KeyValuePair("KE", "Kenya"),
  new KeyValuePair("KI", "Kiribati"),
  new KeyValuePair("KP", "Korea, Democratic People's Republic of"),
  new KeyValuePair("KR", "Korea, Republic of"),
  new KeyValuePair("KW", "Kuwait"),
  new KeyValuePair("KG", "Kyrgyzstan"),
  new KeyValuePair("LA", "Lao People's Democratic Republic"),
  new KeyValuePair("LV", "Latvia"),
  new KeyValuePair("LB", "Lebanon"),
  new KeyValuePair("LS", "Lesotho"),
  new KeyValuePair("LR", "Liberia"),
  new KeyValuePair("LY", "Libya"),
  new KeyValuePair("LI", "Liechtenstein"),
  new KeyValuePair("LT", "Lithuania"),
  new KeyValuePair("LU", "Luxembourg"),
  new KeyValuePair("MO", "Macao"),
  new KeyValuePair("MK", "Macedonia, the former Yugoslav Republic of"),
  new KeyValuePair("MG", "Madagascar"),
  new KeyValuePair("MW", "Malawi"),
  new KeyValuePair("MY", "Malaysia"),
  new KeyValuePair("MV", "Maldives"),
  new KeyValuePair("ML", "Mali"),
  new KeyValuePair("MT", "Malta"),
  new KeyValuePair("MH", "Marshall Islands"),
  new KeyValuePair("MQ", "Martinique"),
  new KeyValuePair("MR", "Mauritania"),
  new KeyValuePair("MU", "Mauritius"),
  new KeyValuePair("YT", "Mayotte"),
  new KeyValuePair("MX", "Mexico"),
  new KeyValuePair("FM", "Micronesia, Federated States of"),
  new KeyValuePair("MD", "Moldova, Republic of"),
  new KeyValuePair("MC", "Monaco"),
  new KeyValuePair("MN", "Mongolia"),
  new KeyValuePair("ME", "Montenegro"),
  new KeyValuePair("MS", "Montserrat"),
  new KeyValuePair("MA", "Morocco"),
  new KeyValuePair("MZ", "Mozambique"),
  new KeyValuePair("MM", "Myanmar"),
  new KeyValuePair("NA", "Namibia"),
  new KeyValuePair("NR", "Nauru"),
  new KeyValuePair("NP", "Nepal"),
  new KeyValuePair("NL", "Netherlands"),
  new KeyValuePair("NC", "New Caledonia"),
  new KeyValuePair("NZ", "New Zealand"),
  new KeyValuePair("NI", "Nicaragua"),
  new KeyValuePair("NE", "Niger"),
  new KeyValuePair("NG", "Nigeria"),
  new KeyValuePair("NU", "Niue"),
  new KeyValuePair("NF", "Norfolk Island"),
  new KeyValuePair("MP", "Northern Mariana Islands"),
  new KeyValuePair("NO", "Norway"),
  new KeyValuePair("OM", "Oman"),
  new KeyValuePair("PK", "Pakistan"),
  new KeyValuePair("PW", "Palau"),
  new KeyValuePair("PS", "Palestinian Territory, Occupied"),
  new KeyValuePair("PA", "Panama"),
  new KeyValuePair("PG", "Papua New Guinea"),
  new KeyValuePair("PY", "Paraguay"),
  new KeyValuePair("PE", "Peru"),
  new KeyValuePair("PH", "Philippines"),
  new KeyValuePair("PN", "Pitcairn"),
  new KeyValuePair("PL", "Poland"),
  new KeyValuePair("PT", "Portugal"),
  new KeyValuePair("PR", "Puerto Rico"),
  new KeyValuePair("QA", "Qatar"),
  new KeyValuePair("RE", "Réunion"),
  new KeyValuePair("RO", "Romania"),
  new KeyValuePair("RU", "Russian Federation"),
  new KeyValuePair("RW", "Rwanda"),
  new KeyValuePair("BL", "Saint Barthélemy"),
  new KeyValuePair("SH", "Saint Helena, Ascension and Tristan da Cunha"),
  new KeyValuePair("KN", "Saint Kitts and Nevis"),
  new KeyValuePair("LC", "Saint Lucia"),
  new KeyValuePair("MF", "Saint Martin (French part)"),
  new KeyValuePair("PM", "Saint Pierre and Miquelon"),
  new KeyValuePair("VC", "Saint Vincent and the Grenadines"),
  new KeyValuePair("WS", "Samoa"),
  new KeyValuePair("SM", "San Marino"),
  new KeyValuePair("ST", "Sao Tome and Principe"),
  new KeyValuePair("SA", "Saudi Arabia"),
  new KeyValuePair("SN", "Senegal"),
  new KeyValuePair("RS", "Serbia"),
  new KeyValuePair("SC", "Seychelles"),
  new KeyValuePair("SL", "Sierra Leone"),
  new KeyValuePair("SG", "Singapore"),
  new KeyValuePair("SX", "Sint Maarten (Dutch part)"),
  new KeyValuePair("SK", "Slovakia"),
  new KeyValuePair("SI", "Slovenia"),
  new KeyValuePair("SB", "Solomon Islands"),
  new KeyValuePair("SO", "Somalia"),
  new KeyValuePair("ZA", "South Africa"),
  new KeyValuePair("GS", "South Georgia and the South Sandwich Islands"),
  new KeyValuePair("SS", "South Sudan"),
  new KeyValuePair("ES", "Spain"),
  new KeyValuePair("LK", "Sri Lanka"),
  new KeyValuePair("SD", "Sudan"),
  new KeyValuePair("SR", "Suriname"),
  new KeyValuePair("SJ", "Svalbard and Jan Mayen"),
  new KeyValuePair("SZ", "Swaziland"),
  new KeyValuePair("SE", "Sweden"),
  new KeyValuePair("CH", "Switzerland"),
  new KeyValuePair("SY", "Syrian Arab Republic"),
  new KeyValuePair("TW", "Taiwan, Province of China"),
  new KeyValuePair("TJ", "Tajikistan"),
  new KeyValuePair("TZ", "Tanzania, United Republic of"),
  new KeyValuePair("TH", "Thailand"),
  new KeyValuePair("TL", "Timor-Leste"),
  new KeyValuePair("TG", "Togo"),
  new KeyValuePair("TK", "Tokelau"),
  new KeyValuePair("TO", "Tonga"),
  new KeyValuePair("TT", "Trinidad and Tobago"),
  new KeyValuePair("TN", "Tunisia"),
  new KeyValuePair("TR", "Turkey"),
  new KeyValuePair("TM", "Turkmenistan"),
  new KeyValuePair("TC", "Turks and Caicos Islands"),
  new KeyValuePair("TV", "Tuvalu"),
  new KeyValuePair("UG", "Uganda"),
  new KeyValuePair("UA", "Ukraine"),
  new KeyValuePair("AE", "United Arab Emirates"),
  new KeyValuePair("GB", "United Kingdom"),
  new KeyValuePair("US", "United States"),
  new KeyValuePair("UM", "United States Minor Outlying Islands"),
  new KeyValuePair("UY", "Uruguay"),
  new KeyValuePair("UZ", "Uzbekistan"),
  new KeyValuePair("VU", "Vanuatu"),
  new KeyValuePair("VE", "Venezuela, Bolivarian Republic of"),
  new KeyValuePair("VN", "Viet Nam"),
  new KeyValuePair("VG", "Virgin Islands, British"),
  new KeyValuePair("VI", "Virgin Islands, U.S."),
  new KeyValuePair("WF", "Wallis and Futuna"),
  new KeyValuePair("EH", "Western Sahara"),
  new KeyValuePair("YE", "Yemen"),
  new KeyValuePair("ZM", "Zambia"),
  new KeyValuePair("ZW", "Zimbabwe")
);

const CountryInput: React.SFC<CountryInputProps> = ({ selected, update }) => {
  return (
    <Select
      name="country"
      collection={countries}
      selected={selected}
      update={update}
    />
  );
};

export default CountryInput;
