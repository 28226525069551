import KeyValuePair from "../KeyValuePair";

function enumToKeyValues<T extends string, TEnumValue extends string>(
  enumParam: { [key in T]: TEnumValue }
) {
  return Object.keys(enumParam).map(
    // @ts-ignore
    key => new KeyValuePair(key, enumParam[key])
  );
}

export { enumToKeyValues };
