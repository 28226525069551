import AccessToken from "../../../../types/AccessToken";
import BusinessId from "../../../../types/BusinessId";
import * as EmployeeApi from "../../api/EmployeeApi";
import {EmployeeModel, EmployeeModelErrors} from "../../service/employee/EmployeeModel";
import moment from "moment";
import BankAccount from "../../api/BankAccount";
import EmployeeEarning from "../../api/EmployeeEarning";
import EmployeeDeduction from "../../api/EmployeeDeduction";
import EmployeeEntitlement from "../../api/EmployeeEntitlement";
import EmployeeSuper from "../../api/EmployeeSuper";
import {EmployeeBankAccountModel} from "./EmployeeBankAccountModel";
import {EmployeeEarningModel} from "./EmployeeEarningModel";
import {EmployeeDeductionModel} from "./EmployeeDeductionModel";
import {EmployeeEntitlementModel} from "./EmployeeEntitlementModel";
import {EmployeeSuperModel} from "./EmployeeSuperModel";
import {fetchEarnings} from "../../../payitem/api/earning/EarningApi";
import {fetchSupers} from "../../../payitem/api/super/SuperApi";
import {fetchDeductions} from "../../../payitem/api/deduction/DeductionApi";
import {fetchEntitlements} from "../../../payitem/api/entitlement/EntitlementApi";
import {EmployeeUpdate} from "../../api/EmployeeUpdate";
import EarningType from '../../../payitem/api/EarningType';
import {StandardPayModel} from "./StandardPayModel";
import {fetchStandardPay} from "../../../payitem/api/standardpay/StandardPayApi";

const convertBankAccount = (b: BankAccount): EmployeeBankAccountModel =>
  b as EmployeeBankAccountModel;

const convertEarning = (e: EmployeeEarning): EmployeeEarningModel => ({
  id: e.id,
  name: e.name,
  displayName: e.displayName,
  type: e.type,
  calculationMethod: e.calculationMethod,
  stpType: e.stpType,
  selected: false
});

const convertDeduction = (d: EmployeeDeduction): EmployeeDeductionModel => ({
  id: d.id,
  name: d.name,
  displayName: d.displayName,
  type: d.type,
  calculationMethod: d.calculationMethod,
  stpType: d.stpType,
  selected: false
});

const convertEntitlement = (
  e: EmployeeEntitlement
): EmployeeEntitlementModel => ({
  id: e.id,
  name: e.name,
  displayName: e.displayName,
  type: e.type,
  calculationMethod: e.calculationMethod,
  stpType: e.stpType,
  selected: false
});

const convertSuper = (s: EmployeeSuper): EmployeeSuperModel => ({
  id: s.id,
  name: s.name,
  displayName: s.displayName,
  type: s.type,
  calculationMethod: s.calculationMethod,
  stpType: s.stpType,
  selected: false
});

const getEarnings = async (
  businessId: string
): Promise<EmployeeEarningModel[]> => {
  const earnings = await fetchEarnings(businessId);
  return earnings.filter(e => e.type !== EarningType.BASE).map(e => convertEarning(e));
};

const getSuper = async (
  businessId: string
): Promise<EmployeeSuperModel[]> => {
  const supers = await fetchSupers(businessId);
  return supers.map(e => convertSuper(e));
};

const getDeductions = async (
  businessId: string
): Promise<EmployeeDeductionModel[]> => {
  const deductions = await fetchDeductions(businessId);
  return deductions.map(e => convertDeduction(e));
};

const getEntitlements = async (
  businessId: string
): Promise<EmployeeEntitlementModel[]> => {
  const entitlements = await fetchEntitlements(businessId);
  return entitlements.map(e => convertEntitlement(e));
};

const getStandardPay = async (
  businessId: string
): Promise<StandardPayModel> => {
  const standardPay = await fetchStandardPay(businessId);
  return {
    earnings: standardPay.earnings.map(e => convertEarning(e)),
    deductions: standardPay.deductions.map(e => convertDeduction(e)),
    entitlements: standardPay.entitlements.map(e => convertEntitlement(e)),
    super: standardPay.super.map(e => convertSuper(e))
  };
};

const getEmployee = async (
  businessId: BusinessId,
  employeeId: string
): Promise<EmployeeModel> => {
  let employee = await EmployeeApi.fetchEmployee(businessId, employeeId);
  return <EmployeeModel>{
    id: employee.id,
    title: employee.title,
    firstName: employee.firstName,
    middleName: employee.middleName,
    lastName: employee.lastName,
    dateOfBirth: moment(employee.dateOfBirth),
    gender: employee.gender,
    emergencyContactName: employee.emergencyContact.name,
    emergencyContactRelationship: employee.emergencyContact.relationship,
    emergencyContactPhone: employee.emergencyContact.phone,
    emergencyContactEmail: employee.emergencyContact.email,
    jobTitle: employee.employmentDetails.jobTitle,
    terminationDate: moment(employee.employmentDetails.terminationDate),
    startDate: moment(employee.employmentDetails.startDate),
    payFrequency: employee.employmentDetails.payFrequency,
    standardHours: employee.employmentDetails.standardHours,
    hourlyRate: employee.employmentDetails.hourlyRate,
    annualSalary: employee.employmentDetails.annualSalary,
    lastPaymentDate: moment(employee.lastPaymentDate),
    phone: employee.contactInformation.phone,
    mobile: employee.contactInformation.mobile,
    email: employee.contactInformation.email,
    streetAddress1: employee.contactInformation.address.streetAddress1,
    streetAddress2: employee.contactInformation.address.streetAddress2,
    suburb: employee.contactInformation.address.suburb,
    postCode: employee.contactInformation.address.postCode,
    state: employee.contactInformation.address.state,
    country: employee.contactInformation.address.country,
    tfn: employee.tax.tfn.toString(10),
    medicareLevy: employee.tax.medicareLevy,
    paymentBasis: employee.tax.paymentBasis,
    residencyStatus: employee.tax.residencyStatus,
    taxFreeThreshold: employee.tax.taxFreeThreshold,
    helpSslTslDebt: employee.tax.helpSslTslDebt,
    financialSupplementDebt: employee.tax.financialSupplementDebt,
    withholdingVariation: employee.tax.withholdingVariation,
    otherTaxOffset: employee.tax.otherTaxOffset,
    declarationDate: moment(employee.tax.declarationDate),
    bankAccounts: employee.bankAccounts.map(b => convertBankAccount(b)),
    earnings: employee.earnings.filter(e => e.type !== EarningType.BASE).map(e => convertEarning(e)),
    deductions: employee.deductions.map(d => convertDeduction(d)),
    entitlements: employee.entitlements.map(entitlement =>
      convertEntitlement(entitlement)
    ),
    super: employee.super.map(s => convertSuper(s)),
    errors: {} as EmployeeModelErrors
  };
};

const updateEmployee = async (
  businessId: BusinessId,
  employee: EmployeeModel
): Promise<void> => {
  const body = convertToBody(employee);
  return await EmployeeApi.updateEmployee(
    businessId,
    employee.id!,
    body
  );
};

const creatEmployee = async (
  businessId: BusinessId,
  employee: EmployeeModel
): Promise<void> => {
  const body = convertToBody(employee);
  return await EmployeeApi.createEmployee(
    businessId,
    employee.id!,
    body
  );
};


const convertToBody = (employee: EmployeeModel) => {
  return {
    id: employee.id,
    title: employee.title,
    firstName: employee.firstName,
    middleName: employee.middleName,
    lastName: employee.lastName,
    gender: employee.gender,
    dateOfBirth: employee.dateOfBirth,
    contactInformation: {
      address: {
        streetAddress1: employee.streetAddress1,
        streetAddress2: employee.streetAddress2,
        suburb: employee.suburb,
        state: employee.state,
        postCode: employee.postCode,
        country: employee.country
      },
      phone: employee.phone,
      mobile: employee.mobile,
      email: employee.email
    },
    emergencyContact: {
      name: employee.emergencyContactName,
      relationship: employee.emergencyContactRelationship,
      phone: employee.emergencyContactPhone,
      email: employee.emergencyContactEmail
    },
    employmentDetails: {
      startDate: employee.startDate,
      terminationDate: employee.terminationDate,
      jobTitle: employee.jobTitle,
      payFrequency: employee.payFrequency,
      standardHours: employee.standardHours,
      hourlyRate: employee.hourlyRate,
      annualSalary: employee.annualSalary
    },
    tax: {
      tfn: parseInt(employee.tfn, 10),
      medicareLevy: employee.medicareLevy,
      paymentBasis: employee.paymentBasis,
      residencyStatus: employee.residencyStatus,
      taxFreeThreshold: employee.taxFreeThreshold,
      helpSslTslDebt: employee.helpSslTslDebt,
      financialSupplementDebt: employee.financialSupplementDebt,
      withholdingVariation: employee.withholdingVariation,
      otherTaxOffset: employee.otherTaxOffset,
      declarationDate: employee.declarationDate
    },
    bankAccounts: employee.bankAccounts.map(it => convertBankAccountToDto(it)),
    earnings: employee.earnings.map(it => it.id),
    deductions: employee.deductions.map(it => it.id),
    super: employee.super.map(it => it.id),
    entitlements: employee.entitlements.map(it => it.id)
  } as EmployeeUpdate;
};

const convertBankAccountToDto = (b: EmployeeBankAccountModel): BankAccount =>
  b as BankAccount;


export {
  getEmployee,
  getEarnings,
  getDeductions,
  getSuper,
  getEntitlements,
  creatEmployee,
  updateEmployee,
  getStandardPay
};
