import * as React from "react";
import { PayFrequency } from "../../../payroll/employee/api/PayFrequency";
import KeyValuePair from "./KeyValuePair";
import Select from "./Select";

interface PayFrequencyInputProps {
  selected: PayFrequency;
  update: (name: string, value: string) => void;
}

const payFrequencies: Array<KeyValuePair> = new Array<KeyValuePair>(
  new KeyValuePair("WEEKLY", "Weekly"),
  new KeyValuePair("FORTNIGHTLY", "Fortnightly"),
  new KeyValuePair("MONTHLY", "Monthly")
);

const PayFrequencyInput: React.SFC<PayFrequencyInputProps> = ({
  selected,
  update
}) => (
  <Select
    name="payFrequency"
    collection={payFrequencies}
    selected={selected.toString()}
    update={update}
  />
);

export default PayFrequencyInput;
