import * as React from "react";
import { ChangeEvent, useState } from "react";
import { Input } from "reactstrap";
import { ErrorMessage } from "../ErrorMessage";
import {InputType} from "reactstrap/lib/Input";

export interface TextInputProps {
  name: string;
  value: string;
  update?: (name: string, value: string) => void;
  showErrors?: boolean;
  error?: string;
  type?: InputType;
  rows?: number;
}

const onChange = (
  name: string,
  update?: (name: string, value: string) => void
) => (event: ChangeEvent<HTMLInputElement>) => {
  if (update) {
    update(name, event.target.value);
  }
};

const onBlur = (
  setTouched: (value: ((prevState: boolean) => boolean) | boolean) => void
) => () => {
  setTouched(true);
};

const TextInput: React.FC<TextInputProps> = ({
  name,
  value,
  update,
  error,
  showErrors,
  type,
  rows
}) => {
  let [touched, setTouched] = useState(false);
  const showError = error && error.length > 0 && (touched || showErrors);
  return (
    <>
      <Input
        type={type ? type : "text"}
        rows={rows}
        name={name}
        className={showError ? "is-invalid" : ""}
        id={name}
        value={value}
        onChange={onChange(name, update)}
        onBlur={onBlur(setTouched)}
      />
      {showError && <ErrorMessage message={error ? error : ""} />}
    </>
  );
};

export default TextInput;
