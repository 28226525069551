import * as React from "react";
import { DeleteButton } from "../DeleteButton";
import { useState } from "react";
import { Modal } from "../Modal";

interface TableDeleteButtonProps {
  entityName?: string;
  onDeleteClick: () => void;
}

const TableDeleteButton: React.FC<TableDeleteButtonProps> = ({
  onDeleteClick,
  entityName
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  return (
    <>
      <DeleteButton
        onClick={() => {
          setShowDeleteModal(true);
        }}
      />

      <Modal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        title="Confirm delete"
        saveText="Delete"
        cancelText="Cancel"
        saveHandler={onDeleteClick}
      >
        Are you sure you want to delete this {entityName}?
      </Modal>
    </>
  );
};

export default TableDeleteButton;
