import * as React from "react";
import { useContext, useState } from "react";
import {
  fieldValidation,
  updateModel,
  validateModel
} from "../../../common/Model";
import { SignUpModel, SignUpModelErrors } from "../../service/SignUpModel";
import { required } from "../../../common/validation/RequiredValidator";
import { signUp } from "../../service/AuthService";
import { History } from "history";
import { ROUTES } from "../../../AppRouter";
import { SignUpViewProps } from "./SignUpView";
import { AppContext, IAppContext } from "../../../context/AppContext";

interface SignUpFormProps {
  goBack: () => void;
  history: History;
  SignUpView: React.ComponentType<SignUpViewProps>;
}

const validators = [
  fieldValidation("email", [required]),
  fieldValidation("password", [required])
];

const submitSignup = (
  model: SignUpModel,
  setShowErrors: (showErrors: boolean) => void,
  history: History,
  updateAppState: (name: string, value: any) => void
) => async () => {
  if (!model.isValid) {
    setShowErrors(true);
  } else {
    try {
      setShowErrors(false);
      const businessId = await signUp(model.email, model.password);
      updateAppState("currentBusinessId", businessId);
      history.push(ROUTES.DASHBOARD);
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        model.errors.email = error.message;
      } else {
        model.errors.apiError = error.message;
      }
      setShowErrors(true);
    }
  }
};

const navToLogin = (history: History) => () => {
  history.push(ROUTES.LOGIN);
};

const SignUpForm: React.FC<SignUpFormProps> = ({
  goBack,
  history,
  SignUpView
}) => {
  const initialModel = {
    email: "",
    password: "",
    errors: {} as SignUpModelErrors
  } as SignUpModel;
  const [model, setModel] = useState<SignUpModel>(
    validateModel(initialModel, validators)
  );
  const { updateAppState } = useContext<IAppContext>(AppContext);
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const onUpdate = updateModel(model, setModel, validators);

  return (
    <SignUpView
      onUpdate={onUpdate}
      showErrors={showErrors}
      goBack={goBack}
      model={model}
      onSubmit={submitSignup(model, setShowErrors, history, updateAppState)}
      navToLogin={navToLogin(history)}
    />
  );
};

export { SignUpForm };
