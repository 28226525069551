import * as React from "react";
import { useContext, useState } from "react";

import { Col, Row } from "reactstrap";
import { EmployeeTab } from "./EmployeeTab";
import { EmployeeModel } from "../../../service/employee/EmployeeModel";
import { TableComponent } from "../../../../../components/widgets/table/TableComponent";
import { EmployeePastPayRow } from "../../forms/EmployeePastPayRow";
import { Loader } from "../../../../../components/widgets/Loader";
import { AppContext, IAppContext } from "../../../../../context/AppContext";
import { findPastPays } from "../../../../payrun/service/PayService";
import Pay from "../../../../payrun/api/Pay";
import PayModel from "../../../../payrun/service/PayModel";

interface EmployeePastPaysTabProps {
  employee: EmployeeModel;
  onSave: () => void;
  onCancel: () => void;
}

const EmployeePastPaysTab: React.FC<EmployeePastPaysTabProps> = ({
  employee,
  onSave,
  onCancel
}) => {
  const { appState } = useContext<IAppContext>(AppContext);
  const [pays, setPays] = useState<Array<PayModel>>([]);
  const fetchData = async () => {
    const pays = await findPastPays(appState.currentBusinessId, employee.id!!);
    setPays(pays);
  };

  return (
    <EmployeeTab name={"Past pays"} onCancel={onCancel} onSave={onSave}>
      <Row>
        <Col>
          <Loader fetchData={fetchData}>
            <TableComponent
              headings={[
                "Payslip",
                "Start date",
                "End date",
                "Pay date",
                "Take home pay",
                "Tax",
                ""
              ]}
              records={pays}
              entityName={"Pay"}
              RowComponent={EmployeePastPayRow}
            />
          </Loader>
        </Col>
      </Row>
    </EmployeeTab>
  );
};

export { EmployeePastPaysTab };
