import * as React from "react";
import Select from "./Select";
import { enumToKeyValues } from "./enum/enumToKeyValues";
import DeductionCalculationMethod from "../../../payroll/payitem/api/DeductionCalculationMethod";

interface DeductionCalculationMethodInputProps {
  selected: DeductionCalculationMethod;
  update: (name: string, value: string) => void;
}

const DeductionCalculationMethodInput: React.SFC<
  DeductionCalculationMethodInputProps
> = ({ selected, update }) => (
  <Select
    name="calculationMethod"
    collection={enumToKeyValues(DeductionCalculationMethod)}
    selected={selected}
    update={update}
  />
);

export { DeductionCalculationMethodInput };
