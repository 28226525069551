import {EmployeeModel} from "../../service/employee/EmployeeModel";
import WizardStep from "./WizardStep";
import React, {useState} from "react";
import {EmployeePersonalDetailsForm} from "../forms/EmployeePersonalDetailsForm";
import {EmployeeEmergencyContactForm} from "../forms/EmployeeEmergencyContactForm";
import {EmploymentDetailsForm} from "../forms/EmploymentDetailsForm";
import EmployeeTaxForm from "../forms/EmpoyeeTaxForm";
import {EmployeeBankDetailsForm} from "../forms/EmployeeBankDetailsForm";
import {EmployeeStandardPayForm} from "../forms/EmployeeStandardPayForm";
import {EmployeeCreateView} from "./EmployeeCreateView";

interface EmployeeCreateWizardProps {
  employee: EmployeeModel;
  onSave: () => void;
  onCancel: () => void;
  onUpdate: (name: string, value: any) => void;
}

// this is super hacky, need to clean it up
const currentTabValid = (
  currentStep: number,
  employee: EmployeeModel
): boolean => {
  if (
    currentStep == 0 &&
    (employee.errors.firstName ||
      employee.errors.middleName ||
      employee.errors.lastName ||
      employee.errors.dateOfBirth ||
      employee.errors.email ||
      employee.errors.phone ||
      employee.errors.mobile ||
      employee.errors.streetAddress1 ||
      employee.errors.streetAddress2 ||
      employee.errors.suburb ||
      employee.errors.state ||
      employee.errors.postCode ||
      employee.errors.country)
  ) {
    return false;
  }

  if (
    currentStep == 3 &&
    (employee.errors.tfn || employee.errors.declarationDate)
  ) {
    return false;
  }

  return true;
};

const onNext = (
  currentStep: number,
  setStep: (step: number) => void,
  totalSteps: number,
  employee: EmployeeModel,
  updateModel: (name: string, value: any) => void,
  onSave: () => void
) => async () => {
  if (!employee.isValid && !currentTabValid(currentStep, employee)) {
    updateModel("showErrors", true);
  } else {
    const isLastStep = currentStep === totalSteps - 1;
    const newStep = isLastStep ? currentStep : currentStep + 1;
    setStep(newStep);
    if (isLastStep) {
      await onSave();
    }
  }
};

const onPrevious = (
  currentStep: number,
  setStep: (step: number) => void
) => () => {
  const newStep = currentStep == 0 ? (currentStep = 0) : currentStep - 1;
  setStep(newStep);
};

const EmployeeCreateWizard: React.SFC<EmployeeCreateWizardProps> = ({
  employee,
  onSave,
  onCancel,
  onUpdate
}) => {
  let [step, setStep] = useState(0);

  const steps = [
    {
      title: "Personal details",
      component: (
        <EmployeePersonalDetailsForm
          employee={employee}
          updateModel={onUpdate}
        />
      )
    } as WizardStep,
    {
      title: "Emergency contact",
      component: (
        <EmployeeEmergencyContactForm
          employee={employee}
          updateModel={onUpdate}
        />
      )
    } as WizardStep,
    {
      title: "Employement details",
      component: (
        <EmploymentDetailsForm employee={employee} updateModel={onUpdate} />
      )
    } as WizardStep,
    {
      title: "Tax",
      component: <EmployeeTaxForm employee={employee} updateModel={onUpdate} />
    } as WizardStep,
    {
      title: "Bank accounts",
      component: (
        <EmployeeBankDetailsForm employee={employee} updateModel={onUpdate} />
      )
    } as WizardStep,
    {
      title: "Standard pay",
      component: (
        <EmployeeStandardPayForm employee={employee} updateModel={onUpdate} />
      )
    } as WizardStep
  ];

  return (
    <EmployeeCreateView
      currentStep={step}
      employee={employee}
      steps={steps}
      onCancel={onCancel}
      onNext={onNext(
        step,
        setStep,
        steps.length,
        employee,
        onUpdate,
        onSave
      )}
      onPrevious={onPrevious(step, setStep)}
    />
  );
};

export { EmployeeCreateWizard };
