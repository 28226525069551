import { fieldValidation } from "../../../../common/Model";
import { required } from "../../../../common/validation/RequiredValidator";
import {validEmail} from "../../../../common/validation/EmailValidator";
import {fixedNumber} from "../../../../common/validation/numberValidator";
import {requiredDate} from "../../../../common/validation/RequiredDateValidator";



const getEmployeeValidators = () => {
  return [
    fieldValidation("firstName", [required]),
    fieldValidation("lastName", [required]),
    fieldValidation("dateOfBirth", [requiredDate]),
    fieldValidation("email", [required, validEmail]),
    fieldValidation("tfn", [required, fixedNumber(0, 9, 'Not a valid TFN')]),
    fieldValidation("declarationDate", [requiredDate]),
  ];
};


export { getEmployeeValidators };
